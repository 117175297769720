import React, { FC, useEffect, useState, useLayoutEffect } from 'react';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Charts from 'fusioncharts/fusioncharts.charts';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import DealerTaskSummary from '../reusableComponent/dealerTaskSummary';
import FaultSummary from '../reusableComponent/faultSummary';
import OrgSummary from '../reusableComponent/orgSummary';
import LocationIcon from '../../images/location@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import { resize, hideSidebar } from '../../settings/resize';
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";
import AlertCount from '../reusableComponent/alertCount';
import { format, subDays } from 'date-fns'

ReactFC.fcRoot(FusionCharts, Maps, World, Usa, Charts, FusionTheme);

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

const DealerDashboard: FC<any> = ({ globalData, setGlobalData, setLabelName }) => {
    const [companiesAlerts, setComapaniesAlerts] = useState<any[]>([])
    const [taskSummary, setTaskSummary] = useState<any>({ faultCount: 0, warningsCount: 0 })
    const [summary, setSummary] = useState<any>({ dealerCount: 0, companiesCount: 0, locationsCount: 0, usersCount: 0, siteCount: 0, divicesCount: 0 })
    const [operationStatus, setOperationStatus] = useState<any>({
        highTempBatteriesCount: 0,
        missedEQsCount: 0,
        lowWaterCount: 0,
        lowSOCCount: 0,
        miscellaneousEventsCount: 0
    })
    const [date, setDate] = useState<{ startDate: string, endDate: string }>({ startDate: "", endDate: "" })
    const [initial, setInitial] = useState<boolean>(true);
    const [loadingCount, setLoadingCount] = useState(0);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)


    // to manage the loader
    const showLoader = () => {
        setLoadingCount((count) => count + 1);
    }
    const hideLoader = () => {
        setLoadingCount((count) => Math.max(count - 1, 0));
    }
    useEffect(() => {
        setIsShowSpinner(loadingCount > 0)
    }, [loadingCount])



    useLayoutEffect(() => {
        let mapHeight = document.getElementById('countryChart')?.offsetHeight
        let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
        let alertHeaderTitle: any = document.getElementById('summaryCardsTitle')?.offsetHeight
        let cardHeight = document.getElementById('company-card')?.offsetHeight
        let paginationHeight: any = document.getElementById('pagination-div')?.offsetHeight
        let sitetitleAndPaginationMargin = 35
        var rightSideHeight: any
        var remaningHeight: any
        var getLocalData: any

        if (cardHeight) {
            let cardCount = 10
            rightSideHeight = mapHeight + alertHeaderTitle + (cardHeight + 12) * cardCount
            getLocalData = localStorage.getItem('leftsideheight')
            if (getLocalData) { remaningHeight = parseInt(getLocalData) - (mapHeight + alertHeaderTitle + paginationHeight + searchAlertHeight + sitetitleAndPaginationMargin) }
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth < 1025 && window.innerWidth > 767) {
            let getDiv = document.querySelector("[class='company-alerts']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth > 1900) {
            let getDiv = document.querySelector("[class='company-alerts']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }
    });

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    })

    useEffect(() => {
        resize();
        hideSidebar();
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('dealerDashBoard_W')) window.history.back()
        }


        let userInfo: any = Utility.getUserInfo();
        var userId = userInfo.userId;

        const today = new Date()
        today.setDate(today.getDate());
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let orgId = data?.orgId > 0 ? data?.orgId : userId;

        getTaskSummary(orgId, data?.divId, data?.locationId, data?.buildingId)
        getDealerSummary(orgId, data?.divId, data?.locationId, data?.buildingId)
        getCompantAlerts(orgId, data?.divId, data?.locationId, data?.buildingId);
        setInitial(false);
    }, []);

    useEffect(() => {
        if (initial === false) {
            let userInfo: any = Utility.getUserInfo();
            let userId = userInfo.userId;

            const today = new Date()
            today.setDate(today.getDate());

            let orgId = globalData?.orgId > 0 ? globalData?.orgId : userId;

            getTaskSummary(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getDealerSummary(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getOperationStatusData(date.startDate, date.endDate, orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            getCompantAlerts(orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId);
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId]);

    const getTaskSummary = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        showLoader()
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let url = constantUrl.api.faultSummaryDashboard.taskSummaryCount;
        let type = "POST";
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: type,
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { faultCount, warningsCount } = response;
            setTaskSummary({ ...taskSummary, faultCount, warningsCount });
        } catch (error) {
            console.log(error)
        }
        finally {
            hideLoader()
        }
    }

    const getDealerSummary = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = "";

        url = constantUrl.api.dashboard.getCompanySummary;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { dealerCount, companiesCount, locationsCount, usersCount, siteCount, divicesCount } = response
            setSummary({ ...summary, dealerCount, companiesCount, locationsCount, usersCount, siteCount, divicesCount })
        } catch (error) {
            console.warn(error);
        } finally {
            hideLoader()
        }

    }
    const getOperationStatusData = async (startDate: string, endDate: string, orgId: any, divisionId: any, locationId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var url = constantUrl.api.faultSummaryDashboard.faultSummary;

        let payload = {
            orgId: orgId,
            divisionId: divisionId,
            locationId: locationId,
            buildingId: buildingId,
            startDate: sevenDaysAgo,
            endDate: today,
            // weekStartDay: localStorage.getItem("weeklyStartDay")
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount } = response
            setOperationStatus({ ...operationStatus, highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount })
        } catch (error) {
            console.warn("operationStatusApiError", error);
        } finally {
            hideLoader()
        }
    }

    const getCompantAlerts = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.faultSummaryDashboard.companyFaultAlerts;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        };
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            if (response) {
                const data = [...response]
                setComapaniesAlerts(data)
            }
        } catch (error) {
            setIsShowSpinner(false)
            console.warn(error);
        } finally {
            hideLoader()
        }
    }

    const dateFilter = (startDate: string, endDate: string, getGlobalData?: any) => {
        setDate({ ...date, startDate, endDate });
        getOperationStatusData(startDate, endDate, getGlobalData?.orgId, getGlobalData?.divId, getGlobalData?.locationId, getGlobalData?.buildingId)
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="main-panel">
                <div className="ChartPanel">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7 left-main-col" id='leftCol'>
                                <DealerTaskSummary
                                    summaryData={taskSummary}
                                />
                                <div className="row row2 justify-content-between mt-3  ">
                                    <div className="col-md-12 col-lg-12 col-xl-6 faults-container">
                                        {/* SYSTEM SUMMARY   */}
                                        <OrgSummary
                                            heading='DEALER SUMMARY'
                                            summaryData={summary}
                                            orgID={data?.orgId}
                                            SiteIcon={SiteIcon}
                                            batteryIcon={Batteries}
                                            LocationIcon={LocationIcon}
                                            UserIcon={UserIcon}
                                            siteLable={'SITES'}
                                            deviceLabel={'BATTERIES'}
                                            locationLabel={'LOCATIONS'}
                                            userLabel={'USERS'}
                                        />
                                    </div>
                                    {/* OPERATIONS STATUS  */}
                                    <div className="col-md-12 col-lg-12 col-xl-6 warnings-container" id='operation-status-container'>
                                        <FaultSummary
                                            statusData={operationStatus}
                                            dateFilter={dateFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pr-0 company-alert" id="dealear-dashboard">
                                <div className="row">

                                    <div className="col-md-12 pl-0 pie-chart" id="countryChart" style={{ height: "180px" }}>
                                        <div className="mapBannerDiv">
                                            <div className="row h-100">
                                                <div className="col-md-5 mapBannerImgDiv h-100">
                                                    <img
                                                        src={mapBannerIcon}
                                                        className="mapBannerImg"
                                                        alt="mapBanner"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='right-side'>
                                                        <div className="mapBannerLogoDiv">
                                                            <img
                                                                src={posiBanneLogo}
                                                                alt="posilinkLogo"
                                                                className="mapBannerDivLogo"
                                                            />
                                                        </div>
                                                        <label className="mapBannerDivText">
                                                            Welcome to Posilink, a<br></br> comprehensive
                                                            battery<br></br> management tool
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row" id='alerts'>
                                    <div className="col-12 pl-0">
                                        <div className="chartTitle mt-2 mb-2" id="summaryCardsTitle">COMPANY ALERTS</div>
                                        <AlertCount
                                            companiesAlerts={companiesAlerts}
                                            setGlobalData={setGlobalData}
                                            setLabelName={setLabelName}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default DealerDashboard;