import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import PosinetHeader from "../../layout/header";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/dateNewFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays, parseISO } from 'date-fns';
import HeaderFilter from '../reusableComponent/smsheader';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { AutoComplete, SelectProps, Modal, Button } from "antd";
import { SearchOutlined } from "@mui/icons-material";


const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  batteryRxSim: string,
  pageOffset: number,
  pageLimit: number,
  sortColumns: string,
  sortDirection: string,
  timeZone: string,
  serialNumber: string
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: -1,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  batteryRxSim: "",
  pageOffset: 1,
  pageLimit: 10,
  sortColumns: "",
  sortDirection: "",
  serialNumber: "",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

const partialReq = {
  serialNumber: ""
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string,
  // refresh: number
}

const SMSReport: FC<Props> = ({ globalData, action }) => {
  // const SMSReport: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [selector, setSelector] = useState(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [column, setColumn] = useState<any[]>([])
  const [selectorLabel, setSelectorLabel] = useState<any[]>([])
  const [sort, setSort] = useState<any>({
    dateTime: false, rxSim: false, eMeter: false, user: false, command: false,
    subCommand: false, sender: false, telephone: false, messageId: false, errorText: false, SmppServer: false, SmppPort: false,
    org: false, div: false, loc: false, site: false, imageType: false
  })
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [countReq, setCountReq] = useState<reqBody>(Body)
  const [count, setCount] = useState<number>(0)
  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [batteryEmpty, setBatteryEmpty] = useState<boolean | string>(false)
  const [query, setQuery] = useState<string>("")
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const [alert, setAlert] = useState<boolean>(false);

  const [tableHeight, setTableHeight] = useState<any>(445)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Last 60 Days', label: 'Last 60 Days' },
    { value: 'Last 90 Days', label: 'Last 90 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 80
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
  };

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
    }
  }, [value])

  const selectorRef = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   if (refresh !== 0) {
  //     getSmsReport()
  //     dataCount()
  //   }
  // }, [refresh])

  useEffect(() => {
    getSmsReport()
    dataCount()
  }, [tableReqBody])

  const getSmsReport = async () => {
    if (tableReqBody.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.smsReport.getSmsReport,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: tableReqBody
      });
      setTableReport(response)
      setIsShowSpinner(false)
      if (response != null &&
        response != undefined &&
        response.length === 0) {
        setBatteryEmpty("Please Enter Valid Battery Rx SIM");
      } else {
        setBatteryEmpty("");
      }
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }

  const dataCount = async () => {
    if (countReq.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.smsReport.getSmsCount,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: tableReqBody
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = (value: string) => {
    let val = value ? value?.trim() : "";
    setQuery(val)
    setBatteryEmpty(false)
    setTimeout(() => {
      if (value === "") {
        setTableReqBody({ ...tableReqBody, batteryRxSim: "", pageOffset: 1 })
        setExportReqBody({ ...exportReqBody, batteryRxSim: "", pageOffset: 1 })
        setCountReq({ ...countReq, batteryRxSim: "", pageOffset: 1 })
        setOptions([]);
        setCurrent(1)
      } else {
        setTableReqBody({ ...tableReqBody, batteryRxSim: val, pageOffset: 1 })
        setExportReqBody({ ...exportReqBody, batteryRxSim: val, pageOffset: 1 })
        setCountReq({ ...countReq, batteryRxSim: val, pageOffset: 1 })
        setCurrent(1)
      }
    }, 1000)
  };

  const searchOnSelect = (value: string) => {
    setCurrent(1);
    setTableReqBody({ ...tableReqBody, batteryRxSim: value, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, batteryRxSim: value, pageOffset: 1 })
    setCountReq({ ...countReq, batteryRxSim: value, pageOffset: 1 })
  };

  const onClearGetCall = () => {
    // let data: any = sessionStorage.getItem('globalData');
    // data = data && JSON.parse(data);
    // setOptions([]);
    // setTableReqBody({ ...tableReqBody, batteryId: data?.batteryId, pageOffset: 1 })
    // getData({ ...logReqBody, batteryId: data?.batteryId, pageOffset: 1 })
    // getCount({ ...logReqBody, batteryId: data?.batteryId, pageOffset: 1 })
    // setCurrent(1)
  };

  const callLocationApi = () => {
    if (!query) {
      setBatteryEmpty(true)
    } else {
      setBatteryEmpty(false)
    }
    if (!query) return
    setTableReqBody({ ...tableReqBody, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, pageOffset: 1 })
    setCountReq({ ...countReq, pageOffset: 1 })
    setCurrent(1)
  };

  const onSelect = (pageLimit: any) => {
    const numPageSize = Number(pageLimit)
    setCurrent(1)
    setPageSize(numPageSize)
    setTableReqBody({ ...tableReqBody, pageLimit, pageOffset: 1 })
  };

  const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset })
    setCurrent(pageOffset)
  }

  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const daysFilter = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    const isLeapYearAtStart = isLeapYear(startYear);
    const isLeapYearAtEnd = isLeapYear(endYear);
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
    if (Difference_In_Days > maxDays) {
      setModalMessage(`The maximum range allowed is 1 year.`);
      setIsModalVisible(true);
      return;
    }
    setTableReqBody({ ...tableReqBody, startDate, endDate });
  };

  const handleModalClose = () => {
    onChange([subDays(new Date(), 6), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  const selectColumn = () => {
    if (selectorRef.current && selector) {
      selectorRef.current.style.right = "-370px";
    } else {
      if (selectorRef.current) { selectorRef.current.style.right = "0px" }
    }
    setSelector(!selector);
  };

  const applyChanges = async () => {
    selectColumn()
    var columnPayload = {
      reportId: 'SMS_EVENTS_REPORT',
      reportName: 'SMS_EVENTS_REPORT',
      columnSettings: selectorLabel,
      sortColumn: tableReqBody?.sortColumns,
      sortDirection: tableReqBody?.sortDirection
    };
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}`,
        method: "PUT",
        headers: { Authorization: "Bearer " + authToken },
        data: columnPayload
      })
      setIsShowSpinner(false)
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn([...columnData])
      setSelectorLabel([...selectorData])
    }
    catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  };

  const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    let columndata: any = selectorLabel
    let index: number = parseInt(event.target.name);
    let checked: boolean = event.target.checked;
    columndata[index].enabled = checked
    setSelectorLabel([...columndata])
    setClearAll(false)
    setSelectAll(false)
  };

  const clearall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = false;
    }
    setSelectorLabel([...columnLable])
    setClearAll(true)
    setSelectAll(false)
  };

  const selectall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = true;
    }
    setSelectorLabel(columnLable)
    setClearAll(false)
    setSelectAll(true)
  }

  const cancelSelection = () => {
    let columnData = selectorLabel;
    for (let i = 0; i < columnData.length; i++) {
      columnData[i]['enabled'] = columnData[i].enabled;
    }
    setSelectorLabel(columnData)
    selectColumn()
  };

  const getReportSettings = async () => {
    const reportId = 'SMS_EVENTS_REPORT'
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
        method: "GET",
        headers: { Authorization: "Bearer " + authToken },
      })
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn(columnData)
      setSelectorLabel(selectorData)

      setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setExportReqBody({ ...exportReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setCountReq({ ...countReq, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })

      setInitial(false)
    }
    catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (action !== "") {
      getDowFormat(action)
    }
  }, [action])

  const getDowFormat = async (format: string) => {
    if (selectorLabel.some((item: any) => item.enabled == true)) {
      let userInfo: any = Utility.getUserInfo();
      let token = userInfo.authToken
      setIsShowSpinner(true);
      let payload =
      {
        orgId: tableReqBody?.orgId,
        divisionId: tableReqBody?.divisionId,
        locationId: tableReqBody?.locationId,
        buildingId: tableReqBody?.buildingId,
        startDate: tableReqBody?.startDate,
        endDate: tableReqBody?.endDate,
        batteryId: tableReqBody?.batteryId,
        vehicleId: tableReqBody?.vehicleId,
        batteryRxSim: tableReqBody?.batteryRxSim,
        pageOffset: 1,
        pageLimit: count + 1,
        sortColumns: tableReqBody?.sortColumns,
        sortDirection: tableReqBody?.sortDirection,
        timeZone: tableReqBody?.timeZone
      }
      try {
        const response = await service({
          url: constantUrl.api.smsReport.getSmsExport + '/' + format,
          method: 'POST',
          responseType: 'blob',
          headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
          data: payload
        })
        if (response) {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'SMS-Events-Report.' + format;
          a.click();
        }
        setIsShowSpinner(false);
      } catch (error) {
        setIsShowSpinner(false);
        console.log(error)
      }
    }
    else {
      setAlert(true)
    }
  }

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "dateTime":
        if (sort.dateTime) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateTime", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateTime", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "rxSim":
        if (sort.rxSim) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSim", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryRxSim", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "imageType":
        if (sort.imageType) {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "eMeter":
        if (sort.eMeter) {
          setTableReqBody({ ...tableReqBody, sortColumns: "geteMeterIdentifier", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "geteMeterIdentifier", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "user":
        if (sort.user) {
          setTableReqBody({ ...tableReqBody, sortColumns: "user", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "user", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "command":
        if (sort.command) {
          setTableReqBody({ ...tableReqBody, sortColumns: "command", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "command", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "subCommand":
        if (sort.subCommand) {
          setTableReqBody({ ...tableReqBody, sortColumns: "subCommand", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "subCommand", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "sender":
        if (sort.sender) {
          setTableReqBody({ ...tableReqBody, sortColumns: "senderAddress", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "senderAddress", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "telephone":
        if (sort.telephone) {
          setTableReqBody({ ...tableReqBody, sortColumns: "telephone", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "telephone", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "messageId":
        if (sort.messageId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "messageId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "messageId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "errorText":
        if (sort.errorText) {
          setTableReqBody({ ...tableReqBody, sortColumns: "errorText", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "errorText", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "SmppServer":
        if (sort.SmppServer) {
          setTableReqBody({ ...tableReqBody, sortColumns: "smppServer", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "smppServer", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "SmppPort":
        if (sort.SmppPort) {
          setTableReqBody({ ...tableReqBody, sortColumns: "smppPort", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "smppPort", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "org":
        if (sort.org) {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "div":
        if (sort.div) {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "loc":
        if (sort.loc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "site":
        if (sort.site) {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('smsEventsReport_W')) window.history.back()
    }

    setInitial(true)
    window.scrollTo(0, 0)
    getReportSettings();


  }, [])

  useEffect(() => {
    if (initial === false) {

      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setQuery("");
      setTableReqBody({ ...tableReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId, batteryRxSim: "" })
      setExportReqBody({ ...exportReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId, batteryRxSim: "" })
      setCountReq({ ...countReq, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId, batteryRxSim: "" })
      setCurrent(1)
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId, globalData?.serialId])

  const formattedDate = (date: string) => {
    const formatDate = new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="row">
        <div className="col-md-6">
          <div ref={filtersRef} className=" sms-report-search auto_complete">
            <AutoComplete
              size='middle'
              className="sms-search"
              options={options}
              onSelect={searchOnSelect}
              onSearch={handleSearch}
              allowClear
              onClear={onClearGetCall}
              placeholder="Search by Battery Rx Serial Number"
              value={query}
            >
            </AutoComplete>
            <span onClick={callLocationApi} className="search_icon" style={{ padding: "3.5px" }}><SearchOutlined style={{ color: 'white' }} /></span>

          </div>
          {
            batteryEmpty && <div className="errorColor" style={{ paddingInline: "15px", display: "block" }}>Please Enter Battery Rx Serial Number</div>
          }
        </div>
        {/* <div className="col-md-6 flex_5"> */}
        <div className="col-md-6 mrLeft column-date-filter position-relative">
          {
            showDateFilter && <span className="date-selector">
              <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
            </span>
          }

          <button
            onClick={selectColumn}
            type="button"
            className="btn btn-light selector-btn"
          >
            Column Selector
          </button>
          <span className="date-dropdown">
            <DaysFilter
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 7 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
        </div>
      </div>
      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className='ml-auto mr-auto'>SMS Events Report</div>
            <div className='show-entries'>
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div style={{ height: tableHeight + 'px' }} className="sms_report overflow-auto scrollbar">
              <table className="billable width100">
                <thead className='tableHeader'>
                  <tr className="billable-head">
                    {column?.[0]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[0]?.['columnName']}</span>
                        {sort.dateTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                      : ""}
                    {column?.[1]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('rxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[1]?.['columnName']}</span>
                        {sort.rxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[2]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[2]?.['columnName']}</span>
                        {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[3]?.['enabled'] ? <th>
                      <span >{column?.[3]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[4]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('user')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[4]?.['columnName']}</span>
                        {sort.user ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[5]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('command')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[5]?.['columnName']}</span>
                        {sort.command ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[6]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('subCommand')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[6]?.['columnName']}</span>
                        {sort.subCommand ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[7]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('sender')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[7]?.['columnName']}</span>
                        {sort.sender ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[8]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('telephone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[8]?.['columnName']}</span>
                        {sort.telephone ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[9]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('messageId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[9]?.['columnName']}</span>
                        {sort.messageId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[10]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('errorText')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[10]?.['columnName']}</span>
                        {sort.errorText ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[11]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('SmppServer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[11]?.['columnName']}</span>
                        {sort.SmppServer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[12]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('SmppPort')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[12]?.['columnName']}</span>
                        {sort.SmppPort ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[13]?.['enabled'] ? <th>
                      <span >{column?.[13]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[14]?.['enabled'] ? <th>
                      <span >{column?.[14]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[15]?.['enabled'] ? <th>
                      <span >{column?.[15]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[16]?.['enabled'] ? <th>
                      <span >{column?.[16]?.['columnName']}</span>
                    </th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((row: any, index: any) => {
                    const { formatDateTime, batteryRxSim, geteMeterIdentifier, user, command, subCommand, senderAddress, telephone, messageId, errorText,
                      smppServer, smppPort, organization, division, location, building, emeterIdentifier, deviceType } = row
                    return (
                      <React.Fragment key={'keys' + index}>
                        <tr>
                          {column?.[0]?.['enabled'] ? <td>{formatDateTime}</td> : ""}
                          {column?.[1]?.['enabled'] ? <td>{batteryRxSim}</td> : ""}
                          {column?.[2]?.['enabled'] ? <td>{deviceType}</td> : ""}
                          {column?.[3]?.['enabled'] ? <td>{emeterIdentifier}</td> : ""}
                          {column?.[4]?.['enabled'] ? <td>{user}</td> : ""}
                          {column?.[5]?.['enabled'] ? <td>{command}</td> : ""}
                          {column?.[6]?.['enabled'] ? <td>{subCommand}</td> : ""}
                          {column?.[7]?.['enabled'] ? <td>{senderAddress}</td> : ""}
                          {column?.[8]?.['enabled'] ? <td>{telephone}</td> : ""}
                          {column?.[9]?.['enabled'] ? <td>{messageId}</td> : ""}
                          {column?.[10]?.['enabled'] ? <td>{errorText}</td> : ""}
                          {column?.[11]?.['enabled'] ? <td>{smppServer}</td> : ""}
                          {column?.[12]?.['enabled'] ? <td>{smppPort}</td> : ""}
                          {column?.[13]?.['enabled'] ? <td>{organization}</td> : ""}
                          {column?.[14]?.['enabled'] ? <td>{division}</td> : ""}
                          {column?.[15]?.['enabled'] ? <td>{location}</td> : ""}
                          {column?.[16]?.['enabled'] ? <td>{building}</td> : ""}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>

        </div>
        <div ref={selectorRef} className="column-selector selector ">
          <div className="column-title">Column Selector</div>
          <div className="slector-body">
            <label>
              <input
                checked={clearAll}
                value="clearAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={clearall}
              />
              <span></span>
            </label>
            <p>Clear All</p>

            <label>
              <input
                checked={selectAll}
                value="selectAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={selectall}
              />
              <span></span>
            </label>
            <p>Select All</p>
          </div>
          <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
            {selectorLabel?.map((item: any, index: any) => {
              return (
                <div key={'key' + index} className="slector-body">
                  <label>
                    <input
                      name={index}
                      onChange={onchangeColumn}
                      checked={item['enabled']}
                      value={item.value}
                      type="checkbox"
                      aria-label="checkbox"
                    />
                    <span></span>
                  </label>
                  <p>{item['columnName']}</p>
                </div>
              )
            })}
          </div>
          <div className="m-flex">
            <button onClick={cancelSelection} className="cancel">
              Cancel
            </button>
            <button onClick={applyChanges} className="apply-changes mr-3">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={alert}
        closable={false}
        centered title={"Atleast Select 1 Column"}
        footer={[
          <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
        ]}
      ></Modal>
      <SessionWarning />
    </>
  )
}
export default SMSReport