import React, { FC, useEffect, useState, useDeferredValue, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
// import CountryChart from '../fusionChart/countryChart';
import Charts from 'fusioncharts/fusioncharts.charts';
import { Input, Pagination, PaginationProps } from 'antd';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfWeek, subDays } from 'date-fns';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import TaskSummary from '../reusableComponent/TaskSummary';
import Summary from '../reusableComponent/summary';
import OperationStatus from '../reusableComponent/operations-status';
import Donut from '../fusionChart/donutChart';
import LocationIcon from '../../images/location@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import * as roles from '../../settings/roles';
import CountryMap from '../../images/CountryMap.png';
import { resize, hideSidebar } from '../../settings/resize';
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";
import DealerTaskSummary from '../reusableComponent/dealerTaskSummary';
import FaultSummary from '../reusableComponent/faultSummary';
import OrgSummary from '../reusableComponent/orgSummary';


ReactFC.fcRoot(FusionCharts, Maps, World, Usa, Charts, FusionTheme);

interface item {
    orderId: string;
    owner: { login: string },
    name: string,
    locationsCount: number,
    faultCount: number,
    warningsCount: number
}
const { Search } = Input;
const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    setGlobalData: (key: any, value: any) => void,
    setLabelName: (key: any, value: any) => void
}

const LocationDashboard2: FC<props> = ({ globalData, setGlobalData, setLabelName }) => {
    const [current, setCurrent] = useState<number>(1)
    const [minIndex, setMindex] = useState<number>(0)
    const [maxIndex, setMaxindex] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [pageSizeOptions, setPageSizeOptions] = useState<[number, number, number, number]>([10, 20, 50, 100])
    const [showSizeChanger, setShowSizeChanger] = useState<boolean>(false)
    const [sites, setSites] = useState<any>([])
    const [search, setSearch] = useState<string>("")
    const [taskSummary, setTaskSummary] = useState<any>({ faultCount: 0, warningsCount: 0})
    const [summary, setSummary] = useState<any>({ dealerCount: 0, companiesCount: 0, locationsCount: 0, usersCount: 0 })
    const [soc, setSoc] = useState<any>({ highSOCUnits: 0, lowSOCUnits: 0, totalChargeCount: 0, totalDisconnectedCount: 0, totalInstalledUnits: 0, totalNoChargeUnits: 0 })
    const [operationStatus, setOperationStatus] = useState<any>({
        highTempBatteriesCount: 0,
        missedEQsCount: 0,
        lowWaterCount: 0,
        lowSOCCount: 0,
        miscellaneousEventsCount: 0
    })
    const [date, setDate] = useState<{ startDate: string, endDate: string }>({ startDate: "", endDate: "" });
    const [countryRes, setCountryRes] = useState<any[]>([])

    const defervalue = useDeferredValue(search)
    const [count, setCount] = useState<any>(0)
    const [pageoffset, setPageoffset] = useState<{ pageLimit: number, pageOffset: number }>({ pageLimit: 10, pageOffset: 1 })
    const [initial, setInitial] = useState<boolean>(true);
    const [loadingCount, setLoadingCount] = useState(0);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)


    // to manage the loader
    const showLoader = () =>{
        setLoadingCount((count) => count + 1);
    } 
    const hideLoader = () => {
        setLoadingCount((count) => Math.max(count - 1, 0));
    }
    useEffect(()=>{
      setIsShowSpinner(loadingCount>0)
    },[loadingCount])

    useLayoutEffect(() => {
        let mapHeight = document.getElementById('countryChart')?.offsetHeight
        let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
        let alertHeaderTitle: any = document.getElementById('siteTitle')?.offsetHeight
        let sitetitleAndPaginationMargin = 45
        var rightSideHeight: any
        var remaningHeight: any
        var getLocalData: any
        let cardHeight = document.getElementById('dealer-card')?.offsetHeight
        let paginationHeight: any = document.getElementById('siteAlertsPagination')?.offsetHeight

        if (cardHeight) {
            let cardCount = 10
            rightSideHeight = mapHeight + alertHeaderTitle + (cardHeight + 12) * cardCount
            getLocalData = localStorage.getItem('leftsideheight')
            if (getLocalData) {
                if (getLocalData) { remaningHeight = parseInt(getLocalData) - (mapHeight + alertHeaderTitle + paginationHeight + searchAlertHeight + sitetitleAndPaginationMargin) }
            }
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth < 1025 && window.innerWidth > 767) {
            let getDiv = document.querySelector("[class='site-alerts-container']") as HTMLElement
            getDiv.style.height = remaningHeight + 'px'
        }
        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth > 1900) {
            let getDiv = document.querySelector("[class='site-alerts-container']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }
    });

    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     })

    useEffect(() => {
        resize();
        hideSidebar();
        // check the page permission
        let permission:any =  localStorage.getItem('permissions')
        if(permission !== null){
        if(!permission.includes('locationDashBoard_W'))window.history.back()  
        }

        setInitial(true)
        let userInfo: any = Utility.getUserInfo();
        let userId = userInfo.userId;

        const today = new Date()
        today.setDate(today.getDate());
        const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
        // setDate({ ...date, startDate: firstDayOfWeek, endDate: lastDayOfWeek });

        // setTimeout(() => {
            let data: any = sessionStorage.getItem('globalData');
            data = data && JSON.parse(data);

            let orgid = data?.orgId > -1 ? data?.orgId : userId;
            let locid = data?.locationId;
            let siteid = data?.buildingId;

            if (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) {
                if (locid > -1) {
                    getTaskSummary(orgid, data?.divId, locid, siteid)
                    getCompanySummary(orgid, data?.divId,locid, siteid)
                    //socLocationDashboard(firstDayOfWeek, lastDayOfWeek, orgid,data?.divId, locid, siteid)
                    // getOperationStatusLocation(firstDayOfWeek, lastDayOfWeek, orgid,data?.divId, locid, siteid)
                    alerts(orgid, data?.divId,locid, siteid);
                    //countrySummary(orgid, data?.divId,locid, siteid)
                }
            } else {
                getTaskSummary(orgid, data?.divId,locid, siteid)
                getCompanySummary(orgid, data?.divId,locid, siteid)
                //socLocationDashboard(firstDayOfWeek, lastDayOfWeek, orgid, data?.divId,locid, siteid)
                // getOperationStatusLocation(firstDayOfWeek, lastDayOfWeek, orgid, data?.divId,locid, siteid)
                alerts(orgid, data?.divId,locid, siteid);
                //countrySummary(orgid,data?.divId, locid, siteid)
            }
            setInitial(false);
        // }, 1000);
    }, []);

    useEffect(() => {
        let userInfo: any = Utility.getUserInfo();
        let userId = userInfo.userId;

        let orgid = globalData?.orgId > -1 ? globalData?.orgId : userId;
        let locid = globalData?.locationId;
        let siteid = globalData?.buildingId;

        const today = new Date()
        today.setDate(today.getDate());
        const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');

        if (initial == false) {
            if (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) {
                if (locid > -1) {
                    getTaskSummary(orgid, globalData?.divId, locid, siteid)
                    getCompanySummary(orgid, globalData?.divId,locid, siteid)
                    //socLocationDashboard(firstDayOfWeek, lastDayOfWeek, orgid, globalData?.divId,locid, siteid)
                    getOperationStatusLocation(date.startDate, date.endDate, orgid, globalData?.divId,locid, siteid)
                    alerts(orgid, globalData?.divId,locid, siteid);
                    //countrySummary(orgid, globalData?.divId,locid, siteid)
                }
            } else {
                getTaskSummary(orgid, globalData?.divId,locid, siteid)
                getCompanySummary(orgid, globalData?.divId,locid, siteid)
                //socLocationDashboard(firstDayOfWeek, lastDayOfWeek, orgid, globalData?.divId,locid, siteid)
               // getOperationStatusLocation(date.startDate, date.endDate, orgid, globalData?.divId,locid, siteid)
                alerts(orgid, globalData?.divId,locid, siteid);
                //countrySummary(orgid, globalData?.divId,locid, siteid)
            }
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId]);

    const getTaskSummary = async (orgId: number, divId: any, locId: number, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let url = constantUrl.api.faultSummaryDashboard.taskSummaryCount;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const {faultCount,warningsCount} = response;
            setTaskSummary({ ...taskSummary,faultCount,warningsCount});
        } catch (error) {
            console.log(error)
        }finally{
            hideLoader()
        }
    }

    const getCompanySummary = async (orgId: any, divId: any, loId: any, building: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        
        let url = constantUrl.api.dashboard.getCompanySummary;

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: loId,
            buildingId: building
        }
        showLoader() 
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token, },
                data: payload
            });
            const { divicesCount, locationsCount, siteCount, usersCount } = response
            setSummary({ ...summary, divicesCount, siteCount, locationsCount, usersCount })
        } catch (error) {
            console.warn(error);
        }finally{
            hideLoader()
        }
    }

    const socLocationDashboard = async (startDate: string, endDate: string, orgId: any, divId: any, loId: any, buildingId: any,) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getOperationSoc;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: loId,
            buildingId: buildingId,
            startDate: startDate,
            endDate: endDate
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits } = response
            setSoc({ ...soc, highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits })
        } catch (error) {
            console.warn(error);
        }
    }

    const getOperationStatusLocation = async (startDate: string, endDate: string, orgId: any, divId: any, loId: any, buildingId: any,) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        var url = constantUrl.api.faultSummaryDashboard.faultSummary;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: loId,
            buildingId: buildingId,
            startDate: sevenDaysAgo,
            endDate: today,
            // weekStartDay: localStorage.getItem("weeklyStartDay")
        }
        showLoader()
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount} = response
            setOperationStatus({ ...operationStatus, highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount})
        } catch (error) {
            console.warn(error);
        }finally{
            hideLoader()
        }
    }

    const countrySummary = async (orgId: any, divId: any, locId: any, building: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getCountrySummary;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: building,
            countryCode: "USA"
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setCountryRes(response)
        } catch (error) {
            console.log("getCompaniesError:", error)
        }
    }

    const alerts = async (orgId: number, divId: any, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.faultSummaryDashboard.siteFaultAlerts;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }
        showLoader() 
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            if (response) {
                const data = [...response]
                setSites(data)
                setMindex(0)
                setMaxindex(pageSize)
                setCount(response.length)
                response.length > 10 && setShowSizeChanger(true)
            }
        } catch (error) {
            console.warn(error);
        }finally{
            hideLoader()
        }
    }

    const dateFilter = (startDate: string, endDate: string,getGlobalData?:any) => {
        // let data: any = sessionStorage.getItem('globalData');
        // data = data && JSON.parse(data);

        // let orgid = data?.orgId;
        setDate({ ...date, startDate, endDate });

        getOperationStatusLocation(startDate, endDate, getGlobalData?.orgId, getGlobalData?.divId, getGlobalData?.locationId, getGlobalData?.buildingId)
    }

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current: number, pageSize: number) => {
        setPageoffset({ pageLimit: pageSize, pageOffset: current })
    }

    const handleChange = (page: number, getPageSize: number) => {
        setCurrent(page);
        setMindex((page - 1) * getPageSize)
        setMaxindex(page * getPageSize);
        setPageSize(getPageSize);
        setPageoffset({ pageLimit: pageSize, pageOffset: page })
    }

    const filterSiteName = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        value.length < 3 ? setSearch("") : setSearch(value)
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="main-panel">
                <div className="ChartPanel">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7 left-main-col" id='leftCol'>
                            <DealerTaskSummary
                                    summaryData={taskSummary}
                                />
                                {/* <TaskSummary
                                    summaryData={taskSummary}
                                /> */}
                                <div className="row row2 justify-content-between mt-3  ">
                                    <div className="col-md-12 col-lg-12 col-xl-6 faults-container">
                                        {/* LOCATION SUMMARY   */}
                                        <OrgSummary
                                            heading='LOCATION SUMMARY'
                                            summaryData={summary}
                                            orgID={data?.orgId}
                                            SiteIcon={SiteIcon}
                                            batteryIcon={Batteries}
                                            LocationIcon={LocationIcon}
                                            UserIcon={UserIcon}
                                            siteLable={'SITES'}
                                            deviceLabel={'BATTERIES'}
                                            locationLabel={'LOCATIONS'}
                                            userLabel={'USERS'}
                                        />
                                        {/* STATE OF CHARGE  */}
                                        {/* <div className="row state-charge" id='state-charge'>
                                            <div className="col-12 px-2 mt-2 mb-2">
                                                <div className="col-12  chartTitle">STATE OF CHARGE</div>
                                            </div> */}
                                            {/* doughnut chart  */}
                                            {/* <div className="col-md-12 pb-2 px-2 pie-chart" id="pie-chart">
                                                <Donut pieRaidus={70} doughnutRadius={60} soc={soc} />
                                            </div> */}
                                            {/* doughnut chart  */}
                                        {/* </div> */}
                                    </div>
                                    {/* OPERATIONS STATUS  */}
                                    <div className="col-md-12 col-lg-12 col-xl-6 warnings-container" id='operation-status-container'>
                                        {/* <OperationStatus statusData={operationStatus} dateFilter={dateFilter} /> */}
                                        <FaultSummary
                                                statusData={operationStatus}
                                                dateFilter={dateFilter}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5  pr-0 company-alert" id="dealear-dashboard">
                                {/*  <div className="row">
                                    <div className="col-md-12 pl-0 pie-chart" id="countryChart" style={{ height: "196px" }}>
                                        <img src={CountryMap} style={{ width: "inherit", height: "200px" }} alt='img' />
                                       <CountryChart data={countryRes} />
                                    </div>
                                </div> */}
                                 <div className="row">
                                  
                                    <div className="col-md-12 pl-0 pie-chart" id="countryChart" style={{ height: "180px" }}>
                                     <div className="mapBannerDiv">
				    			 		{/* <img src={WorldMap} style={{ width: "inherit", height: "200px" }} alt='img' /> */}
				                                        <div className="row h-100">
								            <div className="col-md-5 mapBannerImgDiv h-100">
								              <img
								                src={mapBannerIcon}
								                className="mapBannerImg"
								                alt="mapBanner"
								              />
								            </div>
								            <div className="col-md-6">
                                            <div className='right-side'>
								              <div className="mapBannerLogoDiv">
								                <img
								                  src={posiBanneLogo}
								                  alt="posilinkLogo"
								                  className="mapBannerDivLogo"
								                />
								              </div>
								              <label className="mapBannerDivText">
								                Welcome to Posilink, a<br></br> comprehensive
								                battery<br></br> management tool
								              </label>
								            </div>
                                            </div>
								          </div>
								        </div>
                                       </div>

                                </div>
                                <div className="row">
                                    <div className="col-12 pl-0">
                                        <div className="chartTitle mt-2 mb-2" id='siteTitle'>SITE ALERTS</div>
                                        {/* <div className='search-alerts mb-1' id='search-alerts'>
                                            <Search placeholder="Search site alerts"
                                                onChange={filterSiteName}
                                                style={{}}
                                                allowClear
                                                enterButton />
                                        </div> */}
                                        <div id="company-cards">
                                            <ul className='site-alerts-container'>
                                                {sites && sites.filter((item: any, index: any) => {
                                                    return defervalue.toLowerCase() === "" ? item : item?.name.toLowerCase().includes(defervalue.toLowerCase())
                                                }).map((item: item, index: number) => {
                                                    const { name, faultCount, locationsCount, warningsCount, orderId } = item
                                                    return index >= minIndex &&
                                                        index < maxIndex && (
                                                            <div id="dealer-card" key={index} className="row dealer-card">
                                                                <div className="col-md-12">
                                                                    <div className="dealer-card-body">
                                                                        <div className="delear-body-right">
                                                                            <div>
                                                                                <img src={require("../../images/User.png")} className="dealear-img" alt='img' />
                                                                            </div>
                                                                            <div>
                                                                                <div className="dealaer-no" onClick={() => {
                                                                                    let data: any = sessionStorage.getItem('globalData');
                                                                                    data = data && JSON.parse(data);
                                                                                    data["buildingId"] = orderId;
                                                                                    sessionStorage.setItem("globalData", JSON.stringify(data))
                                                                                    setLabelName("site", name)
                                                                                    setGlobalData("buildingName", name);
                                                                                }}><Link to='/sitedashboard'>{name?.length > 15 ? name?.substring(0, 15) + "..." : name}</Link> </div>
                                                                                <div className="fontBlue ">{locationsCount} DEVICES</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="delear-body-left ">
                                                                            <div>
                                                                                <div className="circle red"></div>
                                                                                <div className="circle yellow"></div>
                                                                            </div>
                                                                            <div className="count">
                                                                                <div>{faultCount}</div>
                                                                                <div>{warningsCount}</div>
                                                                            </div>
                                                                            <div className="delaler-status">
                                                                            <div>FAULTS</div>
                                                                            <div>WARNINGS</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                })}
                                            </ul>
                                        </div>
                                        {/* <div className='pagination-div mt-3' id="siteAlertsPagination">
                                            <Pagination
                                                pageSize={count}
                                                current={current}
                                                total={count}
                                                onChange={handleChange}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger={showSizeChanger}
                                                onShowSizeChange={onShowSizeChange}
                                                showLessItems={true}
                                                style={{ bottom: "0px", textAlign: "center" }}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default LocationDashboard2;