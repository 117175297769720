import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Button, ConfigProvider, Input, Modal, InputRef } from 'antd';
import { format, subDays } from 'date-fns';
import React, { FC, useEffect, useRef, useState } from 'react';
import AhrsCharged from '../../images/Low_SOC.svg'
import Eq from '../../images/Missed_EQ.svg'
import HighTemp from '../../images/High_Temp_Icon.svg';
import LowWater from '../../images/Watering_Icon.svg';
import Miscellaneous from '../../images/miscellaneous_1.svg';
import DaysFilter from '../reusableComponent/dateNewFilter';
import SessionWarning from '../reusableComponent/sessionWarning';
import ShowEntries from '../reusableComponent/showEntries';
import LoadingSpinner from '../spinner/loading-spinner';
import PaginationComponent from "../reusableComponent/pagination";
import { Link, useLocation } from 'react-router-dom';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';



const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    email: boolean,
    setEmail: () => void
}

const reqBody = {
    "orgId": 0,
    "divisionId": -1,
    "locationId": -1,
    "buildingId": -1,
    "startDate": sevenDaysAgo,
    "endDate": today,
    "sortColumn": "batteryId",
    "sortDirection": "ASC",
    "pageOffset": 1,
    "pageLimit": 10,
    // "timeZone" : Intl.DateTimeFormat().resolvedOptions().timeZone // not required
    "timeZone": "",
    // "weekStartDay": "MON"  // not required 
}

const FaultsAndWarnings: FC<Props> = ({ globalData, action, email, setEmail }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const tabelRef = useRef<HTMLDivElement>(null)
    const emailRef = useRef<InputRef>(null);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    type SectionType = 'HIGH_TEMPERATURE_FAULTS_REPORT' | 'MISSED_EQ_FAULTS_REPORT' | 'LOW_WATER_FAULTS_REPORT' | 'LOW_SOC_FAULTS_REPORT' | 'MISCELLANEOUS_FAULTS_REPORT' | 'ALL_FAULTS_REPORT';
    const [highlighted, setHighlighted] = useState<SectionType>();
    const location = useLocation();
    const [emailText, setEmailText] = useState<string>("")
    const [validEmail, setValidEmail] = useState<string>("")
    const [ismailsuccess, setIsMailSuccess] = useState<boolean>(false)
    const [ismailfailure, setIsMailFailure] = useState<boolean>(false)
    const [invalidEmail, setInValidEmail] = useState<string>("")
    const [codeDescription, setCodeDescription] = useState(false);
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [totalCount, setTotalCount] = useState<number>(1)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [dateLabel, setDateLabel] = useState<string>("Last 7 Days")
    const [dateRange, setDateRange] = useState<any>({ sevenDaysAgo: format(subDays(new Date(), 6), 'dd-MMM-yyyy'), today: format(new Date(), 'dd-MMM-yyyy') })
    const [summaryReq, setSummaryReq] = useState({ orgId: 0, divisionId: 0, locationId: 0, buildingId: 0, startDate: sevenDaysAgo, endDate: today, "weekStartDay": localStorage.getItem("weeklyStartDay") })
    const [weekDay, setWeekDay] = useState<any>(localStorage.getItem('weeklyStartDay'))
    const [countReqBody, setCountReqBody] = useState<any>(reqBody)
    const [logReqBody, setlogReqBody] = useState<any>(reqBody)
    const [faultAndWarningList, setFaultAndWarningList] = useState<any[]>([])
    const [initial, setInitial] = useState<boolean>(true);
    const [sort, setSort] = useState<any>({ battery: false, severityType: false, code: false, date: false, time: false, imageType: false })
    const [operationStatus, setOperationStatus] = useState<any>({
        highTempBatteriesCount: 0,
        missedEQsCount: 0,
        lowWaterCount: 0,
        lowSOCCount: 0,
        miscellaneousEventsCount: 0
    })

    const dateValue = [
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 14 Days', label: 'Last 14 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Last 60 Days', label: 'Last 60 Days' },
        { value: 'Last 90 Days', label: 'Last 90 Days' },
        { value: 'Custom Date Range', label: 'Custom Date Range' }]
    let activeRequests = 0;

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        // To calculate the time difference of two dates
        var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today, `Last ${Difference_In_Days} Days`);
        }
    }, [value])

    useEffect(() => {
        if (email && emailRef.current) {
            emailRef.current.focus();
        }
    }, [email]);

    useEffect(() => {
        if (emailRef.current) {
            emailRef.current.focus();
        }
    }, [email]);

    useEffect(() => {
        const storedSection = localStorage.getItem('highlightedSection');
        if (storedSection === 'HIGH_TEMPERATURE_FAULTS_REPORT' ||
            storedSection === 'MISSED_EQ_FAULTS_REPORT' ||
            storedSection === 'LOW_WATER_FAULTS_REPORT' ||
            storedSection === 'LOW_SOC_FAULTS_REPORT' ||
            storedSection === 'MISCELLANEOUS_FAULTS_REPORT') {
            setHighlighted(storedSection as SectionType);
        }
        return () => {
            localStorage.removeItem('highlightedSection');
        };
    }, [location]);

    useEffect(() => {
        let summaryType = localStorage.getItem("highlightedSection");
        if (summaryType === null || summaryType === undefined || summaryType === 'null') {
            localStorage.setItem("highlightedSection", "ALL_FAULTS_REPORT");
        }
        getOperationStatus();
        getFaultWarningList()
    }, [logReqBody]);

    useEffect(() => {
        getCount()
    }, [countReqBody]);

    useEffect(() => {
        if (action !== "") getDowFormat(action)
    }, [action])

    useEffect(() => {
        setInitial(true)
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        setCurrent(1);
        let org = data?.orgId > -1 ? data?.orgId : userId;
        setlogReqBody({ ...logReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
        setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
        setSummaryReq({ ...summaryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
        setTimeout(() => {
            setInitial(false)
        }, 2000);

    }, [])

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setlogReqBody({ ...logReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setSummaryReq({ ...summaryReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const toggleEmail = () => {
        setEmailText("")
        setValidEmail("")
        setEmail()
    }

    const validateEmail = (email: any) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email)
    };

    const handleChange = (event: any) => {
        if (validateEmail(event.target.value)) {
            setEmailText(event.target.value)
            setValidEmail("")
        } else {
            setEmailText(event.target.value)
            setValidEmail("Please Enter Valid Email ID")
        }
    }

    const handleModalClose = () => {
        onChange([subDays(new Date(), 6), new Date()])
        setIsModalVisible(false);
        setModalMessage('');
    };

    const claenderonChange = (startDate: string, endDate: string) => {
        setDateRange({ ...dateRange, sevenDaysAgo: startDate, today: endDate })
    }

    const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };

    const daysFilter = (startDate: string, endDate: string, label?: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const isLeapYearAtStart = isLeapYear(startYear);
        const isLeapYearAtEnd = isLeapYear(endYear);
        const Difference_In_Time = end.getTime() - start.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
        if (Difference_In_Days > maxDays) {
            setModalMessage('The maximum range allowed is 1 year.');
            setIsModalVisible(true);
            return;
        }
        if (label) {
            setDateLabel(label);
        } else if (value) {
            setDateLabel(value);
        }
        claenderonChange(startDate, endDate)
        setlogReqBody({ ...logReqBody, startDate, endDate })
        setCountReqBody({ ...countReqBody, startDate, endDate })
    };

    const showModal = () => {
        setCodeDescription(true);
    };

    const handleCancel = () => {
        setCodeDescription(false);
    };

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const handleHighlight = (section: SectionType) => {
        setHighlighted(section);
        localStorage.setItem('highlightedSection', section as string);
        setCurrent(1)
        const pageNo = 1
        getCount();
        getFaultWarningList(pageNo);
    };

    const resetHighlights = () => {
        setHighlighted('ALL_FAULTS_REPORT');
        localStorage.setItem("highlightedSection", "ALL_FAULTS_REPORT");
        setCurrent(1)
        const pageNo = 1
        getCount();
        getFaultWarningList(pageNo);
    };

    async function getFaultWarningList(pageNo?: number) {
        if (logReqBody.orgId === 0) return
        const ReqBody = { ...logReqBody, pageOffset: pageNo ? pageNo : logReqBody.pageOffset }
        let summaryType = localStorage.getItem('highlightedSection');
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        activeRequests++;
        try {
            const response = await service({
                url: constantUrl.api.faultAndWarning.faultAndWarningList + summaryType,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: ReqBody
            });
            setFaultAndWarningList(response)
        } catch (error) {
            console.error("getFaultsWarning:", error);
        } finally {
            activeRequests--;
            if (activeRequests === 0) {
                setIsShowSpinner(false);
            }
        }
    }

    async function getCount() {
        if (countReqBody.orgId === 0) return
        let summaryType = localStorage.getItem('highlightedSection');
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.faultAndWarning.faultAndWarningCount + summaryType,
                method: "POST",
                data: countReqBody,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("highTempCount:", error);
        }
    }

    const sendEmail = async () => {
        let summaryType = localStorage.getItem('highlightedSection');
        if (isShowSpinner) return;
        if (!emailText) {
            setValidEmail("Please Enter Email Id")
            return
        }
        if (validateEmail(emailText) === false) return
        setIsShowSpinner(true)
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumn: logReqBody?.sortColumn,
            sortDirection: logReqBody?.sortDirection,
            timeZone: logReqBody?.timeZone,
            toEmail: emailText,
            // weekStartDay: localStorage.getItem('weeklyStartDay')
        }
        try {
            const response = await service({
                url: constantUrl.api.faultAndWarning.faultAndWarningEmail + summaryType + "/send/to/email",
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response.status === "FAILURE" && response.errorCode === 1125 && response.data === "Email Sending failed !!!") {
                setValidEmail(response.data)
                setEmail()
            }
            if (response.data === "Email Sent Successfully !!!") {
                setEmailText("")
                setIsMailSuccess(true)
                setEmail()
            }
            if (response.status === "FAILURE" && response.errorCode === 3123) {
                setValidEmail(response.data)
            }
            if (response.status === "FAILURE" && response.errorCode === 3112) {
                setInValidEmail(response.errorMessage)
                setIsMailFailure(true)
                setEmailText("")
                setEmail()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsShowSpinner(false)
        }
    }

    const getDowFormat = async (format: string) => {
        let summaryType = localStorage.getItem('highlightedSection');
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        setIsShowSpinner(true)
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumn: logReqBody?.sortColumn,
            sortDirection: logReqBody?.sortDirection,
            timeZone: logReqBody?.timeZone
            // weekStartDay: weekDay
        }
        try {
            const response = await service({
                url: constantUrl.api.faultAndWarning.faultAndWarningDownload + summaryType + "/download/by/" + format,
                method: 'POST',
                responseType: 'blob',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response) {
                let d = new Date();
                let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'FaultAndWarningReport-' + dformat + "." + format;
                a.click();
                setIsShowSpinner(false)
            }
            setIsShowSpinner(false)
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "battery":
                if (sort.battery) {
                    setlogReqBody({ ...logReqBody, sortColumn: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "imageType":
                if (sort.imageType) {
                    setlogReqBody({ ...logReqBody, sortColumn: "imageType", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "imageType", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "severityType":
                if (sort.severityType) {
                    setlogReqBody({ ...logReqBody, sortColumn: "severityType", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "severityType", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "code":
                if (sort.code) {
                    setlogReqBody({ ...logReqBody, sortColumn: "code", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "code", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "date":
                if (sort.date) {
                    setlogReqBody({ ...logReqBody, sortColumn: "date", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "date", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "time":
                if (sort.time) {
                    setlogReqBody({ ...logReqBody, sortColumn: "time", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumn: "time", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    const getOperationStatus = async () => {
        if (logReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var url = constantUrl.api.faultSummaryDashboard.faultSummary;

        let payload = {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            // weekStartDay: localStorage.getItem("weeklyStartDay")
        }
        activeRequests += 1;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount } = response
            setOperationStatus({ ...operationStatus, highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount })
        } catch (error) {
            console.warn("operationStatusApiError", error);
        } finally {
            activeRequests -= 1;
            if (activeRequests === 0) {
	          setIsShowSpinner(false);
	        }
        }
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <>
                <div className="row pl-3">
                    <div className="row rounded operation-status-top warning-div pr-3 pl-3 ">
                        <div className="report-div p-1">
                            <div className="col-lg-12 col-md-12 chartTitle py-1 pl-2 text-left">FAULTS SUMMARY</div>
                            <div className="col-12 px-0 py-1">
                                <div className="d-flex">

                                    {/* High Temp Section */}
                                    <div className="pr-1 basisFault highlightPointer" onClick={() => handleHighlight('HIGH_TEMPERATURE_FAULTS_REPORT')}>
                                        <div className={`row border-1-darkgrey ${highlighted === 'HIGH_TEMPERATURE_FAULTS_REPORT' ? 'highlight' : ''}`}>
                                            <div className="col-4 img_container">
                                                <img src={HighTemp} className="img_size_battery" alt="img" />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationStatus.highTempBatteriesCount}</span>
                                                    <span className="fontBlue fontSize9">HIGH TEMP</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Missed EQ Section */}
                                    <div className="pr-1 basisFault highlightPointer" onClick={() => handleHighlight('MISSED_EQ_FAULTS_REPORT')}>
                                        <div className={`row border-1-darkgrey ${highlighted === 'MISSED_EQ_FAULTS_REPORT' ? 'highlight' : ''}`}>
                                            <div className="col-4 img_container">
                                                <img src={Eq} className="img_size_battery" alt="img" />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationStatus.missedEQsCount}</span>
                                                    <span className="fontBlue fontSize9">MISSED EQ'S</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Low Water Section */}
                                    <div className="pr-1 basisFault highlightPointer" onClick={() => handleHighlight('LOW_WATER_FAULTS_REPORT')}>
                                        <div className={`row border-1-darkgrey ${highlighted === 'LOW_WATER_FAULTS_REPORT' ? 'highlight' : ''}`}>
                                            <div className="col-4 img_container">
                                                <img src={LowWater} className="img_size_battery" alt="img" />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationStatus.lowWaterCount}</span>
                                                    <span className="fontBlue fontSize9">LOW WATER COUNT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Low SOC Section */}
                                    <div className="pr-1 basisFault highlightPointer" onClick={() => handleHighlight('LOW_SOC_FAULTS_REPORT')}>
                                        <div className={`row border-1-darkgrey ${highlighted === 'LOW_SOC_FAULTS_REPORT' ? 'highlight' : ''}`}>
                                            <div className="col-4 img_container">
                                                <img src={AhrsCharged} className="img_size_battery" alt="img" />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationStatus.lowSOCCount}</span>
                                                    <span className="fontBlue fontSize9">LOW SOC</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Miscellaneous Section */}
                                    <div className="pr-1 basisFault highlightPointer" onClick={() => handleHighlight('MISCELLANEOUS_FAULTS_REPORT')}>
                                        <div className={`row border-1-darkgrey ${highlighted === 'MISCELLANEOUS_FAULTS_REPORT' ? 'highlight' : ''}`}>
                                            <div className="col-4 img_container">
                                                <img src={Miscellaneous} className="img_size_battery" alt="img" />
                                            </div>
                                            <div className="col-8 pr8">
                                                <div className="d-flex img_container_flex">
                                                    <span className="fontBlue fontSize20">{operationStatus.miscellaneousEventsCount}</span>
                                                    <span className="fontBlue fontSize9">MISCELLANEOUS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Reset Button */}
                    <div style={{ paddingLeft: '10px', paddingTop: '2px' }}>
                        <Button type="primary" style={{ background: '#004f9f', color: 'white' }} onClick={resetHighlights}>Reset</Button>
                    </div>
                </div>
                <div className="row rounded pr-3 pl-3 pb-2">
                    <div className="days__filter " >
                        {
                            showDateFilter && <span>
                                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                            </span>
                        }
                        <span className='ml-auto'>
                            <DaysFilter
                                daysFilter={daysFilter}
                                customFilterStatusChange={setShowDateFilter}
                                dateOptions={dateValue}
                                defaultDateLabel={'Last 7 Days'}
                            />
                        </span>
                        <Modal
                            className="update-users"
                            title="Date Range Error"
                            centered
                            open={isModalVisible}
                            onOk={handleModalClose}
                            footer={[
                                <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                                    OK
                                </Button>,
                            ]}
                            closable={false}
                        >
                            <p>{modalMessage}</p>
                        </Modal>
                    </div>
                    <ConfigProvider
                        theme={{ token: { colorPrimary: "#004f9f" } }}>
                        <Modal
                            centered
                            open={email}
                            onCancel={toggleEmail}
                            onOk={() => setEmail()}
                            closable={true}
                            footer={null}
                            maskClosable={false}
                            width={300}
                            key={email ? "open" : "closed"}
                        >
                            <div>
                                <div className='email-report-title'>Email Report</div>
                                <div className='email-label'>Email Address</div>
                                <Input
                                    ref={emailRef}
                                    placeholder="Enter Email"
                                    type='email'
                                    value={emailText}
                                    onChange={handleChange}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            sendEmail();
                                        }
                                    }}
                                />
                                {validEmail ? <span className='text-danger'>{validEmail}</span> : ""}
                                <Button
                                    block
                                    onClick={sendEmail}
                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", marginBlock: 8 }}>
                                    Send
                                </Button>
                            </div>
                        </Modal>
                    </ConfigProvider>
                </div>
                <div className="main-panel">
                    <div className="ChartPanel panelShadow bg-white">
                        <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                            <div className='ml-auto mr-auto'>Faults & Warnings Report</div>
                            <div className='show-entries'>
                                <ShowEntries getPageSize={onSelect} />
                            </div>
                        </div>
                        <div id="table-container">
                            <div ref={tabelRef} className="operationstatus overflow-auto customScroll">
                                <table className="billable" style={{ width: "100%" }}>
                                    <thead className='tableHeader'>
                                        <tr>
                                            <th>
                                                <div onClick={() => sortBy('battery')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Battery ID</span>
                                                    {sort.battery ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Device Type</span>
                                                    {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('severityType')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Severity Type</span>
                                                    {sort.severityType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('code')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Code</span>
                                                    {sort.code ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <span >Description</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('date')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Date</span>
                                                    {sort.date ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('time')} className='d-flex align-items-center justify-content-center highlightPointer'>
                                                    <span >Time (hh:mm:ss)</span>
                                                    {sort.time ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {faultAndWarningList?.map((item: any, index: any) => {
                                            const { batteryId, severityType, code, description, formatDate, formatTime, deviceType } = item
                                            return <tr key={index} >
                                                <td>
                                                    <Link to="/dailyBatteryPerformanceReport" state={{ batteryId }}>{batteryId}</Link>
                                                </td>
                                                <td>{deviceType}</td>
                                                {/* <td className="text-left"><label style={{ paddingLeft: '16px' }}>
                                                    <span className={severityType === "Warning" ? "round dot-yellow mb-0 mr-2 ml-4 mt-1" : "round dot-red2 mb-0 mr-2 ml-4 mt-1"} ></span>
                                                    <label style={{ verticalAlign: 'top', paddingTop: '2px' }}>{severityType}</label></label>
                                                </td> */}
                                                <td>
                                                    <div className='gap2'>
                                                        <span className={severityType === "Warning" ? "round dot-yellow mb-0 mr-2 ml-4 mt-1" : "round dot-red2 mb-0 mr-2 ml-4 mt-1"} ></span>
                                                        <span>{severityType}</span>
                                                    </div>
                                                </td>
                                                <td>{code}</td>
                                                <td>{description}</td>
                                                <td>{formatDate}</td>
                                                <td>{formatTime}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div ref={paginationRef}>
                                <PaginationComponent
                                    handlePagination={handlePagination}
                                    totalLength={totalCount}
                                    pageSize={pageSize}
                                    current={current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <SessionWarning />
                <Modal open={ismailsuccess} closable={false} centered title={"Email is sent Successfully"}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailSuccess(false)}>OK</Button>
                    ]}
                ></Modal>
                <Modal open={ismailfailure} closable={false} centered title={invalidEmail}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailFailure(false)}>OK</Button>
                    ]}
                ></Modal>
                <Modal open={codeDescription} closable={true} centered onCancel={handleCancel} footer={null}>
                    <p>Description of what the fault/warning code means ...</p>
                    <p>Visit <a href="https://www.posicharge.com/product-resources/">www.posicharge.com/product-resources/</a> for all our Product resources.
                        If you need more assistance, call customer service at +(866) 767-4242</p>
                </Modal>
            </>
        </>
    );
}

export default FaultsAndWarnings;
