import React, { useRef, useState, useEffect, FC, useLayoutEffect } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import LoadingSpinner from '../spinner/loading-spinner'
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/dateNewFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, subDays, parseISO } from 'date-fns';
import SessionWarning from '../reusableComponent/sessionWarning';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { resize, hideSidebar } from '../../settings/resize';
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  batteryRxSim: string,
  pageOffset: number,
  pageLimit: number,
  sortColumns: string,
  sortDirection: string,
  timeZone: string
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: 21,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  batteryRxSim: "",
  pageOffset: 1,
  pageLimit: 10,
  sortColumns: "",
  sortDirection: "",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh: number
}

const BatteryHistoryLogReport: FC<Props> = ({ globalData, action }) => {
  // const BatteryHistoryLogReport: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [selector, setSelector] = useState(false);
  const [sort, setSort] = useState<any>({
    batteryId: false, vehicleId: false, serialNumer: false, logNumber: false, datetime: false, initializationDate: false, daysinOp: false,
    cumulativwAhrs: false, cumulativeKwh: false, cumuDisAhrs: false, cumuDisKwh: false, nonPosiAhrs: false, nonPosiKwh: false,
    posiFastHrs: false, posiFastEvnts: false, posiFullHrs: false, posiFullEvnts: false, posiEqhrs: false, posiEqEvents: false,
    nonPosiChrgHrs: false, nonPosiChrgEvnts: false, nonPosiEqHrs: false, nonPosiEqEvents: false, runHours: false, keyOnHrs: false,
    keyOffHrs: false, MinBatteryTemp: false, AvgBatteryTemp: false, dayswithLowSoc: false, MaxRecordedDailyDischAhrs: false,
    lowWater: false, MaxRecAhrsBtwEqs: false, MaxRecDaysBtwEqs: false, daysSinceLastCompEq: false, NumberCompEqEvents: false,
    minRecVolt: false, numberLowVoltEvents: false, MaxRecVolt: false, numberHighVoltEvents: false, maxRecHighTemp: false,
    daysHighTempEvents: false, dayslowTempEvents: false, lastCompEQDate: false, lastChargeDate: false, firmwareREv: false,
    org: false, div: false, loc: false, site: false, logTimestamp: false, imageType: false
  });
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [countReq, setCountReq] = useState<reqBody>(Body)
  const [count, setCount] = useState<number>(0)
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [alert, setAlert] = useState<boolean>(false);

  const selectorRef = useRef<HTMLDivElement>(null);
  const [emptyError, setemptyError] = useState<string>("");

  const [tableHeight, setTableHeight] = useState<any>(445)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Last 60 Days', label: 'Last 60 Days' },
    { value: 'Last 90 Days', label: 'Last 90 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 80
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
  };

  const [searchObject, setSearchObject] = useState<any>({
    batteryId: '',
    vehicleId: '',
    batteryRxSim: ''
  })

  const DropList = [
    { label: "Battery ID" },
    { label: "Vehicle ID" },
    { label: "Battery Rx Serial Number" }

  ];

  const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
  const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
  const [inputvalue, setInput] = useState<any>("")
  const [column, setColumn] = useState<any[]>([])
  const [selectorLabel, setSelectorLabel] = useState<any[]>([])


  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  const onChangedropdown = (value: string) => {
    setemptyError("")
    setDropDownValue(value);
    setPlaceholder(`Search ${value}`)
    setInput("");
    const data = {
      batteryId: '',
      vehicleId: '',
      batteryRxSim: '',
      serialNumber: ''
    }
    setSearchObject(data)
    if (inputvalue) {
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1 })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1 })
      setCountReq({ ...countReq, ...data, pageOffset: 1 })
    }
  }

  const onChangePayload = (value: any) => {
    let obj = { ...searchObject };
    if (dropdownvalue === "Battery ID") {
      obj.batteryId = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Battery Rx Serial Number") {
      obj.batteryRxSim = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Vehicle ID") {
      obj.vehicleId = value;
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    }
    setemptyError("")
  }

  const callCountAndReport = (obj: any) => {
    setTableReqBody({ ...tableReqBody, ...obj })
    setExportReqBody({ ...exportReqBody, ...obj })
    setCountReq({ ...countReq, ...obj })
  };

  // Search Enter Button
  const onPressEnter = (event: any) => {
    let value = event.target.value.trim()
    let obj = { ...searchObject };
    switch (dropdownvalue) {
      case 'Battery ID':
        obj.batteryId = value;
        break;
      case 'Battery Rx Serial Number':
        obj.batteryRxSim = value;
        break;
      case 'Vehicle ID':
        obj.vehicleId = value;
        break;
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  };

  const handleSearch = () => {
    const { batteryId, vehicleId, batteryRxSim } = searchObject
    let obj = { ...searchObject }
    switch (dropdownvalue) {
      case 'Battery ID':
        if (!batteryId) {
          setemptyError("Please Enter Battery ID")
          return
        } else {
          obj.batteryId = inputvalue.trim();
        }
        break;
      case 'Battery Rx Serial Number':
        if (!batteryRxSim) {
          setemptyError("Please Enter Battery Rx Serial Number")
          return
        } else {
          obj.batteryRxSim = inputvalue.trim();
        }
        break;
      case 'Vehicle ID':
        if (!vehicleId) {
          setemptyError("Please Enter Vehicle ID")
          return
        } else {
          obj.vehicleId = inputvalue.trim();
        }
        break;
      default:
        setemptyError("")
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, pageOffset: 1 })
  }

  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
    }
  }, [value])

  useEffect(() => {
    getBatteryReport()
    dataCount()
  }, [tableReqBody])

  // useEffect(() => {
  //   if (refresh === 0) return
  //   getBatteryReport()
  //   dataCount()
  // }, [refresh]);

  const getBatteryReport = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let payload = tableReqBody;

    if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
    if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

    if (payload.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.batteryHistoryLogReport.getReport,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTableReport(response)
      setIsShowSpinner(false)
      const { batteryId, vehicleId, batteryRxSim } = searchObject;
      if (
        response != null &&
        response != undefined &&
        response.length === 0 &&
        inputvalue != ""
      ) {
        switch (dropdownvalue) {
          case "Battery ID":
            if (batteryId) {
              setemptyError("Please Enter Valid Battery ID");
              return;
            }
            break;
          case "Battery Rx Serial Number":
            if (batteryRxSim) {
              setemptyError("Please Enter Valid Battery Rx Serial Number");
              return;
            }
            break;
          case "Vehicle ID":
            if (vehicleId) {
              setemptyError("Please Enter Valid Vehicle ID");
              return;
            }
            break;
          default:
            setemptyError("");
        }
      }
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }
  const dataCount = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let payload = tableReqBody;

    if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
    if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

    if (payload.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.batteryHistoryLogReport.getCount,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: payload
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (action !== "") {
      getDowFormat(action)
    }
  }, [action])

  const getDowFormat = async (format: string) => {
    if (selectorLabel.some((item: any) => item.enabled == true)) {
      let userInfo: any = Utility.getUserInfo();
      let token = userInfo.authToken
      setIsShowSpinner(true);
      let payload = {
        orgId: tableReqBody?.orgId,
        divisionId: tableReqBody?.divisionId,
        locationId: tableReqBody?.locationId,
        buildingId: tableReqBody?.buildingId,
        startDate: tableReqBody?.startDate,
        endDate: tableReqBody?.endDate,
        batteryId: tableReqBody?.batteryId.trim(),
        vehicleId: tableReqBody?.vehicleId.trim(),
        batteryRxSim: tableReqBody?.batteryRxSim.trim(),
        pageOffset: 1,
        pageLimit: count + 1,
        sortColumns: tableReqBody?.sortColumns,
        sortDirection: tableReqBody?.sortDirection,
        timeZone: tableReqBody?.timeZone
      }
      try {
        const response = await service({
          url: constantUrl.api.batteryHistoryLogReport.dwn + '/' + format,
          method: 'POST',
          responseType: 'blob',
          headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
          data: payload
        })
        if (response) {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Battery-History-Log-Report.' + format;
          a.click();
          setIsShowSpinner(false);
        }
      } catch (error) {
        setIsShowSpinner(false);
        console.log(error)
      }
    }
    else {
      setAlert(true)
    }
  }

  const selectColumn = () => {
    if (selectorRef.current && selector) {
      selectorRef.current.style.right = "-370px";
    } else {
      if (selectorRef.current) { selectorRef.current.style.right = "0px" }
    }
    setSelector(!selector);
  };

  const applyChanges = async () => {
    selectColumn()
    var columnPayload = {
      reportId: 'BATTERY_HISTORY_LOG_REPORT',
      reportName: 'Battery History Log Report',
      columnSettings: selectorLabel,
      sortColumn: tableReqBody?.sortColumns,
      sortDirection: tableReqBody?.sortDirection
    };
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;
    setIsShowSpinner(true);
    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}`,
        method: "PUT",
        headers: { Authorization: "Bearer " + authToken },
        data: columnPayload
      })
      setIsShowSpinner(false)
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn([...columnData])
      setSelectorLabel([...selectorData])
    }
    catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  };

  const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let columndata: any = selectorLabel
    let index: number = parseInt(event.target.name);
    let checked: boolean = event.target.checked;
    columndata[index].enabled = checked
    setSelectorLabel([...columndata])
    setClearAll(false)
    setSelectAll(false)
  };

  const clearall = () => {
    let columnLable = selectorLabel
    let lengths = columnLable.length
    for (let i = 0; i < lengths; i++) {
      columnLable[i]['enabled'] = false;
    }
    setSelectorLabel([...columnLable])
    setClearAll(true)
    setSelectAll(false)
  }

  const selectall = () => {
    let columnLable = selectorLabel
    let lengths = columnLable.length
    for (let i = 0; i < lengths; i++) {
      columnLable[i]['enabled'] = true;
    }
    setSelectorLabel(columnLable)
    setClearAll(false)
    setSelectAll(true)
  }

  const cancelSelection = () => {
    let columnData = selectorLabel;
    let lengths = columnData.length
    for (let i = 0; i < lengths; i++) {
      columnData[i]['enabled'] = columnData[i].enabled;
    }
    setSelectorLabel(columnData)
    selectColumn()
  };

  const getReportSettings = async () => {
    const reportId = 'BATTERY_HISTORY_LOG_REPORT'
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
        method: "GET",
        headers: { Authorization: "Bearer " + authToken },
      })
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn(columnData)
      setSelectorLabel(selectorData)

      setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
      setExportReqBody({ ...exportReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
      setCountReq({ ...countReq, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
      setInitial(false)
    }
    catch (error) {
      console.log(error)
    }
  };

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "batteryId":
        if (sort.batteryId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "vehicleId":
        if (sort.vehicleId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "serialNumer":
        if (sort.serialNumer) {
          setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "imageType":
        if (sort.imageType) {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "logNumber":
        if (sort.logNumber) {
          setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "datetime":
        if (sort.datetime) {
          setTableReqBody({ ...tableReqBody, sortColumns: "logTimestamp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "logTimestamp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "initializationDate":
        if (sort.initializationDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "initializationDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "initializationDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "daysinOp":
        if (sort.daysinOp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysInOperation", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysInOperation", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "cumulativwAhrs":
        if (sort.cumulativwAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAhs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAhs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "cumulativeKwh":
        if (sort.cumulativeKwh) {
          setTableReqBody({ ...tableReqBody, sortColumns: "cumulativeChargeKwh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "cumulativeChargeKwh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "cumuDisAhrs":
        if (sort.cumuDisAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeAh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeAh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "cumuDisKwh":
        if (sort.cumuDisKwh) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeKwh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalDischargeKwh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiAhrs":
        if (sort.nonPosiAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiAh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiAh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiKwh":
        if (sort.nonPosiKwh) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiKwh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiKwh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiFastHrs":
        if (sort.posiFastHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiFastEvnts":
        if (sort.posiFastEvnts) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiFullHrs":
        if (sort.posiFullHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiFullEvnts":
        if (sort.posiFullEvnts) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiEqhrs":
        if (sort.posiEqhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiEqEvents":
        if (sort.posiEqEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "completeEqEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "completeEqEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiChrgHrs":
        if (sort.nonPosiChrgHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiChargeHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiChargeHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiChrgEvnts":
        if (sort.nonPosiChrgEvnts) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiChargeEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiChargeEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiEqHrs":
        if (sort.nonPosiEqHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiEqEvents":
        if (sort.nonPosiEqEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "runHours":
        if (sort.runHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "runHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "runHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "keyOnHrs":
        if (sort.keyOnHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOnHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOnHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "keyOffHrs":
        if (sort.keyOffHrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOffHours", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOffHours", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "MinBatteryTemp":
        if (sort.MinBatteryTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "AvgBatteryTemp":
        if (sort.AvgBatteryTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "avgBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "avgBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dayswithLowSoc":
        if (sort.dayswithLowSoc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "socDays", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "socDays", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "MaxRecordedDailyDischAhrs":
        if (sort.MaxRecordedDailyDischAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDailyDischargeAh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDailyDischargeAh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "lowWater":
        if (sort.lowWater) {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowWaterDays", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowWaterDays", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "MaxRecAhrsBtwEqs":
        if (sort.MaxRecAhrsBtwEqs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "MaxRecDaysBtwEqs":
        if (sort.MaxRecDaysBtwEqs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "daysSinceLastCompEq":
        if (sort.daysSinceLastCompEq) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysSinceCompEq", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysSinceCompEq", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "NumberCompEqEvents":
        if (sort.NumberCompEqEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalCompEqEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "totalCompEqEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "minRecVolt":
        if (sort.minRecVolt) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minRecordedVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minRecordedVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "numberLowVoltEvents":
        if (sort.numberLowVoltEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "MaxRecVolt":
        if (sort.MaxRecVolt) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxRecordedVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxRecordedVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "numberHighVoltEvents":
        if (sort.numberHighVoltEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHighVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHighVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxRecHighTemp":
        if (sort.maxRecHighTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxRecordedTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxRecordedTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "daysHighTempEvents":
        if (sort.daysHighTempEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHightTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysHightTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dayslowTempEvents":
        if (sort.dayslowTempEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "daysLowTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "lastCompEQDate":
        if (sort.lastCompEQDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCompEq", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCompEq", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "lastChargeDate":
        if (sort.lastChargeDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCharge", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateLastCharge", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "firmwareREv":
        if (sort.firmwareREv) {
          setTableReqBody({ ...tableReqBody, sortColumns: "firmwareRevision", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "firmwareRevision", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "org":
        if (sort.org) {
          setTableReqBody({ ...tableReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "div":
        if (sort.div) {
          setTableReqBody({ ...tableReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "loc":
        if (sort.loc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "site":
        if (sort.site) {
          setTableReqBody({ ...tableReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "logTimestamp":
        if (sort.logTimestamp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "logTimestamp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "logTimestamp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset })
    setCurrent(pageOffset)
  }

  const onSelect = (pageLimit: any) => {
    const numPageSize = Number(pageLimit)
    setCurrent(1)
    setPageSize(numPageSize)
    setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
  };

  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const daysFilter = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    const isLeapYearAtStart = isLeapYear(startYear);
    const isLeapYearAtEnd = isLeapYear(endYear);
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
    if (Difference_In_Days > maxDays) {
      setModalMessage(`The maximum range allowed is 1 year.`);
      setIsModalVisible(true);
      return;
    }
    setTableReqBody({ ...tableReqBody, startDate, endDate });
  };

  const handleModalClose = () => {
    onChange([subDays(new Date(), 6), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('battery_history_logs_W')) window.history.back()
    }
    setInitial(true)
    getReportSettings();
  }, [])

  useEffect(() => {
    if (initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setInput("");
      setemptyError("");
      const data = {
        batteryId: '',
        vehicleId: '',
        batteryRxSim: '',
        serialNumber: ''
      }
      setSearchObject(data)
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setCountReq({ ...countReq, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setCurrent(1)
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div ref={filtersRef} className="posi_search_container">
        <div className="col-md-6 px-0 drop_search-main">
          <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
            <div className='ant-design-select'>
              <Select
                value={dropdownvalue}
                size={'middle'}
                onChange={onChangedropdown}
                className='drop-filter'
                dropdownStyle={{ zIndex: 900 }}
              >
                {DropList && DropList.map((item: any, index: any) => {
                  return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                })}
              </Select>
            </div>
            <div>
              <Space direction="vertical" size="middle" className='search-input'>
                <Space.Compact className='posi_bmid_search_width'>
                  <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                    onPressEnter={onPressEnter}
                  />
                  <Button type="primary" onClick={handleSearch}>Find</Button>
                </Space.Compact>
              </Space>
              <div className="errorColor">{emptyError}</div>
            </div>
          </ConfigProvider>
        </div>
        <div className="column-date-filter position-relative">
          {
            showDateFilter && <span className="date-selector">
              <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
            </span>
          }
          <button
            onClick={selectColumn}
            type="button"
            className="btn btn-light selector-btn"
          >
            Column Selector
          </button>
          <span className="date-dropdown">
            <DaysFilter
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 7 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
        </div>
      </div>
      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className="table_title">Battery History Log Report</div>
            <div className="show-entries">
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div style={{ height: tableHeight + 'px' }} className="history_report overflow-auto scrollbar">
              <table className="billable width100">
                <thead className='tableHeader'>
                  <tr className="billable-head">
                    {column?.[0]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[0]?.['columnName']}</span>
                        {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                      : ""}
                    {column?.[1]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[1]?.['columnName']}</span>
                        {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[2]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('serialNumer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[2]?.['columnName']}</span>
                        {sort.serialNumer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[3]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[3]?.['columnName']}</span>
                        {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[4]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('logNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[4]?.['columnName']}</span>
                        {sort.logNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[5]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('datetime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[5]?.['columnName']}</span>
                        {sort.datetime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[6]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('initializationDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[6]?.['columnName']}</span>
                        {sort.initializationDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[7]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('daysinOp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[7]?.['columnName']}</span>
                        {sort.daysinOp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[8]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('cumulativwAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[8]?.['columnName']}</span>
                        {sort.cumulativwAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[9]?.['enabled'] ? <th><span>{column?.[9]?.['columnName']}</span></th> : ""}
                    {column?.[10]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('cumuDisAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[10]?.['columnName']}</span>
                        {sort.cumuDisAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[11]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('cumuDisKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[11]?.['columnName']}</span>
                        {sort.cumuDisKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[12]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[12]?.['columnName']}</span>
                        {sort.nonPosiAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[13]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[13]?.['columnName']}</span>
                        {sort.nonPosiKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[14]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiFastHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[14]?.['columnName']}</span>
                        {sort.posiFastHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[15]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiFastEvnts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[15]?.['columnName']}</span>
                        {sort.posiFastEvnts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[16]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiFullHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[16]?.['columnName']}</span>
                        {sort.posiFullHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[17]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiFullEvnts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[17]?.['columnName']}</span>
                        {sort.posiFullEvnts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[18]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiEqhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[18]?.['columnName']}</span>
                        {sort.posiEqhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[19]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiEqEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[19]?.['columnName']}</span>
                        {sort.posiEqEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[20]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiChrgHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[20]?.['columnName']}</span>
                        {sort.nonPosiChrgHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[21]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiChrgEvnts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[21]?.['columnName']}</span>
                        {sort.nonPosiChrgEvnts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[22]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiEqHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[22]?.['columnName']}</span>
                        {sort.nonPosiEqHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[23]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiEqEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[23]?.['columnName']}</span>
                        {sort.nonPosiEqEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[24]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('runHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[24]?.['columnName']}</span>
                        {sort.runHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[25]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('keyOnHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[25]?.['columnName']}</span>
                        {sort.keyOnHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[26]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('keyOffHrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[26]?.['columnName']}</span>
                        {sort.keyOffHrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[27]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('MinBatteryTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[27]?.['columnName']}</span>
                        {sort.MinBatteryTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[28]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('AvgBatteryTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[28]?.['columnName']}</span>
                        {sort.AvgBatteryTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[29]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dayswithLowSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[29]?.['columnName']}</span>
                        {sort.dayswithLowSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[30]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('MaxRecordedDailyDischAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[30]?.['columnName']}</span>
                        {sort.MaxRecordedDailyDischAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[31]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('lowWater')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[31]?.['columnName']}</span>
                        {sort.lowWater ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[32]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('MaxRecAhrsBtwEqs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[32]?.['columnName']}</span>
                        {sort.MaxRecAhrsBtwEqs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[33]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('MaxRecDaysBtwEqs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[33]?.['columnName']}</span>
                        {sort.MaxRecDaysBtwEqs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[34]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('daysSinceLastCompEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[34]?.['columnName']}</span>
                        {sort.daysSinceLastCompEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[35]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('NumberCompEqEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[35]?.['columnName']}</span>
                        {sort.NumberCompEqEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[36]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('minRecVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[36]?.['columnName']}</span>
                        {sort.minRecVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[37]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('numberLowVoltEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[37]?.['columnName']}</span>
                        {sort.numberLowVoltEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[38]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('MaxRecVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[38]?.['columnName']}</span>
                        {sort.MaxRecVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[39]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('numberHighVoltEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[39]?.['columnName']}</span>
                        {sort.numberHighVoltEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[40]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxRecHighTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[40]?.['columnName']}</span>
                        {sort.maxRecHighTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[41]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('daysHighTempEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[41]?.['columnName']}</span>
                        {sort.daysHighTempEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[42]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dayslowTempEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[42]?.['columnName']}</span>
                        {sort.dayslowTempEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[43]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('lastCompEQDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[43]?.['columnName']}</span>
                        {sort.lastCompEQDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[44]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('lastChargeDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[44]?.['columnName']}</span>
                        {sort.lastChargeDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[45]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('firmwareREv')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[45]?.['columnName']}</span>
                        {sort.firmwareREv ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[46]?.['enabled'] ? <th>{column?.[46]?.['columnName']}</th> : ""}
                    {column?.[47]?.['enabled'] ? <th>{column?.[47]?.['columnName']}</th> : ""}
                    {column?.[48]?.['enabled'] ? <th>{column?.[48]?.['columnName']}</th> : ""}
                    {column?.[49]?.['enabled'] ? <th>{column?.[49]?.['columnName']}</th> : ""}
                    {column?.[50]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('logTimestamp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[50]?.['columnName']}</span>
                        {sort.logTimestamp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((item: any, index: any) => {
                    const { batteryId, vehicleId, logNumber, initializationDate, daysInOperation,
                      nonPosiAh, nonPosiKwh, fastChargeHours,
                      fastChargeEvents, fullChargeHours, fullChargeEvents, eqHours, nonPosiChargeHours, nonPosiChargeEvents,
                      nonPosiEqHours, nonPosiEqEvents, runHours, keyOnHours, keyOffHours,
                      socDays, maxDailyDischargeAh, lowWaterDays, maxAhsBetweenEqs, maxDaysBetweenEqs, daysSinceCompEq,
                      totalCompEqEvents, minRecordedVoltage, daysLowVoltage, maxRecordedVoltage, daysHighVoltage,
                      dateLastCharge, firmwareRevision, orgName, divisionName, locationName, buildingName,
                      dateLastCompEq, totalChargeAhs, totalDischargeAh, totalDischargeKwh, completeEqEvents,
                      logTimestamp, daysHightTemp, daysLowTemp, totalChargeKwh, batteryRxSim, formatLogTimestamp, formatInitializationDate,
                      formatDateLastCompEq, formatDateLastCharge, formatMinBatteryTemp, formatAvgBatteryTemp, formatMaxRecordedTemp, deviceType } = item
                    return (
                      <React.Fragment>
                        <tr key={'index1' + index}>
                          {column?.[0]?.['enabled'] ? <td>{batteryId}</td> : ""}
                          {column?.[1]?.['enabled'] ? <td>{vehicleId}</td> : ""}
                          {column?.[2]?.['enabled'] ? <td>{batteryRxSim}</td> : ""}
                          {column?.[3]?.['enabled'] ? <td>{deviceType}</td> : ""}
                          {column?.[4]?.['enabled'] ? <td>{logNumber}</td> : ""}
                          {column?.[5]?.['enabled'] ? <td>{formatLogTimestamp}</td> : ""}
                          {column?.[6]?.['enabled'] ? <td>{formatInitializationDate}</td> : ""}
                          {column?.[7]?.['enabled'] ? <td>{daysInOperation}</td> : ""}
                          {column?.[8]?.['enabled'] ? <td>{totalChargeAhs}</td> : ""}
                          {column?.[9]?.['enabled'] ? <td>{totalChargeKwh}</td> : ""}
                          {column?.[10]?.['enabled'] ? <td>{totalDischargeAh}</td> : ""}
                          {column?.[11]?.['enabled'] ? <td>{totalDischargeKwh}</td> : ""}
                          {column?.[12]?.['enabled'] ? <td>{nonPosiAh}</td> : ""}
                          {column?.[13]?.['enabled'] ? <td>{nonPosiKwh}</td> : ""}
                          {column?.[14]?.['enabled'] ? <td>{fastChargeHours}</td> : ""}
                          {column?.[15]?.['enabled'] ? <td>{fastChargeEvents}</td> : ""}
                          {column?.[16]?.['enabled'] ? <td>{fullChargeHours}</td> : ""}
                          {column?.[17]?.['enabled'] ? <td>{fullChargeEvents}</td> : ""}
                          {column?.[18]?.['enabled'] ? <td>{eqHours}</td> : ""}
                          {column?.[19]?.['enabled'] ? <td>{completeEqEvents}</td> : ""}
                          {column?.[20]?.['enabled'] ? <td>{nonPosiChargeHours}</td> : ""}
                          {column?.[21]?.['enabled'] ? <td>{nonPosiChargeEvents}</td> : ""}
                          {column?.[22]?.['enabled'] ? <td>{nonPosiEqHours}</td> : ""}
                          {column?.[23]?.['enabled'] ? <td>{nonPosiEqEvents}</td> : ""}
                          {column?.[24]?.['enabled'] ? <td>{runHours}</td> : ""}
                          {column?.[25]?.['enabled'] ? <td>{keyOnHours}</td> : ""}
                          {column?.[26]?.['enabled'] ? <td>{keyOffHours}</td> : ""}
                          {column?.[27]?.['enabled'] ? <td>{formatMinBatteryTemp}</td> : ""}
                          {column?.[28]?.['enabled'] ? <td>{formatAvgBatteryTemp}</td> : ""}
                          {column?.[29]?.['enabled'] ? <td>{socDays}</td> : ""}
                          {column?.[30]?.['enabled'] ? <td>{maxDailyDischargeAh}</td> : ""}
                          {column?.[31]?.['enabled'] ? <td>{lowWaterDays}</td> : ""}
                          {column?.[32]?.['enabled'] ? <td>{maxAhsBetweenEqs}</td> : ""}
                          {column?.[33]?.['enabled'] ? <td>{maxDaysBetweenEqs}</td> : ""}
                          {column?.[34]?.['enabled'] ? <td>{daysSinceCompEq}</td> : ""}
                          {column?.[35]?.['enabled'] ? <td>{totalCompEqEvents}</td> : ""}
                          {column?.[36]?.['enabled'] ? <td>{minRecordedVoltage}</td> : ""}
                          {column?.[37]?.['enabled'] ? <td>{daysLowVoltage}</td> : ""}
                          {column?.[38]?.['enabled'] ? <td>{maxRecordedVoltage}</td> : ""}
                          {column?.[39]?.['enabled'] ? <td>{daysHighVoltage}</td> : ""}
                          {column?.[40]?.['enabled'] ? <td>{formatMaxRecordedTemp}</td> : ""}
                          {column?.[41]?.['enabled'] ? <td>{daysHightTemp}</td> : ""}
                          {column?.[42]?.['enabled'] ? <td>{daysLowTemp}</td> : ""}
                          {column?.[43]?.['enabled'] ? <td>{formatDateLastCompEq}</td> : ""}
                          {column?.[44]?.['enabled'] ? <td>{formatDateLastCharge}</td> : ""}
                          {column?.[45]?.['enabled'] ? <td>{firmwareRevision}</td> : ""}
                          {column?.[46]?.['enabled'] ? <td>{orgName}</td> : ""}
                          {column?.[47]?.['enabled'] ? <td>{divisionName}</td> : ""}
                          {column?.[48]?.['enabled'] ? <td>{locationName}</td> : ""}
                          {column?.[49]?.['enabled'] ? <td>{buildingName}</td> : ""}
                          {column?.[50]?.['enabled'] ? <td>{formatLogTimestamp}</td> : ""}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                  {tableReport?.length === 0 && <tr><td colSpan={column.filter((item: any) => item?.['enabled'] === true)?.length} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>
        </div>
        <div ref={selectorRef} className="column-selector selector ">
          <div className="column-title">Column Selector</div>
          <div className="slector-body">
            <label>
              <input
                checked={clearAll}
                value="clearAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={clearall}
              />
              <span></span>
            </label>
            <p>Clear All</p>

            <label>
              <input
                checked={selectAll}
                value="selectAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={selectall}
              />
              <span></span>
            </label>
            <p>Select All</p>
          </div>
          <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
            {selectorLabel?.map((item: any, index: any) => {
              return (
                <div key={index + 2} className="slector-body">
                  <label>
                    <input
                      key={index}
                      name={index}
                      onChange={onchange}
                      checked={item['enabled']}
                      value={item.value}
                      type="checkbox"
                      aria-label="checkbox"
                    />
                    <span></span>
                  </label>
                  <p>{item['columnName']}</p>
                </div>
              )
            })}
          </div>
          <div className="m-flex">
            <button onClick={cancelSelection} className="cancel">
              Cancel
            </button>
            <button onClick={applyChanges} className="apply-changes mr-3">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={alert}
        closable={false}
        centered title={"Atleast Select 1 Column"}
        footer={[
          <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
        ]}
      ></Modal>
      <SessionWarning />
    </>
  );
};

export default BatteryHistoryLogReport;