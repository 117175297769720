import React, { useState, useEffect, useRef, FC } from 'react';
import LoadingSpinner from '../spinner/loading-spinner'
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';
import ShowEntries from '../reusableComponent/showEntries';
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import SessionWarning from '../reusableComponent/sessionWarning';
import { useLocation } from 'react-router-dom';
import { format, subDays, parseISO, startOfMonth } from 'date-fns';
import { resize, hideSidebar } from '../../settings/resize';
import { Input, AutoComplete, ConfigProvider, Select, Space, Button } from "antd";

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const FirmwareUpdateResults: FC<Props> = ({ globalData, action }) => {
    // const FirmwareUpdateResults: FC<Props> = ({ globalData, action, refresh }) => {
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
    const [tableReport, setTableReport] = useState<any[]>([]);
    const location = useLocation();
    const propsData = location.state;

    const getBmids = async (id: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        setIsShowSpinner(true);

        let payload = {
            "pageOffset": 1,
            "pageLimit": 10,
            "sortColumn": "",
            "sortDirection": "",
            "timeZone": ""
          }

        try {
            const response = await service({
                url: constantUrl.api.formwareControlPanel.getFirmwareBMIDsByQueueId+id+"/bmids",
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTableReport(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("get Firmwares:", error);
            setIsShowSpinner(false);
        }
    }

    useEffect(()=>{
        getBmids(propsData?.id)
    }, [])

    const getDowFormat = async (format: string) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "pageOffset": 1,
            "pageLimit": tableReport.length,
            "sortColumn": "",
            "sortDirection": "",
            "timeZone": ""
          }

        try {
            const response = await service({
                url: constantUrl.api.formwareControlPanel.downloadFirmwareBmids + propsData?.id + "/bmids/download/"+ format,
                method: 'POST',
                responseType: 'blob',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })

            if (response) {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'FirmwareUpdateResults.' + format;
                a.click();
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    useEffect(() => {
        window.scrollTo(0, 0)
        resize()
        hideSidebar();
      }, []);

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="main-panel pt-3 position-relative">
            <div className="ChartPanel panelShadow bg-white">
                <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                    <div className='ml-auto mr-auto'>Firmware Update Results Report</div>
                </div>
                <div id="table-container">
                    <div className="battery-history overflow-auto scrollbar">
                        <table className="billable width100">
                            <thead>
                                <tr className="billable-head">
                                    <th>Battery ID</th>
                                    <th>Vehicle ID</th>
                                    <th>Battery Rx SIM #</th>
                                    <th>Device Type</th>
                                    <th>Start Date</th>
                                    <th>Firmware</th>
                                    <th>Telephone</th>
                                    <th>Last Complete Session</th>
                                    <th>In Svc Date</th>
                                    <th>Is E-Meter</th>
                                    <th>E-Meter Identifier</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableReport?.map((report, index) => {
                                        return <tr key={`battery_rx_units_${index}`}>
                                            <td>{report?.batteryId}</td>
                                            <td>{report?.vehicleId}</td>
                                            <td>{report?.serialNumber}</td>
                                            <td>{report?.deviceType}</td>
                                            <td>{report?.formatDateUpdated}</td>
                                            <td>{report?.appVersion}</td>
                                            <td>{report?.telephoneNumber}</td>
                                            <td>{report?.formatDateLastCompleteSession}</td>
                                            <td>{report?.formatInServiceDate}</td>
                                            <td>{report?.isEmeter}</td>
                                            <td>{report?.emeterIdentifier}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <SessionWarning />
        </>
    );
}


export default FirmwareUpdateResults;