import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  summaryData: {
    faultCount:number;
    warningsCount:number;
  };
}

const DealerTaskSummary: FC<Props> = ({ summaryData }) => {
  return (
    <div className="row posicharge-div posi_mobile_tasksummary">
      <div className="col-md-6  faults-container">
        <Link to={"/faultsandwarnings"} style={{ textDecoration: "none" }}>
        <div className="task-card task-card-flex">
          <img
            src={require("../../images/Alerts-Icon.png")}
            className="task-image"
            alt="img"
          />
          <div className="f-s-right">
            <div id="sever-faults-count" className="font40">
              {summaryData?.faultCount}
            </div>
            <div className="count-label">FAULTS</div>
          </div>
        </div>
        </Link>
      </div>
      <div className="col-md-6 warnings-container posicharge-overdue-task admin-posicharge2">
      <Link to={"/faultsandwarnings"} style={{ textDecoration: "none" }}>
        <div className="warning-task task-card-flex">
          <img
            src={require("../../images/OverdueTasks.png")}
            className="task-image"
            alt="img"
          />
          <div className="f-s-right" style={{ width: "100%" }}>
            <div id="overdue-count" className="font40">
              {summaryData?.warningsCount}
            </div>
            <div className="count-label">WARNINGS</div>
          </div>
        </div>
      </Link>  
      </div>
    </div>
  );
};

export default DealerTaskSummary;
