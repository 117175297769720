import { format, startOfWeek, subDays } from 'date-fns';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import World from 'fusioncharts/maps/fusioncharts.world';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import React from 'react';
import ReactFC from 'react-fusioncharts';
import { Link, Navigate } from 'react-router-dom';
import { Utility } from '../../helper/index';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import LocationIcon from '../../images/location@2x.png';
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";
import service from '../../service/api/common';
import constantUrl from '../../settings/constants';
import { hideSidebar, resize } from '../../settings/resize';
import DealerTaskSummary from '../reusableComponent/dealerTaskSummary';
import FaultSummary from '../reusableComponent/faultSummary';
import OrgSummary from '../reusableComponent/orgSummary';
import SessionWarning from '../reusableComponent/sessionWarning';
import LoadingSpinner from '../spinner/loading-spinner';


ReactFC.fcRoot(FusionCharts, Maps, World, FusionTheme,);

interface MyState {
  toggleOperation: boolean,
  data: [],
  totalPage: number,
  current: number,
  minIndex: number,
  maxIndex: number,
  value: [Date, Date],
  pathName: string,
  calender: boolean,
  pageSize: number,
  showChanger: boolean,
  pageSizeOptions: [number, number, number, number],
  leftHeight: number,
  donutHeight: number,
  dealers: any,
  search: string,
  isRedirectLogin: boolean,
  token: string,
  dropDownsDealers: [],
  dealerId: number,
  companyId: number,
  dropDownCompanies: [],
  systemSummary: {
    dealerCount: number,
    companiesCount: number,
    locationsCount: number,
    usersCount: number
  },
  open: boolean,
  operationStatus: {
    highTempBatteriesCount: number,
    missedEQsCount: number,
    lowWaterCount: number,
    lowSOCCount: number,
    miscellaneousEventsCount: number
  },
  taskSummary: {
    faultCount: number,
    warningsCount: number
    // upcomingTasks: number
  },
  soc: {
    highSOCUnits: number,
    lowSOCUnits: number,
    totalChargeCount: number,
    totalDisconnectedCount: number,
    totalInstalledUnits: number,
    totalNoChargeUnits: number
  },
  summaryUrl: string,
  accountId: number,
  companyAlert: {
    CoId: number,
    CoName: string
  },
  isShowSpinner: boolean,
  thisWeek: boolean,
  dealerdashboard: {
    dealerDashboarDealerId: number,
    dealerDashboarCompanyId: number,
  }
  companydashboard: {
    companyDashboardCoId: number
  },
  loggedUserId: number | null,
  date: { startDate: string, endDate: string },
  countryResp: any[],
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  initial: boolean,
  sysSummary: boolean
}

interface item {
  Height: {},
  name: string,
  locationsCount: string,
  faultCount: number,
  warningsCount: number,
  orderId: number
}

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

export default class AdminDashBoard extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      isRedirectLogin: false,
      userInfo: {},
      toggleOperation: false,
      data: [],
      totalPage: 0,
      current: 1,
      minIndex: 0,
      maxIndex: 0,
      value: [new Date(), new Date()],
      pathName: "",
      calender: false,
      pageSize: 10,
      showChanger: false,
      pageSizeOptions: [10, 20, 50, 100],
      leftHeight: 0,
      donutHeight: 200,
      dealers: [],
      search: "",
      token: "",
      dropDownsDealers: [],
      dealerId: 0,
      companyId: 0,
      dropDownCompanies: [],
      open: false,
      systemSummary: {
        dealerCount: 0,
        companiesCount: 0,
        locationsCount: 0,
        usersCount: 0,
        siteCount: 0,
        divicesCount: 0
      },
      operationStatus: {
        highTempBatteriesCount: 0,
        missedEQsCount: 0,
        lowWaterCount: 0,
        lowSOCCount: 0,
        miscellaneousEventsCount: 0
      },
      taskSummary: {
        faultCount: 0,
        warningsCount: 0,
        // upcomingTasks: 0,
      },
      soc: {
        highSOCUnits: 0,
        lowSOCUnits: 0,
        totalChargeCount: 0,
        totalDisconnectedCount: 0,
        totalInstalledUnits: 0,
        totalNoChargeUnits: 0
      },
      summaryUrl: "",
      accountId: 0,
      companyAlert: {
        CoId: 0,
        CoName: "",
      },
      thisWeek: false,
      dealerdashboard: {
        dealerDashboarDealerId: 0,
        dealerDashboarCompanyId: 0,
      },
      companydashboard: {
        companyDashboardCoId: 0
      },
      loggedUserId: null,
      date: {
        startDate: format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy'),
        endDate: format(new Date(), 'dd-MMM-yyyy')
      },
      countryResp: [],
      initial: true,
      sysSummary: true,
      loadingCount: 0,
      isShowSpinner: false,
      taskApicall: false,
      summaryApicall: false,
      operatoinApicall: false,
      alertApicall: false
    }

    this.claenderonChange = this.claenderonChange.bind(this);
    this.showCalender = this.showCalender.bind(this);
    this.filterDealer = this.filterDealer.bind(this)
    this.hideLoader = this.hideLoader.bind(this)
  }


  hideLoader = () => {
    const { taskApicall, summaryApicall, operatoinApicall, alertApicall } = this.state
    if (taskApicall && summaryApicall && operatoinApicall && alertApicall) {
      this.setState({ isShowSpinner: false })
    }
  }


  handleResize = () => {
    let timer: any
    clearTimeout(timer)
    timer = setTimeout(() => {
      resize()
    }, 500)
  };

  componentWillUnmount() {
    // Remove event listener when component is unmounted
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    // check the page permission
    window.addEventListener('resize', this.handleResize);
    resize();
    hideSidebar();

    let permission: any = localStorage.getItem('permissions')
    if (permission !== null) {
      if (!permission.includes('adminDashBoard_W')) window.history.back()
    }

    if (Utility.hasUserEmpty()) {
      this.setState({ isRedirectLogin: true })
    } else {
      this.setState({ pathName: this.props.path, globalData: this.props.globalData })
      let leftSideHeight = document.getElementById('leftCol')?.offsetHeight
      if (leftSideHeight) {
        this.setState({ leftHeight: leftSideHeight })
      }
      let userInfo: any = Utility.getUserInfo();
      var gettoken = userInfo.authToken;
      let userId = userInfo.userId
      this.setState({ token: gettoken, loggedUserId: userId, accountId: userId })

      const today = new Date()
      today.setDate(today.getDate());
      const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
      const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
      this.setState({ date: { ...this.state.date, startDate: firstDayOfWeek, endDate: lastDayOfWeek } })
      setTimeout(() => {
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        this.getTaskSummary(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
        this.getSummary(data?.orgId, data?.divId, data?.locationId, data?.buildingId);
        this.getDealerAlerts(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
        this.setState({ initial: false, orgId: data?.orgId, divId: data?.divId })
      }, 3000);
    }
  }

  getTaskSummary = async (orgId: number, divId: number, location: number, building: number) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    let url = constantUrl.api.faultSummaryDashboard.taskSummaryCount;
    let type = "POST";
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: location,
      buildingId: building
    }
    this.setState({ isShowSpinner: true })
    try {
      const response = await service({
        url: url,
        method: type,
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: payload
      });

      const { faultCount, warningsCount} = response
      //cosnt {faultCount, warningsCount} = response
      this.setState({ taskSummary: { ...this.state.taskSummary,faultCount, warningsCount} })
    } catch (error) {
      console.log("getTaskSummary", error)
    } finally {
      this.setState({ taskApicall: true }, () => { this.hideLoader() })

    }
  }

  async getSummary(orgId: number, divId: number, locationId: number, buildingId: number) {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    var loginDealerId = userInfo.userId;

    let orgid = orgId > -1 ? orgId : loginDealerId;

    let url = constantUrl.api.dashboard.getCompanySummary;

    let payload = {
      orgId: orgid,
      divisionId: divId,
      locationId: locationId,
      buildingId: buildingId
    }
    this.setState({ isShowSpinner: true })
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });

      const { companiesCount, dealerCount, locationsCount, usersCount, siteCount, divicesCount } = response;

      this.setState({
        systemSummary: {
          ...this.state.systemSummary,
          dealerCount: dealerCount,
          companiesCount: companiesCount,
          usersCount: usersCount,
          locationsCount: locationsCount,
          siteCount: siteCount,
          divicesCount: divicesCount
        }
      })
    } catch (error) {
      console.error("systemSummaryErr:", error);
    } finally {
      this.setState({ summaryApicall: true }, () => { this.hideLoader() })
    }
  }

  dealerSoc = async (startDate: string, endDate: string, orgId: number, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    let url = constantUrl.api.dashboard.getOperationSoc;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: buildId,
      startDate: startDate,
      endDate: endDate
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token, },
        data: payload
      });
      if (response) {
        const { highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits } = response
        this.setState({
          soc: {
            ...this.state.soc,
            highSOCUnits,
            lowSOCUnits,
            totalChargeCount,
            totalDisconnectedCount,
            totalInstalledUnits,
            totalNoChargeUnits
          }
        })
      }
    } catch (error) {
      console.warn(error);
    }
  }

  getOperationStatus = async (firstDay: any, lastDay: any, orgId: number, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    var url = constantUrl.api.faultSummaryDashboard.faultSummary;
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: buildId,
      startDate: sevenDaysAgo,
      endDate: today,
      // weekStartDay: localStorage.getItem("weeklyStartDay")
    }
    this.setState({ isShowSpinner: true })
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        data: payload
      });

      if (response) {
        const { highTempBatteriesCount, missedEQsCount, lowWaterCount, lowSOCCount, miscellaneousEventsCount} = response
        this.setState({
          operationStatus: {
            ...this.state.operationStatus,
            highTempBatteriesCount: highTempBatteriesCount,
            missedEQsCount: missedEQsCount,
            lowWaterCount: lowWaterCount,
            lowSOCCount: lowSOCCount,
            miscellaneousEventsCount: miscellaneousEventsCount
          }
        })
      }
    }
    catch (error) {
      console.log("operationStatusError:", error)
    } finally {
      this.setState({ operatoinApicall: true }, () => { this.hideLoader() })
    }
  }

  worldSummary = async (orgId: any, divId: number, locId: number, buildId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;
    let url = constantUrl.api.dashboard.getWorldSummary;

    let payload = {
      "orgId": orgId,
      "divisionId": divId,
      "locationId": locId,
      "buildingId": buildId
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      this.setState({ countryResp: response })
    }
    catch (error) {
      console.log(error)
    }
  }

  getDealerAlerts = async (orgId: number, divId: number, locationId: number, buildingId: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let url = constantUrl.api.faultSummaryDashboard.dealerFaultAlerts;
    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locationId,
      buildingId: buildingId
    };

    this.setState({ isShowSpinner: true })
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload
      });

      if (response) {
        this.setState({
          dealers: response,
          totalPage: response.length / this.state.pageSize,
          minIndex: 0,
          maxIndex: this.state.pageSize,
          showChanger: response.length > 10 ? true : false,
        })
      } else {
      }
    } catch (error) {
      console.log("systemSummaryErr:", error);
    } finally {
      this.setState({ alertApicall: true }, () => { this.hideLoader() })
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<MyState>, snapshot?: any): void {
    if (this.state.leftHeight === prevState.leftHeight) {
      let mapHeight = document.getElementById('world_map')?.offsetHeight
      let headerPlusSearch: any = document.getElementById('summaryCardsTitle')?.offsetHeight
      let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
      let cardTopBottomMargin = 40
      let cardCount = 10
      let paginationHeight: any = document.getElementById('pagination-div')?.offsetHeight
      let cardHeight: any = document.getElementById('card-height')?.offsetHeight
      let rightSideHeight = mapHeight + headerPlusSearch + (cardHeight + cardTopBottomMargin) * cardCount
      let leftHeight: any = document.getElementById('leftCol')?.offsetHeight
      if (leftHeight) { localStorage.setItem('leftsideheight', JSON.stringify(leftHeight)) }

      let remaningHeight = leftHeight - (mapHeight + headerPlusSearch + paginationHeight + searchAlertHeight + cardTopBottomMargin)
      if (rightSideHeight > leftHeight && window.innerWidth < 1025) {
        let getDiv = document.querySelector("[class='alerts-container']") as HTMLElement
        getDiv.style.height = (remaningHeight - paginationHeight) + 'px'
      }
      if (rightSideHeight > leftHeight && window.innerWidth > 1900) {
        let getDiv = document.querySelector("[class='alerts-container']") as HTMLElement
        getDiv.style.height = (remaningHeight) + 'px'
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<any>): void {
    if (this.state.initial === false) {
      const today = new Date()
      today.setDate(today.getDate());
      this.getTaskSummary(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
      this.getSummary(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId);
      this.getDealerAlerts(nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
      this.getOperationStatus(this.state.date.startDate, this.state.date.endDate, nextProps?.globalData?.orgId, nextProps?.globalData?.divId, nextProps?.globalData?.locationId, nextProps?.globalData?.buildingId)
    }
  }

  handleChange = (page: number, getPageSize: number) => {
    this.setState({
      current: page,
      minIndex: (page - 1) * getPageSize,
      maxIndex: page * getPageSize,
      pageSize: getPageSize
    });
  }

  claenderonChange(value: any) {
    let startDate = format(new Date(value[0]), 'dd-MMM-yyyy')
    let endDate = format(new Date(value[1]), 'dd-MMM-yyyy')
    let label = document.getElementById("dateRangeLabel")
    if (label) label.innerText = `${startDate}-${endDate}`
    this.setState({ open: false, value: [new Date(startDate), new Date(endDate)] })
  }

  showCalender() { this.setState({ calender: !this.state.calender }) }

  onSearch = (value: string) => { this.setState({ search: value }) }

  filterDealer(event: any) {
    let value = event.target.value
    this.setState({ search: value.length < 3 ? "" : value })
  }

  dateFilter = (startDate: string, endDate: string, getGlobalData?: any) => {
    setTimeout(() => {
      var data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);
      this.setState({ date: { ...this.state.date, startDate, endDate } })
      this.getOperationStatus(startDate, endDate, data?.orgId, data?.divId, data?.locationId, data?.buildingId)
    }, 5000);
  }

  render() {
    const { isShowSpinner } = this.state;
    var data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    if (this.state.isRedirectLogin) {
      return (
        <Navigate to={"/login"} />
      );
    }
    return (
      <>
        <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
        <div className="main-panel">
          <div className="ChartPanel">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-7 left-main-col" id='leftCol'>
                  <DealerTaskSummary
                    summaryData={this.state.taskSummary}
                  />
                  <div className="row row2 justify-content-between mt-3  ">
                    <div className="col-md-12 col-lg-12 col-xl-6 faults-container">
                      {/* SYSTEM SUMMARY   */}
                      <OrgSummary
                        heading='DEALER SUMMARY'
                        summaryData={this.state.systemSummary}
                        orgID={data?.orgId}
                        SiteIcon={SiteIcon}
                        batteryIcon={Batteries}
                        LocationIcon={LocationIcon}
                        UserIcon={UserIcon}
                        siteLable={'SITES'}
                        deviceLabel={'BATTERIES'}
                        locationLabel={'LOCATIONS'}
                        userLabel={'USERS'}
                      />
                    </div>
                    {/* OPERATIONS STATUS  */}
                    <div className="col-md-12 col-lg-12 col-xl-6 warnings-container" id='operation-status-container'>
                      <FaultSummary
                        statusData={this.state.operationStatus}
                        dateFilter={this.dateFilter}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 pr-0 company-alert" id="dealear-dashboard">
                  <div className="row">

                    <div className="col-md-12 pl-0 pie-chart" id="countryChart" style={{ height: "180px" }}>
                      <div className="mapBannerDiv">
                        <div className="row h-100">
                          <div className="col-md-5 mapBannerImgDiv h-100">
                            <img
                              src={mapBannerIcon}
                              className="mapBannerImg"
                              alt="mapBanner"
                            />
                          </div>
                          <div className="col-md-6">
                            <div className='right-side'>
                            <div className="mapBannerLogoDiv">
                              <img
                                src={posiBanneLogo}
                                alt="posilinkLogo"
                                className="mapBannerDivLogo"
                              />
                            </div>
                            <label className="mapBannerDivText">
                              Welcome to Posilink, a<br></br> comprehensive
                              battery<br></br> management tool
                            </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row" id='alerts'>
                    <div className="col-12 pl-0">
                      <div className="chartTitle mt-2 mb-2" id="admin-card-title">DEALER ALERTS</div>
                      {/* dealear card start  */}
                      <div id="adminCards">
                        <ul className='location-alerts-container' >
                          {this.state.dealers && this.state.dealers?.filter((item: any, index: any) => {
                            return this.state.search.toLowerCase() === "" ? item : item.name.toLowerCase().includes(this.state.search.toLowerCase())
                          })
                            .map(
                              (item: item, indexs: any) => {
                                const { name, orderId, warningsCount, locationsCount, faultCount } = item
                                return indexs >= this.state.minIndex &&
                                  indexs < this.state.maxIndex && (
                                    <div id='admin-card' key={indexs} className="row dealer-card">
                                      <div className="col-md-12">
                                        <div className="dealer-card-body">
                                          <div className="delear-body-right">
                                            <div>
                                              <img src={require("../../images/User.png")} className="dealear-img" alt='img' />
                                            </div>
                                            <div>
                                              <div className="dealaer-no" onClick={() => {
                                                let data: any = sessionStorage.getItem('globalData');
                                                data = data && JSON.parse(data);
                                                data["dealerId"] = orderId;
                                                sessionStorage.setItem("globalData", JSON.stringify(data))
                                                this.props.setLabelName("dealer", name)
                                                this.props.setGlobalData("dealerName", name);
                                              }}><Link to="/dealerdashboard">{name?.length > 7 ? name?.substring(0, 7) + "..." : name} </Link></div>
                                              <div className="fontBlue">{locationsCount}&nbsp; COMPANIES </div>
                                            </div>
                                          </div>
                                          <div className="delear-body-left ">
                                            <div >
                                              <div className="circle red"></div>
                                              <div className="circle yellow"></div>
                                            </div>
                                            <div className="count">
                                              <div>{faultCount}</div>
                                              <div>{warningsCount}</div>
                                            </div>
                                            <div className="delaler-status">
                                              <div>FAULTS</div>
                                              <div>WARNINGS</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SessionWarning />
      </>
    )
  }
}

