import React, { useEffect, useRef, useState, FC, useLayoutEffect } from "react";
import Cleave from "cleave.js/react";
import { Link } from "react-router-dom";
import {
  Modal,
  Form,
  Button,
  Select,
  ConfigProvider,
  Radio,
  Checkbox,
  Space,
  message,
} from "antd";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from "../../service/api/common";
import LoadingSpinner from "../spinner/loading-spinner";
import SessionWarning from "../reusableComponent/sessionWarning";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import DatePicker from "react-date-picker";
import { resize, hideSidebar } from "../../settings/resize";
import TextArea from "antd/es/input/TextArea";
import Info from "../reusableComponent/info";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

type reqBody = {
  orgId: number;
  divisionId: number;
  locationId: number;
  buildingId: number;
  showOtherUsersRecords: boolean;
  pageOffset: number;
  pageLimit: number;
  sortColumn: string;
  sortDirection: string;
  timeZone: string;
};

let Body = {
  orgId: -1,
  divisionId: -1,
  locationId: -1,
  buildingId: -1,
  showOtherUsersRecords: true,
  pageOffset: 1,
  pageLimit: 10,
  sortColumn: "",
  sortDirection: "",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

interface Props {
  globalData: {
    orgId: number;
    orgName: string;
    divId: number;
    divName: string;
    locationId: number;
    locationName: string;
    buildingId: number;
    batteryId: string;
    buildingName: string;
    batteryName: string;
    vehicleId: string;
    vehicleName: string;
    serialId: string;
    serialName: string;
  };
  action: string;
  // refresh: number
}

const FirmControlPanel: FC<Props> = ({ globalData, action }) => {
  // const FirmControlPanel: FC<Props> = ({ globalData, action, refresh }) => {
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body);
  const [tableReport, setTableReport] = useState<any[]>([]);
  const [current, setCurrent] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpen1, setIsOpen1] = useState<boolean>(false);
  const [isOpen2, setIsOpen2] = useState<boolean>(false);
  const [infoStatus, setInfoStatus] = useState<boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpen1, setIsModalOpen1] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<any>(0);

  const [radio1, changeradio1] = useState<boolean>(true);
  const [radio2, changeradio2] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [divisions, setDivisions] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [sites, setSites] = useState<any[]>([]);

  const [editDivisions, setEditDivisions] = useState<any[]>([]);
  const [editLocations, setEditLocations] = useState<any[]>([]);
  const [editSites, setEditSites] = useState<any[]>([]);

  const [orgId, setOrgId] = useState<any>(-1);
  const [divId, setDivId] = useState<any>(-1);
  const [locId, setLocId] = useState<any>(-1);
  const [siteId, setSiteId] = useState<any>(-1);
  const [date, changeDate] = useState<any>("");
  const [date1, changeDate1] = useState<any>("");
  const [simtext, setSimText] = useState<any>("");
  const [simtextcopy, setSimTextCopy] = useState<any>("");
  const [simtextcopy1, setSimTextCopy1] = useState<any>("");
  const [filterByVersion, setFilterByVersion] = useState<any>("");
  const [versions, setVersions] = useState<any[]>([]);
  const [firmwareVersion, setFirmwareVersion] = useState<any>(-1);
  const [skipVersion, setSkipVersion] = useState<any>(false);
  const [firmwareDesc, setFirmwareDesc] = useState<any>("");
  const [enabled, setEnabled] = useState<any>("Y");
  const [uploadVersion, setUploadVersion] = useState<any>("");
  const [file, setFile] = useState<any>("");
  const [fileName, setFileName] = useState<any>("");
  const [initial, setInitial] = useState<boolean>(true);

  const [simError, setSimError] = useState<boolean>(false);
  const [simError1, setSimError1] = useState<boolean>(false);
  const [fileFormatError, setFileFormatError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [updateDateError, setUpdateDateError] = useState<boolean>(false);

  const [firmwareInfo, setFirmwareInfo] = useState<any>({});
  const [fileContent, setFileContent] = useState("");
  const [hasUniversalBMID, setHasUniversalBMID] = useState(false);
  const [validatedSerialNo, SetValidatedSerialNo] = useState<boolean>(false);
  const [fileFormat,setFileFormat] = useState<string>("")
  

  const addButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const ruleSpanRef = useRef<HTMLSpanElement>(null);
  const updateButtonRef = useRef<HTMLButtonElement>(null)
  const cancelUpdateButtonRef = useRef<HTMLButtonElement>(null)
  

  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();

  const [tableHeight, setTableHeight] = useState<any>(425);
  const paginationRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let timer: any;
    const debouncedHandleResize = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        resize();
        callDebounce();
      }, 500);
    };
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce();
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 105;
    let navHeight = localStorage.getItem("navHeight");
    let paginationHeight;
    if (paginationRef.current)
      paginationHeight = paginationRef.current.offsetHeight;
    let subHeight = marginAndPaddings + Number(navHeight) + paginationHeight;
    let tableHeight = window.innerHeight - subHeight;
    tableHeight < 425 ? setTableHeight(425) : setTableHeight(tableHeight);
  };

  const showAddUser = () => {
    SetValidatedSerialNo(false);
    setShow(!show);
    form.resetFields();
    setSimError(false);
    setFileFormat('')
    setFileFormatError(false);
    setSkipVersion(false);
    setOrgId("");
    setDivId("");
    setLocId("");
    setSiteId("");
    changeradio1(true);
    changeradio2(false);
    setSimText("");
    setSimTextCopy('')
    setFilterByVersion("");
    setFirmwareVersion(-1);
    setFileName("");
    setFile("");
    setFirmwareDesc("");
    setUploadVersion("");
    setSkipVersion(false);
    changeDate("");
    changeDate1("");
    setEnabled("Y");
    setDateError(false);
  };

  const onClickRadio1 = () => {
    changeradio1(true);
    changeradio2(false);
  };

  const onClickRadio2 = () => {
    changeradio1(false);
    changeradio2(true);
  };

  const onEditRadio1 = () => {
    setFirmwareInfo({ ...firmwareInfo, filterType: 0 });
  };

  const onEditRadio2 = () => {
    setFirmwareInfo({ ...firmwareInfo, filterType: 1 });
  };

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem("permissions");
    if (permission !== null) {
      if (!permission.includes("firmwareUpdateController_W"))
        window.history.back();
    }

    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;

    let data: any = sessionStorage.getItem("globalData");
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    window.scrollTo(0, 0);
    getOrganizations();
    getVersions();
    // getFirmwares();
    setTableReqBody({
      ...tableReqBody,
      orgId: org,
      divisionId: data?.divId,
      locationId: data?.locationId ? data?.locationId : -1,
      buildingId: data?.buildingId ? data?.buildingId : -1,
    });
    setInitial(false);
  }, []);

  useEffect(() => {
    // if (initial === false) {
    if (tableReqBody.orgId > 0) {
      getFirmwares();
      getCount();
    }
    // }
  }, [tableReqBody]);

  useEffect(() => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;

    if (initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

      setTableReqBody({
        ...tableReqBody,
        pageOffset: 1,
        orgId: org,
        divisionId: globalData?.divId,
        locationId: globalData?.locationId,
        buildingId: globalData?.buildingId,
      });
      setCurrent(1);
    }
  }, [
    globalData?.orgId,
    globalData?.divId,
    globalData?.locationId,
    globalData?.buildingId,
  ]);



  const getFirmwares = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    setIsShowSpinner(true);

    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.getFirmwares,
        method: "POST",
        data: tableReqBody,
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      setTableReport(response);
      setIsShowSpinner(false);
    } catch (error) {
      console.error("get Firmwares:", error);
      setIsShowSpinner(false);
    }
  };

  async function getCount() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.getCount,
        method: "POST",
        data: tableReqBody,
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });
      setCount(response);
    } catch (error) {
      console.error("getCount:", error);
    }
  }

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize);
    setPageSize(numPageSize);
    setCurrent(1);
    setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 });
  };

  const handlePagination = (pageNumber: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset: pageNumber });
    setCurrent(pageNumber);
  };

  const getOrganizations = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let payload = {
      orgId: 0,
      divisionId: 0,
      locationId: 0,
      buildingId: 0,
    };

    try {
      const response = await service({
        url: constantUrl.api.dashboard.getOrganizations,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload,
      });

      setOrganizations(response);
    } catch (error) {
      console.log("getOrganizationsError:", error);
    }
  };

  const getDivisions = async (orgid: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    var url = constantUrl.api.location.getDivisions;
    let payload = { orgId: orgid };

    if (url) {
      try {
        const response = await service({
          url: url,
          method: "POST",
          headers: { accept: "*/*", Authorization: "Bearer " + token },
          data: payload,
        });

        setDivisions(response);
      } catch (error) {
        console.log("getDivisionsError:", error);
      }
    }
  };

  const getLocations = async (orgId: any, divId: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let url = constantUrl.api.dashboard.getLocations;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: -1,
    };

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload,
      });

      setLocations(response);
    } catch (error) {
      console.error("locationList:", error);
    }
  };

  const getSites = async (orgId: any, divId: any, locId: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: -1,
    };

    let url = constantUrl.api.HeaderFilters.getSites;

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload,
      });

      setSites(response);
    } catch (error) {
      console.error("sitesList:", error);
    }
  };

  const getDivisions1 = async (orgid: number) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    var url = constantUrl.api.location.getDivisions;
    let payload = { orgId: orgid };

    if (url) {
      try {
        const response = await service({
          url: url,
          method: "POST",
          headers: { accept: "*/*", Authorization: "Bearer " + token },
          data: payload,
        });

        setEditDivisions(response);
      } catch (error) {
        console.log("getDivisionsError:", error);
      }
    }
  };

  const getLocations1 = async (orgId: any, divId: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let url = constantUrl.api.dashboard.getLocations;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: -1,
    };

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload,
      });

      setEditLocations(response);
    } catch (error) {
      console.error("locationList:", error);
    }
  };

  const getSites1 = async (orgId: any, divId: any, locId: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken;

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId,
      buildingId: -1,
    };

    let url = constantUrl.api.HeaderFilters.getSites;

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: payload,
      });

      setEditSites(response);
    } catch (error) {
      console.error("sitesList:", error);
    }
  };

  const getVersions = async () => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.getFirmwareVersions,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });

      setVersions(response);
    } catch (error) {
      console.error("get Versions:", error);
    }
  };

  const onSelectOrg = (organizationID: any) => {
    form.setFieldsValue({ division: "All", location: "All", building: "All" });
    setOrgId(organizationID);
    setDivId(-1);
    setLocId(-1);
    setSiteId(-1);
    getDivisions(organizationID);
    getLocations(organizationID, -1);
    getSites(organizationID, -1, -1);
  };

  const onUpdateOrg = (organizationID: any) => {
    setFirmwareInfo({
      ...firmwareInfo,
      orgId: organizationID,
      divisionId: -1,
      locationId: -1,
      buildingId: -1,
    });
    getDivisions1(organizationID);
    getLocations1(organizationID, -1);
    getSites1(organizationID, -1, -1);
  };

  const onSelectDivision = (divisionId: any) => {
    form.setFieldsValue({ location: "All", building: "All" });
    setDivId(divisionId);
    setLocId(-1);
    setSiteId(-1);
    getLocations(orgId, divisionId);
    getSites(orgId, divisionId, -1);
  };

  const onUpdateDivision = (divisionId: any) => {
    setFirmwareInfo({
      ...firmwareInfo,
      divisionId: divisionId,
      locationId: -1,
      buildingId: -1,
    });
    getLocations1(firmwareInfo.orgId, divisionId);
    getSites1(firmwareInfo.orgId, divisionId, -1);
  };

  const onSelectLoc = (locationId: any) => {
    form.setFieldsValue({ building: "All" });
    setLocId(locationId);
    setSiteId(-1);
    getSites(orgId, divId, locationId);
  };

  const onUpdateLoc = (locationId: any) => {
    setFirmwareInfo({
      ...firmwareInfo,
      locationId: locationId,
      buildingId: -1,
    });
    getSites(firmwareInfo.orgId, firmwareInfo.divisionId, locationId);
  };

  const onSelectSite = (buildingId: any) => {
    setSiteId(buildingId);
  };

  const onUpdateSite = (buildingId: any) => {
    setFirmwareInfo({ ...firmwareInfo, buildingId: buildingId });
  };

  const getDowFormat = async (format: string) => {
    const userInfo: any = Utility.getUserInfo();
    const token: any = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.downloadFirmwares + format,
        method: "POST",
        responseType: "blob",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: tableReqBody,
      });

      if (response) {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement("a");
        a.href = url;
        a.download = "FirmwareUpdateRules." + format;
        a.click();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (action !== "") {
      getDowFormat(action);
    }
  }, [action]);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
  };
  // reader.result
  const create_blob = (file: any) => {
    var reader = new FileReader();
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onAddFinish = async () => {
    const userInfo: any = Utility.getUserInfo();
    const token1: any = userInfo.authToken;

    // let validformat: any = true;
    // if (firmwareVersion > 0) {
    // } else {
    //   if (fileName?.split(".")[1] === "bin") {
    //     setFileFormatError(false);
    //     validformat = true;
    //   } else {
    //     setFileFormatError(true);
    //     validformat = false;
    //   }
    // }
    let status = radio1 === true ? 0 : 1;
    let simerror: any = false;

    let imgData: any = file !== "" ? file?.split(",")[1] : "";
    let validSim = false;

    let a: any = "";
    if (firmwareVersion > 0) {
      a = versions.find((bird) => bird.id === firmwareVersion);
    }

    let dateerror = false;

    if (date && date1 && date > date1) {
      setDateError(true);
      dateerror = true;
    }

    if (
      validSim === false &&
      !fileFormatError &&
      // validformat &&
      !simerror &&
      !dateerror
    ) {
      let payload = {
        orgId: orgId,
        divisionId: divId,
        locationId: locId,
        buildingId: siteId,
        filterType: status,
        filterSerialNumbers: simtext.trim(),
        filterVersion: filterByVersion,
        updateVersion:
          a === ""
            ? "(uploaded file)"
            : a?.appVersion !== ""
            ? a?.appVersion
            : "",
        updateId: firmwareVersion,
        imageFileName: fileName,
        imageData: imgData,
        imageDescription: firmwareDesc,
        imageVersionNumber: uploadVersion,
        skipVersionCheck: skipVersion === true ? "Y" : "N",
        startDate: date,
        endDate: date1,
        enabledState: enabled,
        imageType: hasUniversalBMID ? 18 : 0,
      };
      setIsShowSpinner(true);
      try {
        const response = await service({
          url: constantUrl.api.formwareControlPanel.addFirmware,
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + token1,
          },
          data: payload,
        });

        if (response.status === "SUCCESS") {
          SetValidatedSerialNo(false);
          setShow(false);
          setIsOpen(true);
          form.resetFields();
          setSimError(false);
          setFileFormatError(false);
          setSkipVersion(false);
          setOrgId("");
          setDivId("");
          setLocId("");
          setSiteId("");
          changeradio1(true);
          changeradio2(false);
          setSimText("");
          setFilterByVersion("");
          setFirmwareVersion(-1);
          setFileName("");
          setFile("");
          setFirmwareDesc("");
          setUploadVersion("");
          setSkipVersion(false);
          changeDate("");
          changeDate1("");
          setEnabled("Y");
          setDateError(false);
          setFileFormat('')
          setSimTextCopy('')
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setIsShowSpinner(false);
      }
    }
  };

  const onChangeSimNumbers = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSimText(e.target.value);
    setSimTextCopy(e.target.value);
  };

  const onUpdateSimNo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFirmwareInfo({
      ...firmwareInfo,
      filterSerialNumbers: event.target.value,
    });
    setSimTextCopy1(event.target.value);
  };

  const onLeaveTextAreaAdd = async (event: any) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null;
    // Check if the focus is moving to the Add button
  
    if (relatedTarget &&
      (addButtonRef.current?.contains(relatedTarget) ||
      cancelButtonRef.current?.contains(relatedTarget) ||
      ruleSpanRef.current?.contains(relatedTarget))
     ) {
      return;
    }

      let simNumbers = [];
      if (simtextcopy.trim() === "") {
        simNumbers = []; // Set simNumbers to an empty array if simtextcopy is [""]
        return;
      } else {
        let sim = simtextcopy.trim().replace(/\s+/g, " ");
        simNumbers = sim.split(" ");
      }

      // // Validate the file selection
      // if (!fileFormat) {
      //   setInfoMsg('Please choose a firmware file.')
      //   setInfoStatus(true) 
      //   return;
      // }
      // if (fileFormat !=='bin') {
      //   setInfoMsg('Firmware filename must be a .bin file.')
      //   setInfoStatus(true)
      //   return;
      // }

    const reqPayload = {
      serialNumbers: simNumbers,
      imageType: fileFormat === 'bin' ? (hasUniversalBMID ? 18 : 0) :18,
    };

    const userInfo: any = Utility.getUserInfo();
    const token: any = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.addNewFirmware,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: reqPayload,
      });
      const { data, status } = response;
      const {
        matchedSerialNumbers,
        unmatchedCount,
      } = data;

      let matchesSerialNo: string = matchedSerialNumbers.join(" ");
      form.setFieldsValue({ SIMnumbers: matchesSerialNo });
      setSimText(matchesSerialNo);
      setSimTextCopy("");

      if (status === "SUCCESS" && unmatchedCount > 0) {
        setInfoMsg(
          `${unmatchedCount} devices were removed from the selection because of invalid device type.`
        );
        setInfoStatus(true);
      }
      SetValidatedSerialNo(true);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const onLeaveTextAreaUpdate = async (event:any) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null;
    // Check if the focus is moving to the Add button
    if (relatedTarget && (updateButtonRef.current?.contains(relatedTarget) || cancelUpdateButtonRef.current?.contains(relatedTarget))) {
      return;
    }

    let simNumbers = [];
    if (simtextcopy1 === "") {
      simNumbers = []; // Set simNumbers to an empty array if simtextcopy is [""]
      return;
    } else {
      let sim = simtextcopy1.replace(/\s+/g, " ");
      simNumbers = sim.split(" ");
    }

    const reqPayload = {
      serialNumbers: simNumbers,
      imageType:firmwareInfo?.imageType,
    };
    const userInfo: any = Utility.getUserInfo();
    const token: any = userInfo.authToken;
    try {
      const response = await service({
        url: constantUrl.api.formwareControlPanel.addNewFirmware,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: reqPayload,
      });
      const { data, status } = response;
      const {
        matchedSerialNumbers,
        unmatchedCount,
      } = data;

      let matchesSerialNo: string = matchedSerialNumbers.join(" ");
      setFirmwareInfo({
        ...firmwareInfo,
        filterSerialNumbers: matchesSerialNo,
      });
      // firmwareInfo?.filterSerialNumbers
      setSimTextCopy1(matchesSerialNo);

      if (status === "SUCCESS" && unmatchedCount > 0) {
        setInfoMsg(
          `${unmatchedCount} devices were removed from the selection because of invalid device type.`
        );
        setInfoStatus(true);
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  async function onUpdateFinish() {
    const userInfo: any = Utility.getUserInfo();
    const token1: any = userInfo.authToken;
    let dateerror: any = false;
    if (firmwareInfo?.startDate > firmwareInfo?.endDate) {
      setUpdateDateError(true);
      dateerror = true;
    }

    let a: any = "";
    let simerror1: any = false;

    if (firmwareInfo?.updateId > 0) {
      a = versions.find((bird) => bird.id === firmwareInfo?.updateId);
    }

    if (!dateerror) {
      let payload = {
        orgId: firmwareInfo?.orgId,
        divisionId: firmwareInfo?.divisionId,
        locationId: firmwareInfo?.locationId,
        buildingId: firmwareInfo?.buildingId,
        filterType: firmwareInfo?.filterType,
        filterSerialNumbers: firmwareInfo?.filterSerialNumbers.trim(),
        filterVersion: firmwareInfo?.filterVersion,
        updateVersion:
          a === "" ? "(upload)" : a?.appVersion !== "" ? a?.appVersion : "",
        updateId: firmwareInfo?.updateId,
        imageFileName: firmwareInfo?.imageFileName,
        imageData: firmwareInfo?.imageData,
        imageDescription: firmwareInfo?.imageDescription,
        imageVersionNumber: firmwareInfo?.imageVersionNumber,
        skipVersionCheck: firmwareInfo?.skipVersionCheck,
        startDate: firmwareInfo?.startDate,
        endDate: firmwareInfo?.endDate,
        enabledState: firmwareInfo?.enabledState,
        imageType: firmwareInfo?.imageType,
      };
      setIsShowSpinner(true);
      try {
        const response = await service({
          url:
            constantUrl.api.formwareControlPanel.updateFirmware +
            firmwareInfo?.recordId +
            "/update",
          method: "PUT",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: "Bearer " + token1,
          },
          data: payload,
        });

        if (response.status == "SUCCESS") {
          setIsModalOpen1(true);
          setUpdateDateError(false);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setIsShowSpinner(false);
      }
    }
  }

  const onChangeUploadVersion = (e: any) => {
    setUploadVersion(e.target.value);
  };

  const onChangeFilterVersion = (e: any) => {
    setFilterByVersion(e.target.value);
  };

  const onSelectFirmwareVersion = (value: any) => {
    setFirmwareVersion(value);
    form.setFields([
      {
        name: "UploadFirmware",
        errors: [],
      },
      {
        name: "UploadVersion",
        errors: [],
      },
    ]);
  };

  const onSelectEnabled = (value: any) => {
    setEnabled(value);
  };

  const onChangeSkipVersion = () => {
    setSkipVersion(!skipVersion);
  };

  const onChangeFirmwareDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFirmwareDesc(e.target.value);
  };

  const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
        console.error('No file selected');
        return;
    }
    const file = files[0];
    var name: any = file.name;
    if (file) {
       setFileFormat(name?.split(".")[1])
        if(name?.split(".")[1] ==='bin'){
          form.setFields([
            {
              name: 'UploadFirmware',
              errors: [], // Clear error messages
            },
          ]);
        }
 
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const content  = e.target?.result as string;
           setFileContent(content);
          // Check if the word "UniversalBMID" exists in the content
         if(content){
          if (content.includes('UniversalBMID')) {
               setHasUniversalBMID(true);
              } else {
               setHasUniversalBMID(false);
              }
         }
       
        };
        reader.readAsText(file);
      }
    setFileName(name);
    create_blob(file);
  };


  const deleteFirmware = async (recordId: any) => {
    const userInfo: any = Utility.getUserInfo();
    const token: any = userInfo.authToken;

    try {
      const response = await service({
        url:
          constantUrl.api.formwareControlPanel.deleteFirmware +
          recordId +
          "/delete",
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      if (response.status === "SUCCESS") {
        setIsOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAlertOpen = (recordId: any) => {
    setIsOpen2(true);
    setRecordId(recordId);
  };

  const getFirmwareInfo = async (recordId: any) => {
    const userInfo: any = Utility.getUserInfo();
    const token: any = userInfo.authToken;

    try {
      const response = await service({
        url:
          constantUrl.api.formwareControlPanel.getFirmwareBMIDsByQueueId +
          recordId,
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      setFirmwareInfo(response?.data);
      setSimTextCopy1(response?.data?.filterSerialNumbers);
      setIsModalOpen(true);
      getDivisions1(response?.data?.orgId);
      getLocations1(response?.data?.orgId, response?.data?.divisionId);
      getSites1(
        response?.data?.orgId,
        response?.data?.divisionId,
        response?.data?.locationId
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onInfoClick = () => {
    setInfoStatus(!infoStatus);
    setInfoMsg("");
  };

  const validateFile = async () => {
    const file = (document.getElementById("actual-btn") as HTMLInputElement).files?.[0];
    if (!file) {
      throw new Error('Please Select Firmware File');
    }
    if (!file.name.endsWith('.bin')) {
      throw new Error('Firmware filename must be a .bin file');
    }
  };

  const validateBin = async()=>{
    const file = (document.getElementById("actual-btn") as HTMLInputElement).files?.[0];
    if (file && !file.name.endsWith('.bin')) {
      throw new Error('Firmware filename must be a .bin file');
    }
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>

      <div className="container-fluid px-0 mb-3">
        <div className=" row nav-filter align-items-center">
          <div className="col-lg-3 col-md-4 col-sm-12  tab_content-battery">
            <span
              ref={ruleSpanRef}
              tabIndex={0}
              onClick={showAddUser}
              className="d-flex align-items-center  "
            >
              {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}{" "}
              <span className="pl-2"> Add a Firmware Update Rule</span>{" "}
            </span>
          </div>
        </div>

        {show ? (
          <>
            <div
              className={show ? "show-user  add-user" : "hide-user add-user"}
            >
              <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
                <Form
                  layout="vertical"
                  form={form}
                  validateMessages={validateMessages}
                  onFinish={onAddFinish}
                >
                  {/* Firmware Update Information */}
                  <div className="border border-secondary p-2 rounded mb-3">
                    <div className="ml-auto mr-auto firmheader">
                      Firmware Update Information
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Item
                          style={{ marginBottom: "5px", marginTop: "5px" }}
                          label="Firmware Version"
                          // name="FirmwareVersion"
                        >
                          <Select
                            placeholder="Select Firmware Version"
                            onSelect={(value: any) =>
                              onSelectFirmwareVersion(value)
                            }
                            value={firmwareVersion}
                            dropdownStyle={{ zIndex: 900 }}
                          >
                            <Select.Option value={-1}>(upload)</Select.Option>
                            {versions &&
                              versions?.map((item: any, index: any) => {
                                return (
                                  <Select.Option key={index} value={item.id}>
                                    {item.appVersion}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        {firmwareVersion > 0 ? (
                          <>
                            <Form.Item
                              style={{ marginBottom: "5px" }}
                              label="Upload Firmware"
                              name="UploadFirmware"
                             
                              rules={[{ required:false, validator: () => validateBin() }]}

                            >
                              <input
                                type="file"
                                id="actual-btn"
                                className="simple_file_upload"
                                style={{ display: "inline" }}
                                onChange={fileChange}
                                accept =".bin"
                              />
                              <div style={{ display: "inline" }}>
                                <span>
                                  <b>Device Type&nbsp;&nbsp; </b>
                                </span>{" "}
                                <label>
                                  {fileFormat==='bin'
                                    ? hasUniversalBMID
                                      ? "UBMID"
                                      : "BMID3"
                                    : ""}
                                </label>
                              </div>
                            </Form.Item>

                            <div style={{ color: "blue", marginBottom: 2 }}>
                              Verify that you are uploading the correct file as
                              the file contents cannot be changed later.
                            </div>
                            {/* {fileFormatError && (
                              <div style={{ color: "red" }}>
                                Firmware filename must be a .bin file.
                              </div>
                            )} */}
                          </>
                        ) : (
                          <>
                            <Form.Item
                              style={{ marginBottom: "5px" }}
                              label="Upload Firmware"
                              name="UploadFirmware"
                              rules={[{ required: true, validator: () => validateFile() }]}
                            >
                              <input
                                type="file"
                                id="actual-btn"
                                className="simple_file_upload"
                                style={{ display: "inline" }}
                                onChange={fileChange}
                                 accept =".bin"
                              />
                              <div style={{ display: "inline" }}>
                                <span>
                                  <b>Device Type&nbsp;&nbsp; </b>
                                </span>{" "}
                                <label>
                                  {fileFormat==='bin'
                                    ? hasUniversalBMID
                                      ? "UBMID"
                                      : "BMID3"
                                    : ""}
                                </label>
                              </div>
                            </Form.Item>

                            <div style={{ color: "blue", marginBottom: 2 }}>
                              Verify that you are uploading the correct file as
                              the file contents cannot be changed later.
                            </div>
                            {/* {fileFormatError && (
                              <div style={{ color: "red" }}>
                                Firmware filename must be a .bin file.
                              </div>
                            )} */}
                          </>
                        )}
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="Start of Update"
                          name="StartofUpdate"
                        >
                          <DatePicker onChange={changeDate} value={date} />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: "5px", display: 'inline-block', width: 'calc(40% - 8px)' }}>
                          <div>
                            <span>Skip Version Check</span>
                            <Checkbox
                              value={skipVersion}
                              onChange={onChangeSkipVersion}
                              className="px-3"
                            ></Checkbox>
                          </div>
                        </Form.Item>
                        <Form.Item
                          style={{ display: 'inline-block', width: 'calc(40% - 8px)', marginLeft: '16px' }}
                          label="Filter by Version"
                          name="filterByVersion"
                        >
                          <Cleave
                            style={{
                              height: 35,
                              resize: "none",
                              borderRadius: 5,
                            }}
                            options={{
                              delimiter: ".",
                              blocks: [2, 2, 2, 2],
                              prefix: "",
                              numericOnly: true,
                            }}
                            onChange={onChangeFilterVersion}
                            value={filterByVersion}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Form.Item
                          style={{ marginBottom: "5px", marginTop: "5px" }}
                          label="Firmware Description"
                          name="FirmwareDescription"
                        >
                          <TextArea
                            maxLength={200}
                            style={{ height: 30, resize: "none" }}
                            onChange={onChangeFirmwareDesc}
                            placeholder=""
                          />
                        </Form.Item>
                        {firmwareVersion > 0 ? (
                          <>
                            <Form.Item
                              style={{ marginBottom: "5px" }}
                              label="Upload Version"
                              name="UploadVersion"
                            >
                              <Cleave
                                maxLength={200}
                                style={{
                                  height: 35,
                                  resize: "none",
                                  borderRadius: 5,
                                }}
                                options={{
                                  delimiter: ".",
                                  blocks: [2, 2, 2, 2],
                                  prefix: "",
                                  numericOnly: true,
                                }}
                                onChange={onChangeUploadVersion}
                              />
                            </Form.Item>
                            <div style={{ color: "blue", marginBottom: 2 }}>
                              Required if you are manually uploading a firmware
                              file
                            </div>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              style={{ marginBottom: "5px" }}
                              label="Upload Version"
                              name="UploadVersion"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Upload Version",
                                },
                              ]}
                            >
                              <Cleave
                                maxLength={200}
                                style={{
                                  height: 35,
                                  resize: "none",
                                  borderRadius: 5,
                                }}
                                options={{
                                  delimiter: ".",
                                  blocks: [2, 2, 2, 2],
                                  prefix: "",
                                  numericOnly: true,
                                }}
                                onChange={onChangeUploadVersion}
                              />
                            </Form.Item>
                            <div style={{ color: "blue", marginBottom: 2 }}>
                              Required if you are manually uploading a firmware
                              file
                            </div>
                          </>
                        )}
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="End of Update"
                          name="EndofUpdate"
                        >
                          <DatePicker
                            onChange={(e) => {
                              changeDate1(e);
                              setTimeout(() => {
                                setDateError(false);
                              }, 1);
                            }}
                            value={date1}
                          />
                        </Form.Item>
                        {dateError && (
                          <div style={{ color: "red" }}>
                            The Date must be greater than or equal to From Date.
                          </div>
                        )}
                        <Form.Item style={{ marginBottom: "5px" }}>
                          <div>
                            <span>Update Enabled</span>
                            <Space wrap className="px-4">
                              <Select
                                dropdownStyle={{ zIndex: 900 }}
                                defaultValue="Enabled"
                                style={{ width: 120 }}
                                onChange={onSelectEnabled}
                                options={[
                                  { value: "Y", label: "Enabled" },
                                  { value: "N", label: "Disabled" },
                                ]}
                              />
                            </Space>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  {/* Organization Filters */}
                  <div className="border border-secondary p-2 rounded mb-3">
                    <div className="ml-auto mr-auto firmheader">
                      Organization Filters
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Radio
                          className="firmradio"
                          checked={radio1}
                          onClick={onClickRadio1}
                        >
                          Filter by Organization
                        </Radio>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="Organization"
                          name="organisation"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Organization",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Organization"
                            onSelect={(value: any) => onSelectOrg(value)}
                            dropdownStyle={{ zIndex: 900 }}
                            showSearch
                            filterOption={(input, option) =>
                              option?.children
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                            }
                          >
                            {organizations &&
                              organizations?.map((item: any, index: any) => {
                                return (
                                  <Select.Option key={index} value={item.orgId}>
                                    {item.orgName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="Division"
                          name="division"
                        >
                          <Select
                            dropdownStyle={{ zIndex: 900 }}
                            placeholder="Select Division"
                            onSelect={(value: any) => onSelectDivision(value)}
                            showSearch
                            filterOption={(input, option) =>
                              option?.children
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                            }
                          >
                            {divisions &&
                              divisions?.map((item: any, index: any) => {
                                return (
                                  <Select.Option
                                    key={index}
                                    value={item.divisionId}
                                  >
                                    {item.divisionName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="Location"
                          name="location"
                        >
                          <Select
                            placeholder="Select Location"
                            onSelect={(value: any) => onSelectLoc(value)}
                            dropdownStyle={{ zIndex: 900 }}
                            showSearch
                            filterOption={(input, option) =>
                              option?.children
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                            }
                          >
                            {locations &&
                              locations?.map((item: any, index: any) => {
                                return (
                                  <Select.Option key={index} value={item.locId}>
                                    {item.locationName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="Building"
                          name="building"
                        >
                          <Select
                            placeholder="Select Site"
                            onSelect={(value: any) => onSelectSite(value)}
                            dropdownStyle={{ zIndex: 900 }}
                            showSearch
                            filterOption={(input, option) =>
                              option?.children
                                ?.toString()
                                .toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                            }
                          >
                            {sites &&
                              sites?.map((item: any, index: any) => {
                                return (
                                  <Select.Option key={index} value={item.id}>
                                    {item.buildingName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <Radio
                          className="firmradio"
                          checked={radio2}
                          onClick={onClickRadio2}
                        >
                          Filter by SIM #
                        </Radio>
                        <Form.Item
                          style={{ marginBottom: "5px" }}
                          label="SIM # List (Up to 200 SIM numbers can be specified, separated by carriage returns)"
                          name="SIMnumbers"
                        >
                          <TextArea
                            showCount
                            maxLength={4200}
                            style={{ height: 165 }}
                            onChange={onChangeSimNumbers}
                            onBlur={onLeaveTextAreaAdd}
                            value={simtext}
                          />
                          {/* {
                                                simError &&
                                                <div style={{ marginTop: 12, color: "red" }}>One or more SIM Numbers/MAC Addresses (20 Chars) must be specified with Filter by SIM</div>
                                            } */}
                        </Form.Item>
                       
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div>
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          ref={cancelButtonRef}
                          onClick={showAddUser}
                          style={{
                            background: "#004f9f",
                            borderBlockColor: "#004f9f",
                            color: "white",
                          }}
                        >
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="pl-3">
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          ref={addButtonRef}
                          style={{
                            background: "#004f9f",
                            borderBlockColor: "#004f9f",
                            color: "white",
                          }}
                          htmlType="submit"
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </ConfigProvider>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className="ml-auto mr-auto">Firmware Update Rules</div>
            <div className="show-entries">
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div
              style={{ height: tableHeight + "px" }}
              className="battery-history overflow-auto scrollbar"
            >
              <table className="billable width100">
                <thead className="tableHeader">
                  <tr className="billable-head">
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>Enabled</th>
                    <th>Created by</th>
                    <th>Filter Type</th>
                    <th>Organization</th>
                    <th>Division</th>
                    <th>Location</th>
                    <th>Building</th>
                    <th>
                      <span>Battery Rx SIM(s) #</span>
                    </th>
                    <th><span> Device Type</span></th>
                    <th>
                      <span>Version Filter</span>
                    </th>
                    <th>
                      <span>Firmware Version</span>
                    </th>
                    <th>
                      <span>Firmware Description</span>
                    </th>
                    <th>
                      <span>Upload Version</span>
                    </th>
                    <th>
                      <span>Skip Version</span>
                    </th>
                    <th>
                      <span>Start Date</span>
                    </th>
                    <th>
                      <span>End Date</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((report, index) => {
                    return (
                      <tr key={`battery_rx_units_${index}`}>
                        <td>
                          {
                            <span
                              onClick={() => {
                                getFirmwareInfo(report?.recordId);
                                setUpdateDateError(false);
                              }}
                            >
                              <a href="#" onClick={(e) => e.preventDefault()}>
                                Update
                              </a>
                            </span>
                          }
                        </td>
                        <td>
                          {
                            <span
                              onClick={() => deleteAlertOpen(report?.recordId)}
                            >
                              <a href="#" onClick={(e) => e.preventDefault()}>
                                Delete
                              </a>
                            </span>
                          }
                        </td>
                        <td>
                          <Link
                            to="/firmware-update-report"
                            state={{ id: report?.recordId }}
                          >
                            Report
                          </Link>
                        </td>
                        <td>
                          <a href={report?.downloadUrl} download>
                            Download
                          </a>
                        </td>
                        <td>{report?.enabledStateDesc}</td>
                        <td>{report?.userName}</td>
                        <td>
                          {report?.filterTypeDesc == 0
                            ? "Organization"
                            : report?.filterTypeDesc == 1
                            ? "SIM Number"
                            : report?.filterTypeDesc}
                        </td>
                        <td>{report?.orgName}</td>
                        <td>{report?.divisionName}</td>
                        <td>{report?.locationName}</td>
                        <td>{report?.buildingName}</td>
                        <td>{report?.filterSerialNumbers}</td>
                        <td>{report?.deviceType}</td>
                        <td>{report?.filterVersion}</td>
                        <td>{report?.updateVersion}</td>
                        <td>{report?.imageDescription}</td>
                        <td>{report?.imageVersionNumber}</td>
                        <td>{report?.skipVersionCheckDesc}</td>
                        <td>{report?.formatStartDate}</td>
                        <td>{report?.formatEndDate}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          open={isOpen}
          closable={false}
          centered
          title={"New Firmware Rule added successfully"}
          footer={[
            <Button
              style={{ background: "#004f9f", color: "white" }}
              onClick={() => {
                setIsOpen(false);
                getFirmwares();
                getCount();
              }}
            >
              OK
            </Button>,
          ]}
        ></Modal>
      </ConfigProvider>

      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          open={isOpen1}
          closable={false}
          centered
          title={"Firmware Rule deleted successfully"}
          footer={[
            <Button
              style={{ background: "#004f9f", color: "white" }}
              onClick={() => {
                setIsOpen1(false);
                getFirmwares();
                getCount();
              }}
            >
              OK
            </Button>,
          ]}
        ></Modal>
      </ConfigProvider>

      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          open={isOpen2}
          closable={false}
          centered
          title={"Are you sure you want to delete this Firmware Update Rule?"}
          footer={[
            <Button
              style={{ background: "#004f9f", color: "white" }}
              onClick={() => {
                setIsOpen2(false);
                deleteFirmware(recordId);
              }}
            >
              YES
            </Button>,
            <Button onClick={() => setIsOpen2(false)}>NO</Button>,
          ]}
        ></Modal>
      </ConfigProvider>

      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          open={isModalOpen1}
          closable={false}
          centered
          title={"Firmware Rule updated successfully"}
          footer={[
            <Button
              style={{ background: "#004f9f", color: "white" }}
              onClick={() => {
                setIsModalOpen1(false);
                setIsModalOpen(false);
                getFirmwares();
                getCount();
              }}
            >
              OK
            </Button>,
          ]}
        ></Modal>
      </ConfigProvider>

      <Modal
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        title="Modify Firmware Update Rule"
        centered
        open={isModalOpen}
        width={850}
      >
        <Form
          layout="vertical"
          form={updateForm}
          validateMessages={validateMessages}
          onFinish={onUpdateFinish}
        >
          {/* Firmware Update Information */}
          <div className="border border-secondary p-2 rounded mb-3">
            <div className="ml-auto mr-auto firmheader">
              Firmware Update Information
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Item
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                  label="Firmware Version"
                >
                  <Select
                    placeholder="Select Firmware Version"
                    value={firmwareInfo.updateId}
                    onSelect={(value: any) =>
                      setFirmwareInfo({ ...firmwareInfo, updateId: value })
                    }
                  >
                    <Select.Option value={-1}>(upload)</Select.Option>
                    {versions &&
                      versions?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item.appVersion}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Upload Firmware"
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      display: "inline",
                    }}
                  >
                    {firmwareInfo.imageFileName}
                  </div>
                  <div style={{ display: "inline" }} className="ml-3">
                    <span>
                      <b>Device Type&nbsp;&nbsp; </b>
                    </span>
                    <label>
                      {firmwareInfo?.imageType === 18
                        ? "UBMID"
                        : firmwareInfo?.imageType === 0
                        ? "BMID3"
                        : ""}
                    </label>
                  </div>
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Start of Update"
                >
                  <DatePicker
                    onChange={(e: any) => {
                      setFirmwareInfo({ ...firmwareInfo, startDate: e });
                    }}
                    value={firmwareInfo?.startDate}
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: "5px", display: 'inline-block', width: 'calc(50% - 8px)' }}>
                  <div>
                    <span>Skip Version Check</span>
                    <Checkbox
                      checked={
                        firmwareInfo.skipVersionCheck == "Y" ? true : false
                      }
                      onChange={() => {
                        setFirmwareInfo({
                          ...firmwareInfo,
                          skipVersionCheck:
                            firmwareInfo.skipVersionCheck == "Y" ? "N" : "Y",
                        });
                      }}
                      className="px-3"
                    ></Checkbox>
                  </div>
                </Form.Item>
                <Form.Item
                  style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                  label="Filter by Version"
                >
                  <Cleave
                    style={{ height: 35, resize: "none", borderRadius: 5 }}
                    options={{
                      delimiter: ".",
                      blocks: [2, 2, 2, 2],
                      numericOnly: true,
                      prefix: "",
                    }}
                    onChange={(e: any) => {
                      setFirmwareInfo({
                        ...firmwareInfo,
                        filterVersion: e.target.value,
                      });
                    }}
                    value={firmwareInfo?.filterVersion}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                  label="Firmware Description"
                >
                  <TextArea
                    maxLength={200}
                    style={{ height: 30, resize: "none" }}
                    onChange={(e: any) => {
                      setFirmwareInfo({
                        ...firmwareInfo,
                        imageDescription: e.target.value,
                      });
                    }}
                    value={firmwareInfo.imageDescription}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="Upload Version"
                >
                  <div style={{ color: "blue", marginBottom: 2 }}>
                    Required if you are manually uploading a firmware file
                  </div>
                  <Cleave
                    maxLength={200}
                    style={{ height: 35, resize: "none", borderRadius: 5 }}
                    options={{
                      delimiter: ".",
                      blocks: [2, 2, 2, 2],
                      prefix: "",
                      numericOnly: true,
                    }}
                    onChange={(e: any) => {
                      setFirmwareInfo({
                        ...firmwareInfo,
                        imageVersionNumber: e.target.value,
                      });
                    }}
                    value={firmwareInfo.imageVersionNumber}
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="End of Update"
                >
                  <DatePicker
                    onChange={(e: any) => {
                      setFirmwareInfo({ ...firmwareInfo, endDate: e });
                      setUpdateDateError(false);
                    }}
                    value={firmwareInfo.endDate}
                  />
                </Form.Item>
                {updateDateError && (
                  <div style={{ color: "red" }}>
                    The Date must be greater than or equal to From Date.
                  </div>
                )}
                <Form.Item style={{ marginBottom: "5px" }}>
                  <div>
                    <span>Update Enabled</span>
                    <Space wrap className="px-4">
                      <Select
                        value={firmwareInfo.enabledState}
                        style={{ width: 120 }}
                        onChange={(val: any) => {
                          setFirmwareInfo({
                            ...firmwareInfo,
                            enabledState: val,
                          });
                        }}
                        options={[
                          { value: "Y", label: "Enabled" },
                          { value: "N", label: "Disabled" },
                        ]}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          {/* Organization Filters */}
          <div className="border border-secondary p-2 rounded mb-3">
            <div className="ml-auto mr-auto firmheader">
              Organization Filters
            </div>
            <div className="row">
              <div className="col-md-6">
                <Radio
                  className="firmradio"
                  checked={firmwareInfo.filterType == 0 ? true : false}
                  onClick={onEditRadio1}
                >
                  Filter by Organization
                </Radio>
                <Form.Item style={{ marginBottom: "5px" }} label="Organization">
                  <Select
                    placeholder="Select Organization"
                    value={firmwareInfo?.orgId}
                    onSelect={(value: any) => onUpdateOrg(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                  >
                    {organizations &&
                      organizations?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.orgId}>
                            {item.orgName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: "5px" }} label="Division">
                  <Select
                    placeholder="Select Division"
                    value={firmwareInfo?.divisionId}
                    onSelect={(value: any) => onUpdateDivision(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                  >
                    <Select.Option value={-1}>ALL</Select.Option>
                    {editDivisions &&
                      editDivisions?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.divisionId}>
                            {item.divisionName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: "5px" }} label="Location">
                  <Select
                    placeholder="Select Location"
                    value={firmwareInfo?.locationId}
                    onSelect={(value: any) => onUpdateLoc(value)}
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                  >
                    <Select.Option value={-1}>ALL</Select.Option>
                    {editLocations &&
                      editLocations?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.locId}>
                            {item.locationName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: "5px" }} label="Building">
                  <Select
                    placeholder="Select Site"
                    value={firmwareInfo?.buildingId}
                    onSelect={(value: any) => onUpdateSite(value)}
                    dropdownStyle={{ zIndex: 900 }}
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        ?.toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                  >
                    <Select.Option value={-1}>ALL</Select.Option>
                    {editSites &&
                      editSites?.map((item: any, index: any) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item.buildingName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Radio
                  className="firmradio"
                  checked={firmwareInfo.filterType === 1 ? true : false}
                  onClick={onEditRadio2}
                >
                  Filter by SIM #
                </Radio>
                <Form.Item
                  style={{ marginBottom: "5px" }}
                  label="SIM # List (Up to 200 SIM numbers can be specified, separated by carriage returns)"
                >
                  <TextArea
                    showCount
                    maxLength={4200}
                    style={{ height: 165 }}
                    onChange={onUpdateSimNo}
                    onBlur={onLeaveTextAreaUpdate}
                    value={firmwareInfo?.filterSerialNumbers}
                  />
                  {/* {
                                    simError1 &&
                                    <div style={{ marginTop: 12, color: "red" }}>One or more SIM Numbers/MAC Addresses (20 Chars) must be specified with Filter by SIM</div>
                                } */}
                </Form.Item>
              
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div>
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button
                  ref={cancelUpdateButtonRef}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                  style={{
                    background: "#004f9f",
                    borderBlockColor: "#004f9f",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
            <div className="pl-3">
              <Form.Item style={{ marginBottom: "0px" }}>
                <Button
                  ref={updateButtonRef}
                  style={{
                    background: "#004f9f",
                    borderBlockColor: "#004f9f",
                    color: "white",
                  }}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
      <Info
        status={infoStatus}
        title={infoMsg}
        infoOnClick={onInfoClick}
        btnText={"OK"}
      />
      <SessionWarning />
    </>
  );
};

export default FirmControlPanel;

