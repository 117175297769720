import React, { useRef, useState, useEffect, FC, useLayoutEffect } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../spinner/loading-spinner'
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/dateNewFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, subDays, parseISO } from 'date-fns';
import SessionWarning from '../reusableComponent/sessionWarning';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { resize, hideSidebar } from '../../settings/resize';
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  batteryRxSim: string,
  serialNumber: string,
  pageOffset: number,
  pageLimit: number,
  sortColumns: string,
  sortDirection: string,
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: 21,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  batteryRxSim: "",
  serialNumber: "",
  pageOffset: 1,
  pageLimit: 10,
  sortColumns: "",
  sortDirection: "",
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh: number
}

const DailyBatteryPerformance: FC<Props> = ({ globalData, action }) => {
  // const DailyBatteryPerformance: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [selector, setSelector] = useState(false);
  const [sort, setSort] = useState<any>({
    batteryId: false, vehicleId: false, batteryRxSim: false, log: false, date: false, posiChargeAhrs: false, dischAhrs: false,
    dischKwh: false, posifastchgHours: false, posifullchgHours: false, posiEqHours: false, keyonHours: false, keyoffHours: false,
    runHours: false, fullchrcomp: false, eqComp: false, minBattSoc: false, maxBattSoc: false, maxBattTemp: false,
    mixBattTempDate: false, mixBattTemp: false, avgBattTemp: false, dateMinBatVolt: false, minBattVoltAmps: false, dateMaxDischAmps: false,
    maxDischAmpsVolts: false, lowWaterToday: false, faultCode1: false, faultCode2: false, faultCode3: false,
    nonPosiKwh: false, posifastChargEvents: false, nonPosifastChargEvents: false, nonPosifastCharghours: false, nonPosiEqHours: false,
    nonPosiAhrs: false, dateMaxImbalVolt: false, maxPackImbalVolt: false, org: false, div: false, loc: false, site: false, dateUpdated: false, imageType: false
  })
  const [column, setColumn] = useState<any[]>([])
  const [selectorLabel, setSelectorLabel] = useState<any[]>([])
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [minIndex, setMindex] = useState<number>(0)
  const [maxIndex, setMaxindex] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [countReq, setCountReq] = useState<reqBody>(Body)
  const [count, setCount] = useState<number>(0)
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [emptyError, setemptyError] = useState<string>("");
  const [alert, setAlert] = useState<boolean>(false);
  const selectorRef = useRef<HTMLDivElement>(null);
  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [tableHeight, setTableHeight] = useState<any>(445)
  const filtersRef = useRef<HTMLDivElement>(null)
  const paginationRef = useRef<HTMLDivElement>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const location = useLocation();
  const propsData = location.state;

  const dateValue = [
    { value: 'Last 7 Days', label: 'Last 7 Days' },
    { value: 'Last 14 Days', label: 'Last 14 Days' },
    { value: 'Last 30 Days', label: 'Last 30 Days' },
    { value: 'Last 60 Days', label: 'Last 60 Days' },
    { value: 'Last 90 Days', label: 'Last 90 Days' },
    { value: 'Custom Date Range', label: 'Custom Date Range' }]

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
        callDebounce()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useLayoutEffect(() => {
    resize();
    hideSidebar();
    callDebounce()
  }, []);

  const callDebounce = () => {
    //if(window.innerHeight<=568) return
    let marginAndPaddings: any = 80
    let navHeight = localStorage.getItem('navHeight')
    let filtersHeight
    let paginationHeight
    if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
    if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
    let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
    let tableHeight = window.innerHeight - subHeight
    tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
  };

  const [searchObject, setSearchObject] = useState<any>({
    batteryId: '',
    vehicleId: '',
    serialNumber: ''
  })

  const DropList = [
    { label: "Battery ID" },
    { label: "Vehicle ID" },
    { label: "Battery Rx SIM" }

  ];

  const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
  const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
  const [inputvalue, setInput] = useState<any>("")

  const onChangedropdown = (value: string) => {
    setemptyError("")
    setDropDownValue(value);
    setPlaceholder(`Search ${value}`)
    setInput("");
    const data = {
      batteryId: '',
      vehicleId: '',
      serialNumber: ''
    }
    setSearchObject(data)
    if (inputvalue) {
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1 })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1 })
      setCountReq({ ...countReq, ...data, pageOffset: 1 })
    }

  }

  const onChangePayload = (value: any) => {
    let obj = { ...searchObject };
    if (dropdownvalue === "Battery ID") {
      obj.batteryId = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Battery Rx SIM") {
      obj.serialNumber = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    } else if (dropdownvalue === "Vehicle ID") {
      obj.vehicleId = value.trim();
      setSearchObject({ ...obj })
      setInput(value);
      !value && callCountAndReport({ ...obj, pageOffset: 1 })
      setCurrent(1)
    }
    setemptyError("")
  }

  const callCountAndReport = (obj: any) => {
    setTableReqBody({ ...tableReqBody, ...obj })
    setExportReqBody({ ...exportReqBody, ...obj })
    setCountReq({ ...countReq, ...obj })
  };

  // Search Enter Button
  const onPressEnter = (event: any) => {
    let value = event.target.value.trim()
    let obj = { ...searchObject };
    switch (dropdownvalue) {
      case 'Battery ID':
        obj.batteryId = value;
        break;
      case 'Battery Rx SIM':
        obj.serialNumber = value;
        break;
      case 'Vehicle ID':
        obj.vehicleId = value;
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  };

  const handleSearch = () => {
    const { batteryId, vehicleId, batteryRxSim, serialNumber } = searchObject;
    let obj = { ...searchObject }
    switch (dropdownvalue) {
      case 'Battery ID':
        if (!batteryId) {
          setemptyError("Please Enter Battery ID")
          return
        } else {
          obj.batteryId = inputvalue.trim();
        }
        break;
      case 'Battery Rx SIM':
        if (!serialNumber) {
          setemptyError("Please Enter Battery Rx SIM")
          return
        } else {
          obj.serialNumber = inputvalue.trim();
        }
        break;
      case 'Vehicle ID':
        if (!vehicleId) {
          setemptyError("Please Enter Vehicle ID")
          return
        } else {
          obj.vehicleId = inputvalue.trim();
        }
        break;
      default:
        setemptyError("")
    }
    setSearchObject({ ...obj })
    setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    setExportReqBody({ ...exportReqBody, ...obj, pageOffset: 1 })
    setCountReq({ ...countReq, ...obj, pageOffset: 1 })
  }

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
    }
  }, [value])

  useEffect(() => {
    getDailyBatteryReport()
    dataCount()
  }, [tableReqBody])

  // useEffect(() => {
  //   if (refresh === 0) return
  //   getDailyBatteryReport()
  //   dataCount()
  // }, [refresh]);

  const getDailyBatteryReport = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = tableReqBody;
    if (payload.batteryId == "") payload.batteryId = data?.batteryId;
    if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId;
    if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;
    if (payload.orgId === 0) return

    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    setIsShowSpinner(true);

    try {
      const response = await service({
        url: constantUrl.api.dailyBatteryPerformance.getReport,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setTableReport(response)
      setIsShowSpinner(false)
      const { batteryId, vehicleId, serialNumber } = searchObject;
      if (
        response != null &&
        response != undefined &&
        response.length === 0 &&
        inputvalue != ""
      ) {
        switch (dropdownvalue) {
          case "Battery ID":
            if (batteryId) {
              setemptyError("Please Enter Valid Battery ID");
              return;
            }
            break;
          case "Battery Rx SIM":
            if (serialNumber) {
              setemptyError("Please Enter Valid Battery Rx SIM");
              return;
            }
            break;
          case "Vehicle ID":
            if (vehicleId) {
              setemptyError("Please Enter Valid Vehicle ID");
              return;
            }
            break;
          default:
            setemptyError("");
        }
      }

    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }

  const dataCount = async () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let payload = tableReqBody;

    if (payload?.batteryId == "") payload.batteryId = data?.batteryId;
    if (payload?.vehicleId === "") payload.vehicleId = data?.vehicleId;
    if (payload?.serialNumber === "") payload.serialNumber = data?.serialId;
    if (payload.orgId === 0) return

    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    try {
      const response = await service({
        url: constantUrl.api.dailyBatteryPerformance.getCount,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
        data: payload
      })
      setCount(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (action !== "") {
      getDowFormat(action)
    }
  }, [action])

  const getDowFormat = async (format: string) => {
    if (selectorLabel.some((item: any) => item.enabled == true)) {
      let userInfo: any = Utility.getUserInfo();
      let token = userInfo.authToken;

      setIsShowSpinner(true);

      let payload = {
        orgId: tableReqBody?.orgId,
        divisionId: tableReqBody?.divisionId,
        locationId: tableReqBody?.locationId,
        buildingId: tableReqBody?.buildingId,
        startDate: tableReqBody?.startDate,
        endDate: tableReqBody?.endDate,
        batteryId: tableReqBody?.batteryId,
        vehicleId: tableReqBody?.vehicleId,
        serialNumber: tableReqBody?.serialNumber,
        pageOffset: 1,
        pageLimit: count + 1,
        sortColumns: tableReqBody?.sortColumns,
        sortDirection: tableReqBody?.sortDirection,
      }

      try {
        const response = await service({
          url: constantUrl.api.dailyBatteryPerformance.dwn + '/' + format,
          method: 'POST',
          responseType: 'blob',
          headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
          data: payload
        })

        if (response) {
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Daily-Battery-Performance-Report.' + format;
          a.click();
          setIsShowSpinner(false);
        }
      } catch (error) {
        setIsShowSpinner(false);
        console.log(error)
      }
    }
    else {
      setAlert(true)
    }
  }

  const selectColumn = () => {
    if (selectorRef.current && selector) {
      selectorRef.current.style.right = "-370px";
    } else {
      if (selectorRef.current) { selectorRef.current.style.right = "0px" }
    }
    setSelector(!selector);
  };

  const applyChanges = async () => {
    selectColumn();

    var columnPayload = {
      reportId: 'DAILY_BATTERY_PERFORMANCE',
      reportName: 'DAILY_BATTERY_PERFORMANCE',
      columnSettings: selectorLabel,
      sortColumn: tableReqBody?.sortColumns,
      sortDirection: tableReqBody?.sortDirection
    };

    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;

    setIsShowSpinner(true);

    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}`,
        method: "PUT",
        headers: { Authorization: "Bearer " + authToken },
        data: columnPayload
      });

      setIsShowSpinner(false)
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn([...columnData])
      setSelectorLabel([...selectorData])
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  };

  const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    let columndata: any = selectorLabel
    let index: number = parseInt(event.target.name);
    let checked: boolean = event.target.checked;
    columndata[index].enabled = checked
    setSelectorLabel([...columndata])
    setClearAll(false)
    setSelectAll(false)
  };

  const clearall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = false;
    }
    setSelectorLabel([...columnLable])
    setClearAll(true)
    setSelectAll(false)
  };

  const selectall = () => {
    let columnLable = selectorLabel
    for (let i = 0; i < columnLable.length; i++) {
      columnLable[i]['enabled'] = true;
    }
    setSelectorLabel(columnLable)
    setClearAll(false)
    setSelectAll(true)
  }

  const cancelSelection = () => {
    let columnData = selectorLabel;
    for (let i = 0; i < columnData.length; i++) {
      columnData[i]['enabled'] = columnData[i].enabled;
    }
    setSelectorLabel(columnData)
    selectColumn()
  };

  const getReportSettings = async () => {
    const reportId = 'DAILY_BATTERY_PERFORMANCE'
    let userInfo: any = Utility.getUserInfo();
    var authToken = userInfo.authToken;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;

    try {
      const response = await service({
        url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
        method: "GET",
        headers: { Authorization: "Bearer " + authToken },
      })
      let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
      setColumn(columnData)
      setSelectorLabel(selectorData)
      if (propsData?.batteryId) {
        setInput(propsData.batteryId);  
      }    
      setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: propsData?.batteryId ?? data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId, pageOffset: 1, pageLimit: 10 })
      setExportReqBody({ ...exportReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: propsData?.batteryId ?? data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setCountReq({ ...countReq, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: propsData?.batteryId ?? data?.batteryId, vehicleId: data?.vehicleId, serialNumber: data?.serialId })
      setCurrent(1);

      setInitial(false)
    }
    catch (error) {
      console.log(error)
    }
  };

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    switch (id) {
      case "batteryId":
        if (sort.batteryId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "vehicleId":
        if (sort.vehicleId) {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "batteryRxSim":
        if (sort.batteryRxSim) {
          setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "imageType":
        if (sort.imageType) {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "log":
        if (sort.log) {
          setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "date":
        if (sort.date) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateStamp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateStamp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiChargeAhrs":
        if (sort.posiChargeAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "chargeAhs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "chargeAhs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dischAhrs":
        if (sort.dischAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dischargeAhs", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dischargeAhs", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dischKwh":
        if (sort.dischKwh) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dischargeKwh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dischargeKwh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posifastchgHours":
        if (sort.posifastchgHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posifullchgHours":
        if (sort.posifullchgHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posiEqHours":
        if (sort.posiEqHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "keyonHours":
        if (sort.keyonHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOnMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOnMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "keyoffHours":
        if (sort.keyoffHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOffMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "keyOffMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "runHours":
        if (sort.runHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "runMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "runMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "fullchrcomp":
        if (sort.fullchrcomp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeComplete", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fullChargeComplete", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "eqComp":
        if (sort.eqComp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqCompleted", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "eqCompleted", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "minBattSoc":
        if (sort.minBattSoc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatterySoc", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatterySoc", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxBattSoc":
        if (sort.maxBattSoc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxBatterySoc", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxBatterySoc", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxBattTemp":
        if (sort.maxBattTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatMaxBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatMaxBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "mixBattTempDate":
        if (sort.mixBattTempDate) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryTempDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryTempDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "mixBattTemp":
        if (sort.mixBattTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatMinBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatMinBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "avgBattTemp":
        if (sort.avgBattTemp) {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatAvgBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "formatAvgBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dateMinBatVolt":
        if (sort.dateMinBatVolt) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryVoltageDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryVoltageDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "minBattVoltAmps":
        if (sort.minBattVoltAmps) {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "minBatteryVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dateMaxDischAmps":
        if (sort.dateMaxDischAmps) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDischargeAmpsDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDischargeAmpsDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxDischAmpsVolts":
        if (sort.maxDischAmpsVolts) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDischargeAmps", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxDischargeAmps", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "lowWaterToday":
        if (sort.lowWaterToday) {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowWaterToday", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "lowWaterToday", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "faultCode1":
        if (sort.faultCode1) {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode1", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode1", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "faultCode2":
        if (sort.faultCode2) {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode2", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode2", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "faultCode3":
        if (sort.faultCode3) {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode3", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "faultCode3", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiKwh":
        if (sort.nonPosiKwh) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiKwh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiKwh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "posifastChargEvents":
        if (sort.posifastChargEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "fastChargeEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosifastChargEvents":
        if (sort.nonPosifastChargEvents) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiFastChargeEvents", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiFastChargeEvents", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosifastCharghours":
        if (sort.nonPosifastCharghours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiFastChargeMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiFastChargeMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiEqHours":
        if (sort.nonPosiEqHours) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqMinutes", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiEqMinutes", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "nonPosiAhrs":
        if (sort.nonPosiAhrs) {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiAh", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "nonPosiAh", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dateMaxImbalVolt":
        if (sort.dateMaxImbalVolt) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxImbalanceVoltageDate", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxImbalanceVoltageDate", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "maxPackImbalVolt":
        if (sort.maxPackImbalVolt) {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxImbalanceVoltage", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "maxImbalanceVoltage", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "org":
        if (sort.org) {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "div":
        if (sort.div) {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "loc":
        if (sort.loc) {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "site":
        if (sort.site) {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
      case "dateUpdated":
        if (sort.dateUpdated) {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateUpdated", sortDirection: "ASC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        } else {
          setTableReqBody({ ...tableReqBody, sortColumns: "dateUpdated", sortDirection: "DESC", pageOffset: 1 })
          setCurrent(1)
          setSort({ ...sort, [id]: getIdboolean })
        }
        break
    }
  };

  const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
    setTableReqBody({ ...tableReqBody, pageOffset })
    setMaxindex(maxIndex)
    setMindex(minIndex)
    setCurrent(pageOffset)
  }

  const onSelect = (pageLimit: any) => {
    const numPageSize = Number(pageLimit)
    setCurrent(1)
    setPageSize(numPageSize)
    setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
  };

  const isLeapYear = (year: number) => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };

  const daysFilter = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    const isLeapYearAtStart = isLeapYear(startYear);
    const isLeapYearAtEnd = isLeapYear(endYear);
    const Difference_In_Time = end.getTime() - start.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
    if (Difference_In_Days > maxDays) {
      setModalMessage(`The maximum range allowed is 1 year.`);
      setIsModalVisible(true);
      return;
    }
    setTableReqBody({ ...tableReqBody, startDate, endDate });
  };

  const handleModalClose = () => {
    onChange([subDays(new Date(), 6), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  // const refresh = () => {
  //   getDailyBatteryReport()
  //   dataCount()
  // }

  useEffect(() => {
    setInitial(true);
    getReportSettings();
  }, [])

  useEffect(() => {
    if (initial == false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setInput("");
      setemptyError("");
      const data = {
        batteryId: '',
        vehicleId: '',
        serialNumber: ''
      }
      setSearchObject(data)
      setTableReqBody({ ...tableReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setExportReqBody({ ...exportReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setCountReq({ ...countReq, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId, serialNumber: globalData?.serialId })
      setCurrent(1);
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId, globalData?.serialId])

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div ref={filtersRef} className="posi_search_container">
        <div className="col-md-6 px-0 drop_search-main">
          <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
            <div className='ant-design-select'>
              <Select
                value={dropdownvalue}
                size={'middle'}
                onChange={onChangedropdown}
                className='drop-filter'
                dropdownStyle={{ zIndex: 900 }}
              >
                {DropList && DropList.map((item: any, index: any) => {
                  return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                })}
              </Select>
            </div>
            <div>
              <Space direction="vertical" size="middle" className='search-input'>
                <Space.Compact className='posi_bmid_search_width'>
                  <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                    onPressEnter={onPressEnter} />
                  <Button type="primary" onClick={handleSearch}>Find</Button>
                </Space.Compact>
              </Space>
              <div className="errorColor">{emptyError}</div>
            </div>
          </ConfigProvider>
        </div>
        <div className="column-date-filter position-relative">
          {
            showDateFilter && <span className="date-selector">
              <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
            </span>
          }
          <button
            onClick={selectColumn}
            type="button"
            className="btn btn-light selector-btn"
          >
            Column Selector
          </button>
          <span className="date-dropdown">
            <DaysFilter
              daysFilter={daysFilter}
              customFilterStatusChange={setShowDateFilter}
              dateOptions={dateValue}
              defaultDateLabel={'Last 7 Days'}
            />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
        </div>
      </div>
      <div className="main-panel pt-3 position-relative">
        <div className="ChartPanel panelShadow bg-white">
          <div className="chartTitle p-2 d-flex  align-items-center position-relative">
            <div className="table_title">Daily Battery Performance Report</div>
            <div className="show-entries">
              <ShowEntries getPageSize={onSelect} />
            </div>
          </div>
          <div id="table-container">
            <div style={{ height: tableHeight + 'px' }} className="history_report overflow-auto scrollbar">
              <table className="billable width100">
                <thead className='tableHeader'>
                  <tr className="billable-head">
                    {column?.[0]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[0]?.['columnName']}</span>
                        {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th>
                      : ""}
                    {column?.[1]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[1]?.['columnName']}</span>
                        {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[2]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('batteryRxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[2]?.['columnName']}</span>
                        {sort.batteryRxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[3]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[3]?.['columnName']}</span>
                        {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[4]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('log')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[4]?.['columnName']}</span>
                        {sort.log ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[5]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('date')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[5]?.['columnName']}</span>
                        {sort.date ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[6]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiChargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[6]?.['columnName']}</span>
                        {sort.posiChargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[7]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dischAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[7]?.['columnName']}</span>
                        {sort.dischAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[8]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dischKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[8]?.['columnName']}</span>
                        {sort.dischKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[9]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posifastchgHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[9]?.['columnName']}</span>
                        {sort.posifastchgHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[10]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posifullchgHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[10]?.['columnName']}</span>
                        {sort.posifullchgHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[11]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posiEqHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[11]?.['columnName']}</span>
                        {sort.posiEqHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[12]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('keyonHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[12]?.['columnName']}</span>
                        {sort.keyonHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[13]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('keyoffHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[13]?.['columnName']}</span>
                        {sort.keyoffHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[14]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('runHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[14]?.['columnName']}</span>
                        {sort.runHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[15]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('fullchrcomp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[15]?.['columnName']}</span>
                        {sort.fullchrcomp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[16]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('eqComp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[16]?.['columnName']}</span>
                        {sort.eqComp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[17]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('minBattSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[17]?.['columnName']}</span>
                        {sort.minBattSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[18]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxBattSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[18]?.['columnName']}</span>
                        {sort.maxBattSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[19]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxBattTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[19]?.['columnName']}</span>
                        {sort.maxBattTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[20]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('mixBattTempDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[20]?.['columnName']}</span>
                        {sort.mixBattTempDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[21]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('mixBattTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[21]?.['columnName']}</span>
                        {sort.mixBattTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[22]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('avgBattTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[22]?.['columnName']}</span>
                        {sort.avgBattTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[23]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateMinBatVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[23]?.['columnName']}</span>
                        {sort.dateMinBatVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[24]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('minBattVoltAmps')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[24]?.['columnName']}</span>
                        {sort.minBattVoltAmps ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[25]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateMaxDischAmps')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[25]?.['columnName']}</span>
                        {sort.dateMaxDischAmps ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[26]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxDischAmpsVolts')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[26]?.['columnName']}</span>
                        {sort.maxDischAmpsVolts ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[27]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('lowWaterToday')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[27]?.['columnName']}</span>
                        {sort.lowWaterToday ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[28]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('faultCode1')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[28]?.['columnName']}</span>
                        {sort.faultCode1 ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[29]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('faultCode2')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[29]?.['columnName']}</span>
                        {sort.faultCode2 ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[30]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('faultCode3')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[30]?.['columnName']}</span>
                        {sort.faultCode3 ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[31]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[31]?.['columnName']}</span>
                        {sort.nonPosiKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[32]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('posifastChargEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[32]?.['columnName']}</span>
                        {sort.posifastChargEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[33]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosifastChargEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[33]?.['columnName']}</span>
                        {sort.nonPosifastChargEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[34]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosifastCharghours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[34]?.['columnName']}</span>
                        {sort.nonPosifastCharghours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[35]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiEqHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[35]?.['columnName']}</span>
                        {sort.nonPosiEqHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[36]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('nonPosiAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[36]?.['columnName']}</span>
                        {sort.nonPosiAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[37]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateMaxImbalVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[37]?.['columnName']}</span>
                        {sort.dateMaxImbalVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[38]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('maxPackImbalVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[38]?.['columnName']}</span>
                        {sort.maxPackImbalVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                    {column?.[39]?.['enabled'] ? <th>
                      <span >{column?.[39]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[40]?.['enabled'] ? <th>
                      <span >{column?.[40]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[41]?.['enabled'] ? <th>
                      <span >{column?.[41]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[42]?.['enabled'] ? <th>
                      <span >{column?.[42]?.['columnName']}</span>
                    </th> : ""}
                    {column?.[43]?.['enabled'] ? <th>
                      <div onClick={() => sortBy('dateUpdated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                        <span >{column?.[43]?.['columnName']}</span>
                        {sort.dateUpdated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                      </div>
                    </th> : ""}
                  </tr>
                </thead>
                <tbody>
                  {tableReport?.map((item: any, index: any) => {
                    const { batteryId, vehicleId, serialNumber, logNumber, formatDateStamp, chargeAhs, dischargeAhs, dischargeKwh,
                      fastChargeMinutes, fullChargeMinutes, eqMinutes, keyOnMinutes, keyOffMinutes, runMinutes, fullChargeComplete,
                      minBatterySoc, eqCompleted, maxBatterySoc, formatMinBatteryTempDate, formatMaxBatteryTemp, formatAvgBatteryTemp, formatMinBatteryTemp,
                      formatMinBatteryVoltageDate, maxDischargeAmps, formatMaxDischargeAmpsDate, lowWaterToday, maxDischargeVoltage, faultCode1,
                      faultCode2, faultCode3, fastChargeEvents, nonPosiKwh, nonPosiFastChargeEvents, nonPosiFastChargeMinutes, nonPosiEqMinutes,
                      nonPosiAh, maxImbalanceVoltage, formatMaxImbalanceVoltageDate, orgName, divisionName, locationName, buildingName, minBatteryVoltage,
                      building, location, division, organization, formatDateUpdated, minBatteryAmps, maxImbalanceAmps, deviceType } = item
                    return (
                      <React.Fragment key={'keys' + index}>
                        <tr>
                          {column?.[0]?.['enabled'] ? <td>{batteryId}</td> : ""}
                          {column?.[1]?.['enabled'] ? <td>{vehicleId}</td> : ""}
                          {column?.[2]?.['enabled'] ? <td>{serialNumber}</td> : ""}
                          {column?.[3]?.['enabled'] ? <td>{deviceType}</td> : ""}
                          {column?.[4]?.['enabled'] ? <td>{logNumber}</td> : ""}
                          {column?.[5]?.['enabled'] ? <td>{formatDateStamp}</td> : ""}
                          {column?.[6]?.['enabled'] ? <td>{chargeAhs}</td> : ""}
                          {column?.[7]?.['enabled'] ? <td>{dischargeAhs}</td> : ""}
                          {column?.[8]?.['enabled'] ? <td>{dischargeKwh}</td> : ""}
                          {column?.[9]?.['enabled'] ? <td>{fastChargeMinutes}</td> : ""}
                          {column?.[10]?.['enabled'] ? <td>{fullChargeMinutes}</td> : ""}
                          {column?.[11]?.['enabled'] ? <td>{eqMinutes}</td> : ""}
                          {column?.[12]?.['enabled'] ? <td>{keyOnMinutes}</td> : ""}
                          {column?.[13]?.['enabled'] ? <td>{keyOffMinutes}</td> : ""}
                          {column?.[14]?.['enabled'] ? <td>{runMinutes}</td> : ""}
                          {column?.[15]?.['enabled'] ? <td>{fullChargeComplete}</td> : ""}
                          {column?.[16]?.['enabled'] ? <td>{eqCompleted}</td> : ""}
                          {column?.[17]?.['enabled'] ? <td>{minBatterySoc}</td> : ""}
                          {column?.[18]?.['enabled'] ? <td>{maxBatterySoc}</td> : ""}
                          {column?.[19]?.['enabled'] ? <td>{formatMaxBatteryTemp}</td> : ""}
                          {column?.[20]?.['enabled'] ? <td>{formatMinBatteryTempDate}</td> : ""}
                          {column?.[21]?.['enabled'] ? <td>{formatMinBatteryTemp}</td> : ""}
                          {column?.[22]?.['enabled'] ? <td>{formatAvgBatteryTemp}</td> : ""}
                          {column?.[23]?.['enabled'] ? <td>{formatMinBatteryVoltageDate}</td> : ""}
                          {column?.[24]?.['enabled'] ? <td>{minBatteryVoltage + "v@" + minBatteryAmps + "A"}</td> : ""}
                          {column?.[25]?.['enabled'] ? <td>{formatMaxDischargeAmpsDate}</td> : ""}
                          {column?.[26]?.['enabled'] ? <td>{maxDischargeAmps + "A@" + maxDischargeVoltage + "v"}</td> : ""}
                          {column?.[27]?.['enabled'] ? <td>{lowWaterToday}</td> : ""}
                          {column?.[28]?.['enabled'] ? <td>{faultCode1}</td> : ""}
                          {column?.[29]?.['enabled'] ? <td>{faultCode2}</td> : ""}
                          {column?.[30]?.['enabled'] ? <td>{faultCode3}</td> : ""}
                          {column?.[31]?.['enabled'] ? <td>{nonPosiKwh}</td> : ""}
                          {column?.[32]?.['enabled'] ? <td>{fastChargeEvents}</td> : ""}
                          {column?.[33]?.['enabled'] ? <td>{nonPosiFastChargeEvents}</td> : ""}
                          {column?.[34]?.['enabled'] ? <td>{nonPosiFastChargeMinutes}</td> : ""}
                          {column?.[35]?.['enabled'] ? <td>{nonPosiEqMinutes}</td> : ""}
                          {column?.[36]?.['enabled'] ? <td>{nonPosiAh}</td> : ""}
                          {column?.[37]?.['enabled'] ? <td>{formatMaxImbalanceVoltageDate}</td> : ""}
                          {column?.[38]?.['enabled'] ? <td>{`${maxImbalanceVoltage.toFixed(2)}v/${maxImbalanceAmps}A`}</td> : ""}
                          {column?.[39]?.['enabled'] ? <td>{organization}</td> : ""}
                          {column?.[40]?.['enabled'] ? <td>{division}</td> : ""}
                          {column?.[41]?.['enabled'] ? <td>{location}</td> : ""}
                          {column?.[42]?.['enabled'] ? <td>{building}</td> : ""}
                          {column?.[43]?.['enabled'] ? <td>{formatDateUpdated}</td> : ""}
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div ref={paginationRef}>
              <PaginationComponent
                handlePagination={handlePagination}
                totalLength={count}
                pageSize={pageSize}
                current={current}
              />
            </div>
          </div>
        </div>

        <div ref={selectorRef} className="column-selector selector ">
          <div className="column-title">Column Selector</div>
          <div className="slector-body">
            <label>
              <input
                checked={clearAll}
                value="clearAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={clearall}
              />
              <span></span>
            </label>
            <p>Clear All</p>

            <label>
              <input
                checked={selectAll}
                value="selectAll"
                type="checkbox"
                aria-label="checkbox"
                onChange={selectall}
              />
              <span></span>
            </label>
            <p>Select All</p>
          </div>
          <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
            {selectorLabel?.map((item: any, index: any) => {
              return (
                <div key={'key' + index} className="slector-body">
                  <label>
                    <input
                      name={index}
                      onChange={onchangeColumn}
                      checked={item['enabled']}
                      value={item.value}
                      type="checkbox"
                      aria-label="checkbox"
                    />
                    <span></span>
                  </label>
                  <p>{item['columnName']}</p>
                </div>
              )
            })}
          </div>
          <div className="m-flex">
            <button onClick={cancelSelection} className="cancel">
              Cancel
            </button>
            <button onClick={applyChanges} className="apply-changes mr-3">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={alert}
        closable={false}
        centered title={"Atleast Select 1 Column"}
        footer={[
          <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
        ]}
      ></Modal>
      <SessionWarning />
    </>
  );
};

export default DailyBatteryPerformance;