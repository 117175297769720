import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays, parseISO } from 'date-fns';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";


const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    startDate: string,
    endDate: string,
    batteryId: string,
    vehicleId: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    timeZone: string
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: sevenDaysAgo,
    endDate: today,
    batteryId: "",
    vehicleId: "",
    pageOffset: 1,
    pageLimit: 10,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const LifeTimeBtteryHistoryReport: FC<Props> = ({ globalData, action }) => {
    // const LifeTimeBtteryHistoryReport: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [selector, setSelector] = useState(false)
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, serialNumber: false, firmRev: false, initDate: false, daysOp: false, dataAs: false, totalChargeEvents: false,
        cumChargeAhrs: false, cumChargeKwh: false, minStartSoc: false, maxStartSoc: false, totalEqHours: false, totalCompEQ: false, daysSince: false, daysLast: false,
        maxDaysEq: false, maxAhrsEq: false, daysLowVolt: false, daysHighVolt: false, minRecVolt: false, maxRecVolt: false, norVolt: false, totDisAg: false, totDisKwh: false,
        runHours: false, keyOnHours: false, keyOffHours: false, avgChargetime: false, avgIdleTime: false, daysHighTemp: false, minBatteryTemp: false, maxRecordedTemp: false,
        warrantyMonths: false, ahBatteryLife: false, ratedBatteryCap: false, estimatedBatteryCapacity: false, lowWaterDays: false, organization: false, division: false,
        location: false, building: false, imageType: false
    })

    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [logReqBody, setlogReqBody] = useState<any>(
        {
            orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "", pageOffset: 1, pageLimit: 10, sortColumns: "",
            sortDirection: "", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    )
    const [countReqBody, setCountReqBody] = useState<any>(
        {
            orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "", pageOffset: 1, pageLimit: 10, sortColumns: "",
            sortDirection: ""
        }
    )
    const [lifetime, setLifetime] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [alert, setAlert] = useState<boolean>(false);

    const [emptyError, setemptyError] = useState<string>("");

    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        serialNumber: ''
    })

    const DropList = [
        { label: "Battery ID" },
        { label: "Vehicle ID" },
        { label: "Battery Rx SIM #" }

    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")

    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        const data = {
            batteryId: '',
            vehicleId: '',
            serialNumber: ''
        }
        setSearchObject(data)
        if (inputvalue) {
            setlogReqBody({ ...logReqBody, ...data, pageOffset: 1 })
            setCountReqBody({ ...countReqBody, ...data, pageOffset: 1 })
        }

    }

    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery ID") {
            obj.batteryId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx SIM #") {
            obj.serialNumber = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, ...obj, pageOffset: 1 })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery Rx SIM #':
                obj.serialNumber = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, ...obj, pageOffset: 1 })
    };

    const handleSearch = () => {
        const { batteryId, vehicleId, serialNumber } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery ID")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx SIM #':
                if (!serialNumber) {
                    setemptyError("Please Enter Battery Rx SIM")
                    return
                } else {
                    obj.serialNumber = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, ...obj, pageOffset: 1 })
    };


    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
        }
    }, [value])

    const selectorRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getData();
    }, [logReqBody]);

    useEffect(() => {
        getCount();
    }, [countReqBody]);

    async function getData() {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let payload = logReqBody;

        if (payload.batteryId == "") {
            payload.batteryId = data?.batteryId.trim();
        }
        if (payload.vehicleId === "") {
            payload.vehicleId = data?.vehicleId.trim();
        }

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.lifetimeBatteryHistory.getlifetimeBatteryHistory,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setLifetime(response);
            setIsShowSpinner(false);
            const { batteryId, vehicleId, serialNumber } = searchObject;
            if (
                response != null &&
                response != undefined &&
                response.length === 0 &&
                inputvalue != ""
            ) {
                switch (dropdownvalue) {
                    case "Battery ID":
                        if (batteryId) {
                            setemptyError("Please Enter Valid Battery ID");
                            return;
                        }
                        break;
                    case "Battery Rx SIM #":
                        if (serialNumber) {
                            setemptyError("Please Enter Valid Battery Rx SIM");
                            return;
                        }
                        break;
                    case "Vehicle ID":
                        if (vehicleId) {
                            setemptyError("Please Enter Valid Vehicle ID");
                            return;
                        }
                        break;
                    default:
                        setemptyError("");
                }
            }

        } catch (error) {
            console.error("getlifetimeBatteryHistory:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount() {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let payload = logReqBody;

        if (payload.batteryId == "") {
            payload.batteryId = data?.batteryId.trim();
        }
        if (payload.vehicleId === "") {
            payload.vehicleId = data?.vehicleId.trim();
        }

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.lifetimeBatteryHistory.getlifetimeBatteryHistorycount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("getlifetimeBatteryHistorycount:", error);
        }
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const daysFilter = (startDate: string, endDate: string) => {
        setlogReqBody({ ...logReqBody, startDate, endDate })
    };

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'LIFETIME_BATTERY_HISTORY',
            reportName: 'LIFETIME_BATTERY_HISTORY',
            columnSettings: selectorLabel,
            sortColumn: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        for (let i = 0; i < columnData.length; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'LIFETIME_BATTERY_HISTORY'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setlogReqBody({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setCountReqBody({ ...countReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setInitial(false)
            setCurrent(1);

        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            setIsShowSpinner(true)
            let payload =
            {
                orgId: logReqBody?.orgId,
                divisionId: logReqBody?.divisionId,
                locationId: logReqBody?.locationId,
                buildingId: logReqBody?.buildingId,
                startDate: logReqBody?.startDate,
                endDate: logReqBody?.endDate,
                batteryId: logReqBody?.batteryId,
                vehicleId: logReqBody?.vehicleId,
                serialNumber: logReqBody?.serialNumber,
                pageOffset: 1,
                pageLimit: totalCount + 1,
                sortColumns: logReqBody?.sortColumns,
                sortDirection: logReqBody?.sortDirection,
                timeZone: logReqBody?.timeZone
            }
            try {
                const response = await service({
                    url: constantUrl.api.lifetimeBatteryHistory.getlifetimeBatteryHistoryexport + '/' + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })
                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'Lifetime-Battery-History.' + format;
                    a.click();
                    setIsShowSpinner(false)
                }

            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    const haveSameData = function (obj1: any, obj2: any) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;

        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('lifeTimeBattery_W')) window.history.back()
        }

        getReportSettings()
        setInitial(true)
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (initial == false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setInput("");
            setemptyError("");
            const data = {
                batteryId: '',
                vehicleId: '',
                serialNumber: ''
            }
            setSearchObject(data)
            setlogReqBody({ ...logReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCountReqBody({ ...countReqBody, ...data, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
        return formatDate
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "batteryId":
                if (sort.batteryId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "confBatteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "confBatteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "confBatteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "confBatteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "confVehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "confVehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "confVehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "confVehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "serialNumber":
                if (sort.serialNumber) {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "imageType":
                if (sort.imageType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "firmRev":
                if (sort.firmRev) {
                    setlogReqBody({ ...logReqBody, sortColumns: "firmwareRevision", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "firmwareRevision", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "firmwareRevision", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "firmwareRevision", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "initDate":
                if (sort.initDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "initializationDate", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "initializationDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "initializationDate", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "initializationDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysOp":
                if (sort.daysOp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysInOperation", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysInOperation", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysInOperation", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysInOperation", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dataAs":
                if (sort.dataAs) {
                    setlogReqBody({ ...logReqBody, sortColumns: "logTimestamp", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "logTimestamp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "logTimestamp", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "logTimestamp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totalChargeEvents":
                if (sort.totalChargeEvents) {
                    setlogReqBody({ ...logReqBody, sortColumns: "cumulativeChargeEvents", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "cumulativeChargeEvents", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "cumulativeChargeEvents", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "cumulativeChargeEvents", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "cumChargeAhrs":
                if (sort.cumChargeAhrs) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeAhs", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeAhs", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeAhs", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeAhs", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "cumChargeKwh":
                if (sort.cumChargeKwh) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "minStartSoc":
                if (sort.minStartSoc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "minStartSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minStartSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "minStartSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minStartSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxStartSoc":
                if (sort.maxStartSoc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxStartSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxStartSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxStartSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxStartSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totalEqHours":
                if (sort.totalEqHours) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalEqHours", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalEqHours", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalEqHours", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalEqHours", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totalCompEQ":
                if (sort.totalCompEQ) {
                    setlogReqBody({ ...logReqBody, sortColumns: "completeEqEvents", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "completeEqEvents", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "completeEqEvents", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "completeEqEvents", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysSince":
                if (sort.daysSince) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysSinceCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysSinceCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysSinceCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysSinceCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysLast":
                if (sort.daysLast) {
                    setlogReqBody({ ...logReqBody, sortColumns: "dateLastCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "dateLastCompEq", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "dateLastCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "dateLastCompEq", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxDaysEq":
                if (sort.maxDaysEq) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxDaysBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxAhrsEq":
                if (sort.maxAhrsEq) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxAhsBetweenEqs", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysLowVolt":
                if (sort.daysLowVolt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysLowVoltage", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysLowVoltage", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysLowVoltage", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysLowVoltage", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysHighVolt":
                if (sort.daysHighVolt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysHighVoltage", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysHighVoltage", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysHighVoltage", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysHighVoltage", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "minRecVolt":
                if (sort.minRecVolt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "minRecordedVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minRecordedVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "minRecordedVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minRecordedVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxRecVolt":
                if (sort.maxRecVolt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxRecordedVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxRecordedVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxRecordedVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxRecordedVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "norVolt":
                if (sort.norVolt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCellsVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalCellsVolts", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCellsVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalCellsVolts", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totDisAg":
                if (sort.totDisAg) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalDischargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalDischargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalDischargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalDischargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totDisKwh":
                if (sort.totDisKwh) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalDischargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalDischargeKwh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalDischargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalDischargeKwh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "runHours":
                if (sort.runHours) {
                    setlogReqBody({ ...logReqBody, sortColumns: "runHours", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "runHours", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "runHours", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "runHours", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "keyOnHours":
                if (sort.keyOnHours) {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyNnHours", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "keyNnHours", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyNnHours", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "keyNnHours", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "keyOffHours":
                if (sort.keyOffHours) {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyOffHours", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "keyOffHours", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyOffHours", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "keyOffHours", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgChargetime":
                if (sort.avgChargetime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeHours", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeHours", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalChargeHours", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalChargeHours", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgIdleTime":
                if (sort.avgIdleTime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalIdleTime", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalIdleTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalIdleTime", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "totalIdleTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "daysHighTemp":
                if (sort.daysHighTemp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysHighTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysHighTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daysHighTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "daysHighTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "minBatteryTemp":
                if (sort.minBatteryTemp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "minBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minBatteryTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "minBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "minBatteryTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxRecordedTemp":
                if (sort.maxRecordedTemp) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxRecordedTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxRecordedTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxRecordedTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "maxRecordedTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "warrantyMonths":
                if (sort.warrantyMonths) {
                    setlogReqBody({ ...logReqBody, sortColumns: "warrantyBatteryLife", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "warrantyBatteryLife", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "warrantyBatteryLife", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "warrantyBatteryLife", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "ahBatteryLife":
                if (sort.ahBatteryLife) {
                    setlogReqBody({ ...logReqBody, sortColumns: "ahBatteryLife", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "ahBatteryLife", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "ahBatteryLife", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "ahBatteryLife", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "ratedBatteryCap":
                if (sort.ratedBatteryCap) {
                    setlogReqBody({ ...logReqBody, sortColumns: "ratedBatteryCapacity", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "ratedBatteryCapacity", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "ratedBatteryCapacity", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "ratedBatteryCapacity", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "estimatedBatteryCapacity":
                if (sort.estimatedBatteryCapacity) {
                    setlogReqBody({ ...logReqBody, sortColumns: "estimatedBatteryCapacity", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "estimatedBatteryCapacity", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "estimatedBatteryCapacity", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "estimatedBatteryCapacity", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lowWaterDays":
                if (sort.lowWaterDays) {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowWaterDays", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "lowWaterDays", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowWaterDays", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "lowWaterDays", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "organization":
                if (sort.organization) {
                    setlogReqBody({ ...logReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "division":
                if (sort.division) {
                    setlogReqBody({ ...logReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "location":
                if (sort.location) {
                    setlogReqBody({ ...logReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "building":
                if (sort.building) {
                    setlogReqBody({ ...logReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };


    // useEffect(() => {
    //     if (refresh === 0) return
    //     setlogReqBody({ ...logReqBody })
    //     setCountReqBody({ ...countReqBody })
    // }, [refresh]);


    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div ref={filtersRef} className="posi_bmid_search_container">
                <div className="col-md-6 px-0 drop_search-main">
                    <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                        <div className='ant-design-select'>
                            <Select
                                value={dropdownvalue}
                                size={'middle'}
                                onChange={onChangedropdown}
                                className='drop-filter'
                                dropdownStyle={{ zIndex: 900 }}
                            >
                                {DropList && DropList.map((item: any, index: any) => {
                                    return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Space direction="vertical" size="middle" className='search-input'>
                                <Space.Compact className='posi_bmid_search_width'>
                                    <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                        onPressEnter={onPressEnter} />
                                    <Button type="primary" onClick={handleSearch}>Find</Button>
                                </Space.Compact>
                            </Space>
                            <div className="errorColor">{emptyError}</div>
                        </div>
                    </ConfigProvider>
                </div>
                <div className="column-selector-btn">
                    <button
                        onClick={selectColumn}
                        type="button"
                        className="btn btn-light selector-btn"
                    >
                        Column Selector
                    </button>
                </div>
            </div>
            <div className="main-panel pt-3 position-relative">
                <div className="ChartPanel panelShadow bg-white">
                    <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                        <div className='ml-auto mr-auto'>Lifetime Battery History</div>
                        <div className='show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                        </div>
                    </div>
                    <div id="table-container">
                        <div style={{ height: tableHeight + 'px' }} className="lifetime_report overflow-auto scrollbar">
                            <table className="billable width100">
                                <thead className='tableHeader'>
                                    <tr className="billable-head">
                                        {column?.[0]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[0]?.['columnName']}</span>
                                                {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[1]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[1]?.['columnName']}</span>
                                                {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[2]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('serialNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[2]?.['columnName']}</span>
                                                {sort.serialNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[3]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[3]?.['columnName']}</span>
                                                {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[4]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('firmRev')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[4]?.['columnName']}</span>
                                                {sort.firmRev ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[5]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('initDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[5]?.['columnName']}</span>
                                                {sort.initDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[6]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysOp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[6]?.['columnName']}</span>
                                                {sort.daysOp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[7]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('dataAs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[7]?.['columnName']}</span>
                                                {sort.dataAs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[7]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totalChargeEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[7]?.['columnName']}</span>
                                                {sort.totalChargeEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[8]?.['enabled'] ? <th>
                                            <span >{column?.[8]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[9]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('cumChargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[9]?.['columnName']}</span>
                                                {sort.cumChargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('cumChargeKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.cumChargeKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('minStartSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.minStartSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[11]?.['enabled'] ? <th>
                                            <span >{column?.[11]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[11]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxStartSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[11]?.['columnName']}</span>
                                                {sort.maxStartSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[12]?.['enabled'] ? <th>
                                            <span >{column?.[12]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[12]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totalEqHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[12]?.['columnName']}</span>
                                                {sort.totalEqHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[13]?.['enabled'] ? <th>
                                            <span >{column?.[13]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[14]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totalCompEQ')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[14]?.['columnName']}</span>
                                                {sort.totalCompEQ ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[15]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysSince')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[15]?.['columnName']}</span>
                                                {sort.daysSince ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[16]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysLast')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[16]?.['columnName']}</span>
                                                {sort.daysLast ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[17]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxDaysEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[17]?.['columnName']}</span>
                                                {sort.maxDaysEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[18]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxAhrsEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[18]?.['columnName']}</span>
                                                {sort.maxAhrsEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[19]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysLowVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[19]?.['columnName']}</span>
                                                {sort.daysLowVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[20]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysHighVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[20]?.['columnName']}</span>
                                                {sort.daysHighVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[21]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('minRecVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[21]?.['columnName']}</span>
                                                {sort.minRecVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[22]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxRecVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[22]?.['columnName']}</span>
                                                {sort.maxRecVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[23]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('norVolt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[23]?.['columnName']}</span>
                                                {sort.norVolt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[24]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totDisAg')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[24]?.['columnName']}</span>
                                                {sort.totDisAg ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[25]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totDisKwh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[25]?.['columnName']}</span>
                                                {sort.totDisKwh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[26]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('runHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[26]?.['columnName']}</span>
                                                {sort.runHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[27]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('keyOnHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[27]?.['columnName']}</span>
                                                {sort.keyOnHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[28]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('keyOffHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[28]?.['columnName']}</span>
                                                {sort.keyOffHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[28]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('avgChargetime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[28]?.['columnName']}</span>
                                                {sort.avgChargetime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[29]?.['enabled'] ? <th>
                                            <span >{column?.[29]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[29]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('avgIdleTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[29]?.['columnName']}</span>
                                                {sort.avgIdleTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[30]?.['enabled'] ? <th>
                                            <span >{column?.[30]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[31]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('daysHighTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[31]?.['columnName']}</span>
                                                {sort.daysHighTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[32]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('minBatteryTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[32]?.['columnName']}</span>
                                                {sort.minBatteryTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[33]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxRecordedTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[33]?.['columnName']}</span>
                                                {sort.maxRecordedTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[33]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('warrantyMonths')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[33]?.['columnName']}</span>
                                                {sort.warrantyMonths ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[34]?.['enabled'] ? <th>
                                            <span >{column?.[34]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[34]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('ahBatteryLife')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[34]?.['columnName']}</span>
                                                {sort.ahBatteryLife ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[35]?.['enabled'] ? <th>
                                            <span >{column?.[35]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[36]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('ratedBatteryCap')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[36]?.['columnName']}</span>
                                                {sort.ratedBatteryCap ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[36]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('estimatedBatteryCapacity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[36]?.['columnName']}</span>
                                                {sort.estimatedBatteryCapacity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""} */}
                                        {column?.[37]?.['enabled'] ? <th>
                                            <span >{column?.[37]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[38]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('lowWaterDays')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[38]?.['columnName']}</span>
                                                {sort.lowWaterDays ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[39]?.['enabled'] ? <th>
                                            <span >{column?.[39]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[40]?.['enabled'] ? <th>
                                            <span >{column?.[40]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[41]?.['enabled'] ? <th>
                                            <span >{column?.[41]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[42]?.['enabled'] ? <th>
                                            <span >{column?.[42]?.['columnName']}</span>
                                        </th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lifetime?.map((item: any, index: number) => {
                                        const { confBatteryId, confVehicleId, serialNumber, firmwareRevision, initializationDate, daysInOperation, totalChargeEvents,
                                            cumulativeChargeAhs, cumulativeChargeKwh, totalEqHours, totalCompEqEvents, dateLastCompEq, maxDaysBetweenEqs,
                                            maxAhsBetweenEqs, minRecordedVolts, maxRecordedVolts, runHours, keyNnHours, keyOffHours, lowWaterDays, daysHighTemp,
                                            maxRecordedTemp, minBatteryTemp, ratedBatteryCapacity, estimatedBatteryCapacity, warrantyMonths, ahBatteryLife,
                                            organization, division, location, building, daysSinceCompEq, daysLowVoltage, minStartSoc, maxStartSoc,
                                            daysHighVoltage, avgChargeTime, avgIdleTime, totalDischargeAh, totalDischargeKwh, dataAsOf, logTimestamp, cumulativeChargeEvents
                                            , totalChargeAhs, totalChargeKwh, completeEqEvents, totalCellsVolts, totalChargeHours, totalIdleTime, warrantyBatteryLife,
                                            formatInitializationDate, formatLogTimestamp, formatDateLastCompEq, formatMinStartSoc, formatMaxStartSoc, formatMinBatteryTemp, formatMaxRecordedTemp, deviceType } = item
                                        return <tr>
                                            {column?.[0]?.['enabled'] ? <td>{confBatteryId}</td> : ""}
                                            {column?.[1]?.['enabled'] ? <td>{confVehicleId}</td> : ""}
                                            {column?.[2]?.['enabled'] ? <td>{serialNumber}</td> : ""}
                                            {column?.[3]?.['enabled'] ? <td>{deviceType}</td> : ""}
                                            {column?.[4]?.['enabled'] ? <td>{firmwareRevision}</td> : ""}
                                            {column?.[5]?.['enabled'] ? <td>{formatInitializationDate}</td> : ""}
                                            {column?.[6]?.['enabled'] ? <td>{daysInOperation}</td> : ""}
                                            {column?.[7]?.['enabled'] ? <td>{formatLogTimestamp}</td> : ""}
                                            {column?.[8]?.['enabled'] ? <td>{cumulativeChargeEvents}</td> : ""}
                                            {column?.[9]?.['enabled'] ? <td>{totalChargeAhs}</td> : ""}
                                            {column?.[10]?.['enabled'] ? <td>{totalChargeKwh}</td> : ""}
                                            {column?.[11]?.['enabled'] ? <td>{formatMinStartSoc}</td> : ""}
                                            {column?.[12]?.['enabled'] ? <td>{formatMaxStartSoc}</td> : ""}
                                            {column?.[13]?.['enabled'] ? <td>{totalEqHours}</td> : ""}
                                            {column?.[14]?.['enabled'] ? <td>{completeEqEvents}</td> : ""}
                                            {column?.[15]?.['enabled'] ? <td>{daysSinceCompEq}</td> : ""}
                                            {column?.[16]?.['enabled'] ? <td>{formatDateLastCompEq}</td> : ""}
                                            {column?.[17]?.['enabled'] ? <td>{maxDaysBetweenEqs}</td> : ""}
                                            {column?.[18]?.['enabled'] ? <td>{maxAhsBetweenEqs}</td> : ""}
                                            {column?.[19]?.['enabled'] ? <td>{daysLowVoltage}</td> : ""}
                                            {column?.[20]?.['enabled'] ? <td>{daysHighVoltage}</td> : ""}
                                            {column?.[21]?.['enabled'] ? <td>{minRecordedVolts}</td> : ""}
                                            {column?.[22]?.['enabled'] ? <td>{maxRecordedVolts}</td> : ""}
                                            {column?.[23]?.['enabled'] ? <td>{totalCellsVolts}</td> : ""}
                                            {column?.[24]?.['enabled'] ? <td>{totalDischargeAh}</td> : ""}
                                            {column?.[25]?.['enabled'] ? <td>{totalDischargeKwh}</td> : ""}
                                            {column?.[26]?.['enabled'] ? <td>{runHours}</td> : ""}
                                            {column?.[27]?.['enabled'] ? <td>{keyNnHours}</td> : ""}
                                            {column?.[28]?.['enabled'] ? <td>{keyOffHours}</td> : ""}
                                            {column?.[29]?.['enabled'] ? <td>{totalChargeHours}</td> : ""}
                                            {column?.[30]?.['enabled'] ? <td>{totalIdleTime}</td> : ""}
                                            {column?.[31]?.['enabled'] ? <td>{daysHighTemp}</td> : ""}
                                            {column?.[32]?.['enabled'] ? <td>{formatMinBatteryTemp}</td> : ""}
                                            {column?.[33]?.['enabled'] ? <td>{formatMaxRecordedTemp}</td> : ""}
                                            {column?.[34]?.['enabled'] ? <td>{warrantyBatteryLife}</td> : ""}
                                            {column?.[35]?.['enabled'] ? <td>{ahBatteryLife}</td> : ""}
                                            {column?.[36]?.['enabled'] ? <td>{ratedBatteryCapacity}</td> : ""}
                                            {column?.[37]?.['enabled'] ? <td>{estimatedBatteryCapacity == 0 ? "TBD" : estimatedBatteryCapacity}</td> : ""}
                                            {column?.[38]?.['enabled'] ? <td>{lowWaterDays}</td> : ""}
                                            {column?.[39]?.['enabled'] ? <td>{organization}</td> : ""}
                                            {column?.[40]?.['enabled'] ? <td>{division}</td> : ""}
                                            {column?.[41]?.['enabled'] ? <td>{location}</td> : ""}
                                            {column?.[42]?.['enabled'] ? <td>{building}</td> : ""}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div ref={paginationRef}>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={totalCount}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>

                </div>
                <div ref={selectorRef} className="column-selector selector ">
                    <div className="column-title">Column Selector</div>
                    <div className="slector-body">
                        <label>
                            <input
                                checked={clearAll}
                                value="clearAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={clearall}
                            />
                            <span></span>
                        </label>
                        <p>Clear All</p>

                        <label>
                            <input
                                checked={selectAll}
                                value="selectAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={selectall}
                            />
                            <span></span>
                        </label>
                        <p>Select All</p>
                    </div>
                    <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                        {selectorLabel?.map((item: any, index: any) => {
                            return (
                                <div key={'key' + index} className="slector-body">
                                    <label>
                                        <input
                                            name={index}
                                            onChange={onchangeColumn}
                                            checked={item['enabled']}
                                            value={item.value}
                                            type="checkbox"
                                            aria-label="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                    <p>{item['columnName']}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="m-flex">
                        <button onClick={cancelSelection} className="cancel">
                            Cancel
                        </button>
                        <button onClick={applyChanges} className="apply-changes mr-3">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}
export default LifeTimeBtteryHistoryReport