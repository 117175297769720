import React, { FC, useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react';
import { Modal, Input, Button, ConfigProvider } from "antd";
import LoadingSpinner from '../spinner/loading-spinner'
import PosinetHeader from '../../layout/header';
import { Utility } from "../../helper/index";
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import ShowEntries from '../reusableComponent/showEntries';
import { format, parseISO, startOfMonth, subDays } from 'date-fns'
import HeaderFilter from '../reusableComponent/headerFilter';
import SelectDays from '../reusableComponent/selectDays';
import SummaryFaultCards from '../reusableComponent/issuesSummaryCard';
import SessionWarning from '../reusableComponent/sessionWarning';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DaysFilter from '../reusableComponent/dateNewFilter';
import constantUrl from '../../settings/constants';
import { resize, hideSidebar } from '../../settings/resize';

const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    startDate: string,
    endDate: string,
    batteryId: string,
    vehicleId: string,
    serialNumber: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    // timeZone: string
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: sevenDaysAgo,
    endDate: today,
    batteryId: "",
    vehicleId: "",
    serialNumber: "",
    pageOffset: 1,
    pageLimit: 10,
    sortColumns: "battery",
    sortDirection: "ASC",
    // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    email: boolean,
    // refresh: number
    setEmail: () => void
}


const IssuesReport2: FC<Props> = ({ globalData, action, email, setEmail }) => {
    // const IssuesReport2: FC<Props> = ({ globalData, action, email, setEmail, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [sort, setSort] = useState<any>({ battery: false, charger: false, severity: false, code: false, datee: false, timee: false, dateUpdated: false, imageType:false })
    const [totalCount, setTotalCount] = useState<number>(1)
    const [issuesReport2, setIssuesReport2] = useState<any[]>([])
    const [emailText, setEmailText] = useState<string>("")
    const tabelRef = useRef<HTMLDivElement>(null)
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [ismailsuccess, setIsMailSuccess] = useState<boolean>(false)
    const [validEmail, setValidEmail] = useState<string>("")
    const [ismailfailure, setIsMailFailure] = useState<boolean>(false)
    const [invalidEmail, setInValidEmail] = useState<string>("")
    const [dateLabel, setDateLabel] = useState<string>("Last 7 Days")
    const [dateRange, setDateRange] = useState<any>({ sevenDaysAgo: format(subDays(new Date(), 6), 'dd-MMM-yyyy'), today: format(new Date(), 'dd-MMM-yyyy') })
    const [logReqBody, setlogReqBody] = useState<any>(
        // {
        //     orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "", startDate: sevenDaysAgo, endDate: today, serialNumber: "", pageOffset: 1, pageLimit: 10, sortColumns: "battery",
        //     sortDirection: "ASC", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        // }
        {
            orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "", startDate: sevenDaysAgo, endDate: today, serialNumber: "", pageOffset: 1, pageLimit: 10, sortColumns: "battery",
            sortDirection: "ASC"
        }
    )
    const [countReqBody, setCountReqBody] = useState<any>(
        {
            orgId: 0, divisionId: -1, locationId: -1, buildingId: -1, batteryId: "", vehicleId: "", startDate: sevenDaysAgo, endDate: today, serialNumber: "", pageOffset: 1, pageLimit: 10, sortColumns: "battery",
            sortDirection: "ASC"
        }
    )
    const [initial, setInitial] = useState<boolean>(true);

    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');

    const dateValue = [
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 14 Days', label: 'Last 14 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
        { value: 'Last 60 Days', label: 'Last 60 Days' },
        { value: 'Last 90 Days', label: 'Last 90 Days' },
        { value: 'Custom Date Range', label: 'Custom Date Range' }]

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 107
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        // To calculate the time difference of two dates
        var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today, `Last ${Difference_In_Days} Days`);
        }
    }, [value])

    const selectorRef = useRef<HTMLDivElement>(null)

    // useEffect(() => {
    //     if (refresh === 0) return
    //     setlogReqBody({ ...logReqBody })
    //     setCountReqBody({ ...countReqBody })
    // }, [refresh])

    useEffect(() => {
        getData();
    }, [logReqBody]);

    useEffect(() => {
        getCount();
    }, [countReqBody]);

    async function getData() {
        if (logReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.issues2.getissues2,
                method: "POST",
                data: logReqBody,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setIssuesReport2(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getissues2:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount() {
        if (countReqBody.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.issues2.getissues2count,
                method: "POST",
                data: countReqBody,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("getissues2count:", error);
        }
    }

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('Issues_report2_W')) window.history.back()
        }

        setInitial(true)
        window.scrollTo(0, 0)
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        setCurrent(1);
        let org = data?.orgId > -1 ? data?.orgId : userId;
        setlogReqBody({ ...logReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
        setCountReqBody({ ...countReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
        setInitial(false)
    }, [])

    useEffect(() => {
        if (initial == false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

            setlogReqBody({ ...logReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    const haveSameData = function (obj1: any, obj2: any) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;

        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "battery":
                if (sort.battery) {
                    setlogReqBody({ ...logReqBody, sortColumns: "battery", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "battery", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "battery", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "battery", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "imageType":
                if (sort.imageType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "imageType", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "imageType", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "charger":
                if (sort.charger) {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerId", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargerId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "chargerId", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "chargerId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "severity":
                if (sort.severity) {
                    setlogReqBody({ ...logReqBody, sortColumns: "severityType", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "severityType", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "severityType", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "severityType", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "code":
                if (sort.code) {
                    setlogReqBody({ ...logReqBody, sortColumns: "code", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "code", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "code", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "code", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "datee":
                if (sort.datee) {
                    setlogReqBody({ ...logReqBody, sortColumns: "date", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "date", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "date", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "date", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "timee":
                if (sort.timee) {
                    setlogReqBody({ ...logReqBody, sortColumns: "time", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "time", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "time", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "time", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dateUpdated":
                if (sort.dateUpdated) {
                    setlogReqBody({ ...logReqBody, sortColumns: "dateUpdated", sortDirection: "ASC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "dateUpdated", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "dateUpdated", sortDirection: "DESC", pageOffset: 1 })
                    setCountReqBody({ ...countReqBody, sortColumns: "dateUpdated", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'MM/dd/yyyy ');
        return formatDate
    }

    const formattedTime = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatTime = format(parsedDate, 'hh:mm:ss');
        return formatTime
    }

    const claenderonChange = (startDate: string, endDate: string) => {
        setDateRange({ ...dateRange, sevenDaysAgo: startDate, today: endDate })
    }


    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        setIsShowSpinner(true)
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            batteryId: logReqBody?.batteryId,
            vehicleId: logReqBody?.vehicleId,
            serialNumber: logReqBody?.serialNumber,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumns: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection,
            // timeZone: logReqBody?.timeZone
        }
        try {
            const response = await service({
                url: constantUrl.api.issues2.issues2export + '/' + format,
                method: 'POST',
                responseType: 'blob',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response) {
                let url = window.URL.createObjectURL(response);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Weekly-Issues-Report2.' + format;
                a.click();
                setIsShowSpinner(false)
            }
            setIsShowSpinner(false)
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
        setCountReqBody({ ...countReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCountReqBody({ ...countReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const isLeapYear = (year: number) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };

    // const daysFilter = (startDate: string, endDate: string, p0: string) => {
    //     const start = new Date(startDate);
    //     const end = new Date(endDate);
    //     const startYear = start.getFullYear();
    //     const endYear = end.getFullYear();
    //     const isLeapYearAtStart = isLeapYear(startYear);
    //     const isLeapYearAtEnd = isLeapYear(endYear);
    //     const Difference_In_Time = end.getTime() - start.getTime();
    //     const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    //     const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
    //     if (Difference_In_Days > maxDays) {
    //         setModalMessage(`The maximum range allowed is 1 year.`);
    //         setIsModalVisible(true);
    //         return;
    //     }
    //     setlogReqBody({ ...logReqBody, startDate, endDate })
    //     setCountReqBody({ ...countReqBody, startDate, endDate })
    //     claenderonChange(startDate, endDate)
    //     value && setDateLabel(value)
    // };
    const daysFilter = (startDate: string, endDate: string, label?: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const isLeapYearAtStart = isLeapYear(startYear);
        const isLeapYearAtEnd = isLeapYear(endYear);
        const Difference_In_Time = end.getTime() - start.getTime();
        const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        const maxDays = isLeapYearAtStart || isLeapYearAtEnd ? 365 : 364;
        if (Difference_In_Days > maxDays) {
            setModalMessage('The maximum range allowed is 1 year.');
            setIsModalVisible(true);
            return;
        }
        setlogReqBody({ ...logReqBody, startDate, endDate })
        setCountReqBody({ ...countReqBody, startDate, endDate })
        if (label) {
            setDateLabel(label);
        } else if (value) {
            setDateLabel(value);
        }
        claenderonChange(startDate, endDate)
    };

    const handleModalClose = () => {
        onChange([subDays(new Date(), 6), new Date()])
        setIsModalVisible(false);
        setModalMessage('');
    };

    const toggleEmail = () => {
        setEmailText("")
        setValidEmail("")
        setEmail()
    }

    const sendEmail = async () => {
        if (isShowSpinner) return;
        if (!emailText) {
            setValidEmail("Please Enter Email Id")
            return
        }
        if (validateEmail(emailText) === false) return
        setIsShowSpinner(true)
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken
        let payload =
        {
            orgId: logReqBody?.orgId,
            divisionId: logReqBody?.divisionId,
            locationId: logReqBody?.locationId,
            buildingId: logReqBody?.buildingId,
            startDate: logReqBody?.startDate,
            endDate: logReqBody?.endDate,
            batteryId: logReqBody?.batteryId,
            vehicleId: logReqBody?.vehicleId,
            serialNumber: logReqBody?.serialNumber,
            pageOffset: 1,
            pageLimit: totalCount + 1,
            sortColumns: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection,
            // timeZone: logReqBody?.timeZone,
            toEmail: emailText
        }
        try {
            const response = await service({
                url: constantUrl.api.issues2.email,
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            if (response.data === "Email Sending failed !!!") {
                setValidEmail(response.data)
            }
            if (response.data === "Email Sent Successfully !!!") {
                setEmailText("")
                setIsMailSuccess(true)
            }
            if (response.status === "FAILURE" && response.errorCode === 3123) {
                setValidEmail(response.data)
            }
            if (response.status === "FAILURE" && response.errorCode === 3112) {
                setInValidEmail(response.errorMessage)
                setIsMailFailure(true)
                setEmailText("")
            }
            setEmail()
        } catch (error) {
            console.log(error)
        } finally {
            setIsShowSpinner(false)
        }
    }

    const validateEmail = (email: any) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email)
    };

    const handleChange = (event: any) => {
        if (validateEmail(event.target.value)) {
            setEmailText(event.target.value)
            setValidEmail("")
        } else {
            setEmailText(event.target.value)
            setValidEmail("Please Enter Valid Email ID")
        }
    }



    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <>
                {/* <PosinetHeader billing={<HeaderFilter getId={getIds} icon2={true} getMailReport={sendMailReport} vId={true}/>}/> */}
                {/* <PosinetHeader billing={<HeaderFilter icon={true} refeshIcon={false} icon2={true} getMailReport={sendMailReport} dwnFormat={getDowFormat} refresh={refresh} getId={getIds} vId={false} bId={false} pdf={true} />} /> */}
                <div ref={filtersRef} className="row rounded pr-3 pl-3 pb-3">
                    {/* <SummaryFaultCards
                            summaryFaultCounts={{dealerCount:68,companiesCount:28,locationsCount:72,batteriessCount:278,faultsCount:29}}
                            heading={"Report Summary"}
                        /> */}
                    <div className="days__filter " >
                        {
                            showDateFilter && <span>
                                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                            </span>
                        }
                        <span className='ml-auto'>
                            <DaysFilter
                                daysFilter={daysFilter}
                                customFilterStatusChange={setShowDateFilter}
                                dateOptions={dateValue}
                                defaultDateLabel={'Last 7 Days'}
                            />
                        </span>
                        <Modal
                            className="update-users"
                            title="Date Range Error"
                            centered
                            open={isModalVisible}
                            onOk={handleModalClose}
                            footer={[
                                <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                                    OK
                                </Button>,
                            ]}
                            closable={false}
                        >
                            <p>{modalMessage}</p>
                        </Modal>
                    </div>
                    <ConfigProvider
                        theme={{ token: { colorPrimary: "#004f9f" } }}>
                        <Modal
                            centered
                            open={email}
                            onCancel={toggleEmail}
                            onOk={() => setEmail()}
                            closable={true}
                            footer={null}
                            maskClosable={false}
                            width={300}
                        >
                            <div>
                                <div className='email-report-title'>Email Report</div>
                                <div className='email-label'>Email Address</div>
                                <Input
                                    placeholder="Enter Email"
                                    type='email'
                                    value={emailText}
                                    onChange={handleChange}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            sendEmail();
                                        }
                                    }}
                                />
                                {validEmail ? <span className='text-danger'>{validEmail}</span> : ""}
                                <Button
                                    block
                                    onClick={sendEmail}
                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", marginBlock: 8 }}>
                                    Send
                                </Button>
                            </div>
                        </Modal>
                    </ConfigProvider>
                </div>
                <div className="main-panel">
                    <div className="ChartPanel panelShadow bg-white">
                        <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                            <div className='ml-auto mr-auto'>Issues Report</div>
                            <div className='show-entries'>
                                <ShowEntries getPageSize={onSelect} />
                            </div>
                        </div>
                        <div className='gap marginBlock'>
                            <div className='range pl-3'>{dateLabel}</div>
                            <div className='this-week'>{"(" + dateRange.sevenDaysAgo + " to " + dateRange.today + ")"}</div>
                        </div>
                        <div id="table-container">
                            <div style={{ height: tableHeight + 'px' }} ref={tabelRef} className="charge-log-tableWrappe overflow-auto customScroll">
                                <table className="billable" style={{ width: "100%" }}>
                                    <thead className='tableHeader'>
                                        <tr>
                                            <th>
                                                <div onClick={() => sortBy('battery')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Battery</span>
                                                    {sort.battery ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('imageType')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Device Type</span>
                                                    {sort.imageType ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('charger')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Connected Charger</span>
                                                    {sort.charger ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('severity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Severity Type</span>
                                                    {sort.severity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('code')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Code</span>
                                                    {sort.code ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('datee')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Date</span>
                                                    {sort.datee ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            <th>
                                                <div onClick={() => sortBy('timee')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Time (hh:mm:ss)</span>
                                                    {sort.timee ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th>
                                            {/* <th>
                                                <div onClick={() => sortBy('dateUpdated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >Date Updated</span>
                                                    {sort.dateUpdated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {issuesReport2?.map((item: any, index: number) => {
                                            const { battery, chargerId, severityType, code, formatDate, formatTime, formatDateUpdated, deviceType } = item
                                            return <tr key={index}>
                                                <td>{battery}</td>
                                                <td>{deviceType}</td>
                                                <td>{chargerId}</td>
                                                <td>
                                                    <div>
                                                        {/* <div className='gap2'> */}
                                                        {/* <span className={severityType === "Fault" ? "round red11" : "round yellow1"} ></span> */}
                                                        <span></span>{severityType}
                                                    </div>
                                                </td>
                                                <td>{code}</td>
                                                <td>{formatDate}</td>
                                                <td>{formatTime}</td>
                                                {/* <td>{formatDateUpdated}</td> */}
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div ref={paginationRef}>
                                <PaginationComponent
                                    handlePagination={handlePagination}
                                    totalLength={totalCount}
                                    pageSize={pageSize}
                                    current={current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <SessionWarning />
                <Modal open={ismailsuccess} closable={false} centered title={"Email is sent Successfully"}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailSuccess(false)}>OK</Button>
                    ]}
                ></Modal>
                <Modal open={ismailfailure} closable={false} centered title={invalidEmail}
                    footer={[
                        <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setIsMailFailure(false)}>OK</Button>
                    ]}
                ></Modal>
            </>
        </>
    )
}

export default IssuesReport2;