import { Button, ConfigProvider, Modal } from "antd";
import React, { FC } from "react";

interface props {
    status:boolean
    title:string
    infoOnClick:()=>void
    btnText:string
}

const Info:FC<props> = ({status,title,infoOnClick,btnText}) => {
  return (
    <div>
      <ConfigProvider theme={{ token: { colorPrimary: "#004f9f" } }}>
        <Modal
          open={status}
          closable={false}
          centered
          title={title}
          zIndex={1100}
          footer={[
            <Button
              style={{ background: "#004f9f", color: "white" }}
              onClick={infoOnClick}
            >
              {btnText}
            </Button>,
          ]}
        ></Modal>
      </ConfigProvider>
    </div>
  );
};

export default Info;
