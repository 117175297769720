import React, { FC } from "react";
import { Link } from "react-router-dom";
import defaultImage from "../../images/ampure_icon_58x58.png";

interface props {
  companiesAlerts: any;
  setGlobalData: any;
  setLabelName: any;
}
interface item {
  id: string;
  owner: { login: string };
  name: string;
  locationsCount: string;
  faultCount: number;
  warningsCount: number;
  orderId: number;
  imageData: any;
  imageName: any;
}

const AlertCount: FC<props> = ({
  companiesAlerts,
  setGlobalData,
  setLabelName,
}) => {
  return (
    <div id="company-cards">
      <ul id="company-alerts" className="company-alerts">
        {companiesAlerts &&
          companiesAlerts.map((itemData: item, index: any) => {
            const {
              name,
              locationsCount,
              faultCount,
              warningsCount,
              orderId,
              imageData,
            } = itemData;
            return (
              <div id="company-card" key={index} className="row dealer-card">
                <div className="col-md-12">
                  <div className="dealer-card-body">
                    <div className="delear-body-right">
                      <div>
                        <img
                          src={
                            imageData
                              ? `data:image/png;base64,${imageData}`
                              : defaultImage
                          }
                          className="dealear-img"
                          alt="img"
                        />
                      </div>
                      <div>
                        <div
                          className="dealaer-no"
                          onClick={() => {
                            let data: any =
                              sessionStorage.getItem("globalData");
                            data = data && JSON.parse(data);
                            data["divId"] = orderId;
                            sessionStorage.setItem(
                              "globalData",
                              JSON.stringify(data)
                            );
                            setLabelName("company", name);
                            setGlobalData("divName", name);
                          }}
                          title={name}
                        >
                          <Link to={"/companydashboard"}>
                            {name?.length > 7
                              ? name?.substring(0, 7) + "..."
                              : name}
                          </Link>
                        </div>
                        <div className="fontBlue">
                          {locationsCount}&nbsp; LOCATIONS
                        </div>
                      </div>
                    </div>
                    <div className="delear-body-left ">
                      <div>
                        <div className="circle red"></div>
                        <div className="circle yellow"></div>
                      </div>
                      <div className="count">
                        <div>{faultCount}</div>
                        <div>{warningsCount}</div>
                      </div>
                      <div className="delaler-status">
                        <div>FAULTS</div>
                        <div>WARNINGS</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </ul>
    </div>
  );
};

export default AlertCount;
