

export const apiVersion = 'posinet-2.0';

export const appVersion = process.env.REACT_APP_DF_VERSION;
export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;

export default {
  api: {
    base: { url: API_BASE_URL },
    HeaderFilters: {
      getDealers: '/api/v1.0/dashboard/dealer/organisation',
      getCompanies: '/api/v1.0/dashboard/client/organisation',
      getLocations: '/api/v1.0/dashboard/location',
      getSites: '/api/v1.0/building/dropdown/sitesByOrgIdAndLocId',
      getBatteries: '/api/v1.0/report/battery-ids',
      getVehicles: '/api/v1.0/report/vehicle-ids',
      getSerialNumbers: '/api/v1.0/report/serial-numbers'
    },
    batteryTemperature: {
      getReport: '/api/v1.0/report/battery-temperature-report',
      getBatteryId: "/api/v1.0/report/battery-ids",
      getTest: 'api/' + apiVersion + '/test'
    },
    batteryVoltage: {
      getReport: '/api/v1.0/report/battery-voltage-over-time',
      getTest: 'api/' + apiVersion + '/test'
    },
    dailyUsagePlugIn: {
      getReport: 'api/' + apiVersion + '/report/daily-usage-plugin',
      getTest: 'api/' + apiVersion + '/test'
    },
    equivalentBatteryUtilization: {
      getReport: 'api/' + apiVersion + '/report/equivalent-battery-utilization',
      getTest: 'api/' + apiVersion + '/test',
      equivalentBatteryUtilization: "/api/v1.0/report/equivalent-battery-utilization"
    },
    runtimeVsAh: {
      getReport: 'api/' + apiVersion + '/report/run-time-vs-ahrs',
      getTest: 'api/' + apiVersion + '/test',
      runtimeVsAh: "/api/v1.0/report/run-time-vs-ahrs"
    },
    warrantyTime: {
      getReport: 'api/' + apiVersion + '/report/warranty-time',
      getTest: 'api/' + apiVersion + '/test',
      warrantyByTimeChart: "/api/v1.0/report/warranty-time",
    },
    remainingLife: {
      getReport: 'api/' + apiVersion + '/report/remaining-life',
      getTest: 'api/' + apiVersion + '/test',
      remainingLifeReport: "/api/v1.0/report/remaining-life"
    },
    chargeLog: {
      getReport: 'api/' + apiVersion + '/report/charge-log-report',
      getTest: 'api/' + apiVersion + '/test',
      getChargelogReport: "/api/v1.0/report/charge-log-report",
      dwn: '/api/v1.0/report/charge-log-report/download',
      count: '/api/v1.0/report/charge-log-report/recordcount'
    },
    plugInCompliance: {
      getReport: 'api/' + apiVersion + '/report/plugin-compliance-report',
      getTest: 'api/' + apiVersion + '/test',
      plugInCompliance: "/api/v1.0/report/plugin-compliance-report"
    },
    consumptionAhrs: {
      getReport: 'api/' + apiVersion + '/report/consumption-ahrs',
      getTest: 'api/' + apiVersion + '/test',
      consumptionAhrsReport: "/api/v1.0/report/consumption-ahrs",
    },
    consumptionKwhrs: {
      getReport: 'api/' + apiVersion + '/report/consumption-kwhrs',
      getTest: 'api/' + apiVersion + '/test',
      consumptionKwhrsReport: "/api/v1.0/report/consumption-kwhrs",
    },
    login: {
      getLogin: 'login/v1.0/',
      getTest: 'api/test'
    },
    updatePassword: {
      updatepwdLink: "/api/v1.0/organisation/updatepassword"
    },
    user: {
      accountId: "/api/v1.0/user/me",
      getDealerUser: "/api/v1.0/platform/admin/dealer/user/",
      postUser: "/api/v1.0/platform/admin/dealer/user",
      postAdminUser: "/api/v1.0/dealer/admin/user",
      postClientUser: "/api/v1.0/dealer/admin/client/user",
      deleteUser: "/api/v1.0/platform/admin/user/",
      toCreateUserRoles: "/api/v1.0/loginuser/roles",
      toUpdateUser: "/api/v1.0/user/me",
      particalSearch: "/api/v1.0/platform/admin/user/",
      Apitoken: '/api/v1.0/auth/api-token',
      emeterids: '/api/v1.0/masterdata/e-meter-ids?orgId=',
      emetersessions: '/api/v1.0/report/e-meter-sessions/',
      bmidEmeterSession:'/api/v1.0/BMID-Master/e-meter-session-id/',
      clearlogs: '/api/v1.0/emeter/logs/clear-bmid-logs',
      enableCapture: '/api/v1.0/BMID-Master/e-meter-enabled/update',
      getUsers: '/api/v1.0/platform/admin/dealer/getusers',
      getUsersCount: '/api/v1.0/platform/admin/dealer/getusers/count',
      getUser : '/api/v1.0/user/getusers',
      getCount: '/api/v1.0/user/getusers/count',
      addUserAll:'/api/v1.0/user/add',
      getPermissions: '/api/v1.0/permissible-roles/user/'
    },
    organization: {
      getOrganization: "/api/v1.0/organisation/parent",
      getCount: '/api/v1.0/organisation/parent/count',
      getAllOrganizations:"/api/v1.0/platform/admin/organisations",
      postOrganization: "/api/v1.0/platform/admin/dealer/organisation",
      putOrganization: "/api/v1.0/platform/admin/dealer/organisation",
      putClientOrg: "/api/v1.0/platform/admin/client/organisation",
      deleteDealerById: "/api/v1.0/platform/admin/dealer/organisation/",
      postForClient: "/api/v1.0/platform/admin/client/organisation",
      dealerMapping: "/api/v1.0/platform/admin/dealer/map/",
      count:'/api/v1.0/platform/admin/organisations/count',
      image:'/api/v1.0/platform/admin/dealer/organisation/image'
    },
    location: {
      getLocation: "/api/v1.0/location/",
      postLocation: "/api/v1.0/location/add",
      putLocation: "/api/v1.0/location/update",
      getLocationById: "/api/v1.0/location/{id}",
      getLocationByOrg: "/api/v1.0/location/locationsByOrgId",
      getLocationByOrgCount: "/api/v1.0/location/locationsByOrgId/count",
      deleteLocation: "/api/v1.0/location/delete/",
      getDivisions: "/api/v1.0/division/dropdown/divisions"
    },
    division: {
      getdivision: "/api/v1.0/division/",
      postdivision: "/api/v1.0/division/add",
      putdivision: "/api/v1.0/division/edit",
      getdivisionId: "/api/v1.0/division/{id}",
      // getLocationByOrg: "/api/v1.0/location/locationsByOrgId",
      getdivisionByCount: "/api/v1.0/division/count",
      deletedivision: "/api/v1.0/division/delete/"
    },
    site: {
      getSite: "/api/v1.0/building/",
      postSite: "/api/v1.0/building/add",
      putSite: "/api/v1.0/building/update",
      deleteSite: "/api/v1.0/building/delete/",
      getSiteById: "/api/v1.0/building/{id}",
      getCount: '/api/v1.0/building/site/count'
    },
    building: {
      getBuildings: '/api/v1.0/building/sitesByOrgIdAndLocId'
    },
    userRole: {
      userType: "/api/v1.0/loginuser/getLoggedInUserRole",
      getRoleAccess: "/api/v1.0/loginuser/getRoleAccess"
    },
    resetPwd: {
      reset: "/api/v1.0/user/reset/password"
    },
    adminDashboard: {
      getSummary: "/api/v1.0/dashboard/system/summary",
      getDealers: "/api/v1.0/dashboard/dealer/organisation",
      getCompanies: "/api/v1.0/dashboard/client/organisation",
      operationStatusDealerId: "/api/v1.0/dashboard/admin/operation/status/dealer/",
      operationStatusDealerCompanyId: "/api/v1.0/dashboard/admin/operation/status/dealer/",
      getDealerAlerts: "/api/v1.0/dashboard/dealer/alerts",
      dealerSoc: "/api/v1.0/dashboard/dealer/operation/soc",
      getSocById: "/api/v1.0/dashboard/admin/operation/soc/dealer",
      getSocByDealerCompanyId: "/api/v1.0/dashboard/admin/operation/status/dealer/",
      getTaskSummary: '/api/v1.0/dashboard/task-summary',
      getDealerTaskSummary: '/api/v1.0/dashboard/admin/task-summary/dealer/',
      getCompanyTaskSummary: '/api/v1.0/dashboard/task-summary',
      getLocationTaskSummary: '/api/v1.0/dashboard/task-summary/',
      worldSummary: '/api/v1.0/dashboard/world-summary',
      getAdminTaskSummary: '/api/v1.0/dashboard/task-summary/admin',
      dealerAlerts: '/api/v1.0/dashboard/admin/dealer/alerts'
    },
    dealerDashboard: {
      getCompanyAlertsByDealerId: "/api/v1.0/dashboard/company/alerts/",
      alertByCompanyId:'/api/v1.0/dashboard/company/alert',
      getSocByCompanyId: "/api/v1.0/dashboard/dealer/operation/soc/",
      getDealerSummary: "/api/v1.0/dashboard/organisation/summary",
      getSocByDealerId: "/api/v1.0/dashboard/dealer/operation/soc",
      getOperationStatusByDealerId: "/api/v1.0/dashboard/admin/operation/status/dealer/",
      countrySummary: "/api/v1.0/dashboard/country-summary/company/",
      countrySummary1: "/api/v1.0/dashboard/admin/country-summary/dealer/",
      alerts: '/api/v1.0/dashboard/company/alerts'
    },
    companyDashboard: {
      getLocationAlerts: "/api/v1.0/dashboard/location/alerts/",
      getCompanySummary: "/api/v1.0/dashboard/organisation/summary",
      getSocByCompanyId: "/api/v1.0/dashboard/dealer/operation/soc/",
      getOperationStatusByCoId: "/api/v1.0/dashboard/dealer/operation/status/",
      getLocationList: "/api/v1.0/dashboard/location",
      getLocations: "/api/v1.0/dashboard/location",
      getSocByCompanyIdNull: "/api/v1.0/dashboard/company/soc/status",
      getOperationStatusByCoIdNull: "/api/v1.0/dashboard/dealer/operation/status",
      companySummary:'/api/v1.0/dashboard/company/summary'
    },
    locationDashboard: {
      getSiteAlerts: "/api/v1.0/dashboard/site/alerts",
      getSummary: "/api/v1.0/dashboard/system/summary",
      getSiteAlertsCount: "/api/v1.0/dashboard/site/alerts/Count"
    },
    siteDashboard: {
      count: '/api/v1.0/dashboard/site/alerts/',
      siteDropdown: '/api/v1.0/building/dropdown/sitesByOrgIdAndLocId',
      getLocationSummary: "/api/v1.0/dashboard/location/summary",
      postDeviceReport: "/api/v1.0/device-report",
      postOperationStatus: "/api/v1.0/dashboard/location/operation/status",
      postStateOfCharge: "/api/v1.0/dashboard/dealer/operation/soc",
      count: '/api/v1.0/device-report/record-count'
    },
    dailyUsagePlugin: {
      dailyUsagePlugin: "/api/v1.0/report/daily-usage-plugin"
    },
    staeOfCharge: {
      staeOfCharge: "/api/v1.0/report/stateOfCharge",
      batterySummary: "/api/v1.0/report/battery-summary"
    },
    socreport:{
      socreport:"/api/v1.0/report/Posinet/stateOfCharge"
    },
    eqLog: {
      getEqlogReport: "/api/v1.0/report/eq-log-report",
      getEqlogCount: "/api/v1.0/report/eq-log-report/recordcount",
      getEqlogExport: "/api/v1.0/report/eq-log-report/download"
    },
    dailyPlugInReport: {
      serialNumbers: "/api/v1.0/report/serial-numbers"
    },
    billingSummaryReport: {
      getBillingSummaryReport: "/api/v1.0/dashboard/billing-summary"
    },
    batteryHistoryReport: {
      getVehicles: `/api/v1.0/report/vehicle-ids`,
      getReport: `/api/v1.0/report/battery-history-report`,
      getCount: `/api/v1.0/report/battery-history-report/recordcount`,
      dwn: `/api/v1.0/report/battery-history-report/download`
    },
    batteryHistoryLogReport: {
      getReport: `/api/v1.0/report/battery-history-logs-report`,
      getCount: `/api/v1.0/report/battery-history-logs-report/recordcount`,
      dwn: `/api/v1.0/report/battery-history-logs-report/download`
    },
    eMeterLogReport: {
      getEMeterLogReport: "/api/v1.0/report/e-meter-logs",
      eMeterExport: "/api/v1.0/report/e-meter-Log-report/download",
      eMeterCount: "/api/v1.0/report/e-meter-Log-report/recordcount",
      eMeterHeader: "/api/v1.0/report/e-meter-Log-report-emeter-identifier"
    },
    lifetimeBatteryHistory: {
      getlifetimeBatteryHistory: "/api/v1.0/report/lifetime-battery-history-report",
      getlifetimeBatteryHistorycount: "/api/v1.0/report/lifetime-battery-history-report/recordcount",
      getlifetimeBatteryHistoryexport: "/api/v1.0/report/lifetime-battery-history-report/download"
    },
    configurationLogReport: {
      getLogs: '/api/v1.0/report/configuration-logs-report',
      getLogsCount: '/api/v1.0/report/configuration-logs-report/recordcount',
      downloadFile: '/api/v1.0/report/configuration-logs-report/download/'
    },
    BMIDInventory: {
      getInventory: '/api/v1.0/BMID-Master/sp_GetMatchingDeviceSerialNumber',
      getInventoryCount: '/api/v1.0/BMID-Master/sp_GetMatchingDeviceSerialNumber/count',
      downloadFile: '/api/v1.0/BMID-Master/sp_GetMatchingDeviceSerialNumber/download/'
    },
    ChargeSummaryReport: {
      getChargeSummary: '/api/v1.0/report/charge-summary-report',
      getChargeSummaryCount: '/api/v1.0/report/charge-summary-report/recordcount',
      downloadFile: '/api/v1.0/report/charge-summary-report/download/'
    },
    equalizationSummary: {
      equalizationSummaryReport: "/api/v1.0/report/equalization-summary-report",
      equalizationSummaryCount: "/api/v1.0/report/equalization-summary-report/recordcount",
      downloadFile: "/api/v1.0/report/equalization-summary-report/download"
    },
    configurationSummaryReport: {
      getConfigurationSummary: '/api/v1.0/report/configuration-summary-report',
      getConfigurationSummaryCount: '/api/v1.0/report/configuration-summary-report/recordcount',
      getConfigurationSummaryexport: '/api/v1.0/report/configuration-summary-report/download'
    },
    batteryRXUnits: {
      addBatteryRxUnits: '/api/v1.0/BMID-Master/',
      updateBatteryRxUnits: '/api/v1.0/BMID-Master/',
      getBatteryRxUnits: '/api/v1.0/report/battery-rx-units-report',
      getBatteryRxUnitsCount: '/api/v1.0/report/battery-rx-units-report/recordcount',
      downloadFile: '/api/v1.0/report/battery-rx-units-report/download/',
      delete: '/api/v1.0/bmid-configuration/',
      getSIMNumbers: '/api/v1.0/report/battery-rx-sim-numbers',
      getTimezones: '/api/v1.0/masterdata/offsets'
    },
    smsReport: {
      getSmsReport: '/api/v1.0/report/sms-events-report',
      getSmsCount: '/api/v1.0/report/sms-events-report/recordcount',
      getSmsExport: '/api/v1.0/report/sms-events-report/download',
      BatteryrxDropdown: "/api/v1.0/report/battery-rx-sim-numbers"
    },
    FirmHistoryReport: {
      getFirmHistory: "/api/v1.0/report/firmware-history-report",
      getFirmHistoryCount: "/api/v1.0/report/firmware-history-report/recordcount",
      getFirmHistoryExport: "/api/v1.0/report/firmware-history-report/download"
    },
    accountProfile: {
      getGeneral: "/api/v1.0/settings/general",
      putGeneral: "/api/v1.0/settings/general",
      getAlert: "/api/v1.0/settings/user/alert",
      basicSosFaultAlerts:"/api/v1.0/user/alert/sos-fault/basic-alerts",
      criticalSosAlerts:"/api/v1.0/user/alert/sos-fault/critical-alerts",
      specifiedSos:"/api/v1.0/user/alert/sos-fault/specified-alerts",
      putAlert: "/api/v1.0/settings/user/alert"
    },
    batteryStatiscs: {
      getStastics: "/api/v1.0/report/battery-statics-report"
    },
    issues1: {
      getissues: "/api/v1.0/report/issues-report",
      issues1export: "/api/v1.0/report/issues-report/download"
    },
    issues2: {
      getissues2: "/api/v1.0/report/weekly-report",
      getissues2count: "/api/v1.0/report/weekly-report/recordcount",
      issues2export: "/api/v1.0/report/weekly-report/download",
      email: "/api/v1.0/report/weekly-report/send/email"
    },
    batteryRXConfig: {
      // getData: '/api/v1.0/bmid-configuration/queue/',
      getData: '/api/v1.0/bmid-configuration/data/',
      updateData: '/api/v1.0/bmid-configuration/',
      getWarrenty: '/api/v1.0/report/battery-warranty-summary-report',
      getSocTemparature: '/api/v1.0/report/battery-performance-soc-temp-report',
      batteryTypes: '/api/v1.0/batterytype/',
      getKeyOnDetectTypes: '/api/v1.0/masterdata/key-on-detection-types',
      getKeyOnLogicTypes: '/api/v1.0/masterdata/key-on-logic-types',
      daysOfWeek: '/api/v1.0/masterdata/days-of-week'
    },
    shiftShedules: {
      buildings: '/api/v1.0/masterdata/building-names',
      getshift: '/api/v1.0/shiftschedules/',
      addshift: '/api/v1.0/shiftschedules/add-shift-schedule',
      putShift: '/api/v1.0/shiftschedules/update/shift-schedule',
      deleteShift: '/api/v1.0/shiftschedules/delete',
      countShift:'/api/v1.0/shiftschedules/count'
    },
    batteries: {
      getInfo: '/api/v1.0/bmid-configuration/lognumber/max/',
      updateBattery: '/api/v1.0/bmid-configuration/'
    },
    formwareControlPanel: {
      getFirmwares: '/api/v1.0/firmware-update-rules/list',
      getCount: '/api/v1.0/firmware-update-rules/record-count',
      addFirmware: '/api/v1.0/firmware-update-rules/add', 
      addNewFirmware:'/api/v1.0/firmware-update-rules/check-device-type',
      updateFirmware: '/api/v1.0/firmware-update-rules/',
      deleteFirmware: '/api/v1.0/firmware-update-rules/',
      getFirmwareBMIDsByQueueId: '/api/v1.0/firmware-update-rules/',
      downloadFirmwares: '/api/v1.0/firmware-update-rules/list/download/',
      downloadFirmwareBmids: '/api/v1.0/firmware-update-rules/',
      getFirmwareVersions: '/api/v1.0/firmware-update-rules/available-updates'
    },
    emeter: {
      phonehome: '/api/v1.0/BMID-Master/getBMIDPhoneHome',
      deleteSession: '/api/v1.0/emeter/logs/clearEMeterSession',
      createSession: '/api/v1.0/emeter/logs/createSession',
      createSession1:"/api/v1.0/emeter/logs/update-session"
    },
    chargeIdleUse: {
      postChargeIdleUse:"api/v1.0/report/charge-idle-use-report"
    },
    dailyBatteryPerformance: {
      getReport: `/api/v1.0/report/daily-performance-report`,
      getCount: `/api/v1.0/report/daily-performance-report/recordcount`,
      dwn: `/api/v1.0/report/daily-performance-report/download`
    },
    reportSettings:{
      settings:'/api/v1.0/report-settings',
      availableReports:'/api/v1.0/report-settings/available-reports',
    },
    dashboard: {
      getTaskSummary: '/api/v1.0/company-dashboard/task-summary',
      getCompanySummary: '/api/v1.0/company-dashboard/company/summary',
      getLocationSummary: '/api/v1.0/company-dashboard/location/summary',
      getCountrySummary: '/api/v1.0/company-dashboard/country-summary',
      getOperationSoc: '/api/v1.0/company-dashboard/operation/soc',
      getOperationStatus: '/api/v1.0/company-dashboard/operation/status',
      getDealerAlerts: '/api/v1.0/company-dashboard/dealer/alerts',
      getCompanyAlerts: '/api/v1.0/company-dashboard/company/alerts',
      getLocationAlerts: '/api/v1.0/company-dashboard/location/alerts',
      getSiteAlerts: '/api/v1.0/company-dashboard/site/alerts',
      getUsers: '/api/v1.0/company-dashboard/getusers',
      getUsersCount: '/api/v1.0/company-dashboard/getusers/count',
      getWorldSummary: '/api/v1.0/company-dashboard/world-summary',
      getLocations: '/api/v1.0/company-dashboard/drop-down/location',
      getOrganizations: '/api/v1.0/company-dashboard/drop-down/organization',
      getOrgs: '/api/v1.0/company-dashboard/organizations',
      getOrgsCount: '/api/v1.0/company-dashboard/organizations/count'
    },
    operationStatus:{
      highTemp:"/api/v1.0/report/operation-status/high-temperature-batteries",
      ahrsCharged:"/api/v1.0/report/operation-status/ahrs-charged",
      eqOverdue:"/api/v1.0/report/operation-status/eq-status",
      waterDays:"/api/v1.0/report/operation-status/low-water-events",
      highTempCount:"/api/v1.0/report/operation-status/high-temperature-batteries/record-count",
      ahrsChargedCount:"/api/v1.0/report/operation-status/ahrs-charged/record-count",
      eqOverdueCount:"/api/v1.0/report/operation-status/eq-status/record-count",
      waterDaysCount:"/api/v1.0/report/operation-status/low-water-events/record-count",
      highTempDownload:"/api/v1.0/report/operation-status/high-temperature-batteries/download",
      ahrsChargedDownload:"/api/v1.0/report/operation-status/ahrs-charged/download",
      eqOverdueDownload:"/api/v1.0/report/operation-status/eq-status/download",
      waterDaysDownload:"/api/v1.0/report/operation-status/low-water-events/download",
      highTempEmail:"/api/v1.0/report/operation-status/high-temperature/send/email",
      ahrsChargedEmail:"/api/v1.0/report/operation-status/ahrs-charged/send/email",
      eqOverdueEmail:"/api/v1.0/report/operation-status/eq-status/send/email",
      waterDaysEmail:"/api/v1.0/report/operation-status/low-water/send/email",
      updateOpsStatus: "/api/v1.0/user/enable-ops-status-report"
    },
    batteryRxTestPage:{
      chargerStatus:"/api/v1.0/report/charger-status",
      chargerStatusCount:"/api/v1.0/report/charger-status/recordcount",
      bmidInformation:"/api/v1.0/report/bmid-information",
      firmwareVersion:"/api/v1.0/report/firmwareVersion",
      rebootBmid:"/api/v1.0/report/reboot-bmid",
      clearBmid:"/api/v1.0/report/clear-bmid-logs",
      resetBmid:"/api/v1.0/report/reset-bmid-data"
    },
    faultAndWarning:{
      faultAndWarningList:"/api/v1.0/report/faults/warning/list/by/",
      faultAndWarningCount:"/api/v1.0/report/faults/warning/count/by/",
      faultAndWarningEmail:"/api/v1.0/report/faults/warning/",
      faultAndWarningDownload:"/api/v1.0/report/faults/warning/"
    },
    faultSummaryDashboard:{
      faultSummary:"/api/v1.0/company-dashboard/faults/summary/count",
      taskSummaryCount:'/api/v1.0/company-dashboard/task-summary-new',
      dealerFaultAlerts:'/api/v1.0/company-dashboard/dealer/alerts-new',
      companyFaultAlerts:'/api/v1.0/company-dashboard/company/alerts-new',
      locationFaultAlerts:'/api/v1.0/company-dashboard/location/alerts-new',
      siteFaultAlerts:'/api/v1.0/company-dashboard/site/alerts-new'
    },
    eventLog: {
      getReport: '/api/v1.0/report/event-logs-report',
      dwn: '/api/v1.0/report/event-logs-report/download',
      count: '/api/v1.0/report/event-logs-report/recordcount'
    },
  }
}