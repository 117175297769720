import React, { FC, useState, useEffect } from "react";
import { format, subDays, startOfWeek } from "date-fns";
import HightempImg from "../../images/High-Temp-Icon.png";
import AhsImg from "../../images/Low_SOC.svg";
import GroupImg from "../../images/Group-601.png";
import EQ from "../../images/Missed_EQ.svg";
import { Link } from "react-router-dom";
import Miscellaneous from '../../images/miscellaneous_1.svg';

interface Props {
  statusData: {
    highTempBatteriesCount: number;
    missedEQsCount: number;
    lowWaterCount: number;
    lowSOCCount: number;
    miscellaneousEventsCount: number;
  };
  dateFilter: (startDate: string, endDate: string, getGlobalData?: any) => void;
}

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

// const currentD = new Date();
// currentD.setDate(currentD.getDate());
// const lastDaysWeek = format(currentD, "dd-MMM-yyyy");
// const firstDayOfWeek = format(
//   startOfWeek(new Date(), { weekStartsOn: 0 }),
//   "dd-MMM-yyyy"
// );

const FaultSummary: FC<Props> = ({ statusData, dateFilter }) => {
  // const [open, setOpen] = useState<boolean>(false);
  // const [value, setvalue] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [date, setDate] = useState<any>({
    startDate: sevenDaysAgo,
    endDate: today,
  });

  // useEffect(() => {
  //   let week_of_day = localStorage.getItem("weeklyStartDay");
  //   let date_selected = new Date();
  //   date_selected = new Date(
  //     date_selected.getFullYear(),
  //     date_selected.getMonth(),
  //     date_selected.getDate()
  //   );

  //   switch (week_of_day) {
  //     case "SUN":
  //       while (date_selected.getDay() !== 0) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "MON":
  //       while (date_selected.getDay() !== 1) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "TUE":
  //       while (date_selected.getDay() !== 2) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "WED":
  //       while (date_selected.getDay() !== 3) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "THU":
  //       while (date_selected.getDay() !== 4) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "FRI":
  //       while (date_selected.getDay() !== 5) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     case "SAT":
  //       while (date_selected.getDay() !== 6) {
  //         date_selected.setDate(date_selected.getDate() - 1);
  //       }
  //       break;
  //     default:
  //       return;
  //   }

  //   let date_end = new Date(date_selected.getTime() + 7 * 24 * 60 * 60 * 1000);
  //   let label = document.getElementById("dateRangeLabel");
  //   if (label)
  //     label.innerText = `${format(date_selected, "dd-MMM-yyyy")} - ${format(
  //       date_end,
  //       "dd-MMM-yyyy"
  //     )}`;
  //   var getGlobalData: any = sessionStorage.getItem("globalData");
  //   getGlobalData = getGlobalData && JSON.parse(getGlobalData);
  //   dateFilter(
  //     format(date_selected, "dd-MMM-yyyy"),
  //     format(date_end, "dd-MMM-yyyy"),
  //     getGlobalData
  //   );
  //   setDate({
  //     startDate: format(date_selected, "dd-MMM-yyyy"),
  //     endDate: format(date_end, "dd-MMM-yyyy"),
  //   });
  // }, []);

  useEffect(() => {
    const label = document.getElementById("dateRangeLabel");
    if (label) {
      label.innerText = `${sevenDaysAgo} - ${today}`;
    }

    let getGlobalData = sessionStorage.getItem("globalData");
    getGlobalData = getGlobalData ? JSON.parse(getGlobalData) : null;

    dateFilter(sevenDaysAgo, today, getGlobalData);

    setDate({
      startDate: sevenDaysAgo,
      endDate: today,
    });
  }, []);

  const handleSectionClick = (section: string) => {
    localStorage.setItem('highlightedSection', section);
  };

  return (
    <div className="operation-status-dashboard">
      <div>
        <div className="chartTitle">FAULTS SUMMARY</div>
      </div>
      <div className="mt-2 mb-2 gray-color">
        <span className="d-range-label">DATE RANGE</span>
        <span id="dateRangeLabel" className="d-range-value">
          THIS WEEK
        </span>
      </div>
      <Link to="/faultsandwarnings" style={{ textDecoration: "none" }}>
        <div className="col-12 faults-card-col" onClick={() => handleSectionClick('HIGH_TEMPERATURE_FAULTS_REPORT')}>
          <div className="fault-card">
            <div>
              <img
                src={HightempImg}
                className="faults-summary-image"
                alt="img"
              />
            </div>
            <div className="f-s-right blue-color">
              <div id="highTempCount" className="f-s-count">
                {statusData.highTempBatteriesCount}
              </div>
              <div className="count-label">HIGH TEMP BATTERIES</div>
            </div>
          </div>
        </div>
      </Link>

      <Link to="/faultsandwarnings" style={{ textDecoration: "none" }}>
        <div className="col-12 faults-card-col mt-2 " onClick={() => handleSectionClick('MISSED_EQ_FAULTS_REPORT')}>
          <div className="fault-card">
            <div>
              <img src={EQ} className="faults-summary-image" alt="img" />
            </div>
            <div className="f-s-right blue-color">
              <div
                id="watering"
                className="f-s-count"
                style={{ font: "normal normal bold 28px/30px Inter" }}
              >
                {statusData.missedEQsCount}
              </div>
              <div className="count-label">MISSED EQ'S</div>
            </div>
          </div>
        </div>
      </Link>

      <Link to="/faultsandwarnings" style={{ textDecoration: "none" }}>
        <div className="col-12 faults-card-col mt-2 " onClick={() => handleSectionClick('LOW_WATER_FAULTS_REPORT')}>
          <div className="fault-card">
            <div>
              <img src={GroupImg} className="faults-summary-image" alt="img" />
            </div>
            <div className="f-s-right blue-color">
              <div
                id="watering"
                className="f-s-count"
                style={{ font: "normal normal bold 28px/30px Inter" }}
              >
                {statusData.lowWaterCount}
              </div>
              <div className="count-label">LOW WATER COUNT</div>
            </div>
          </div>
        </div>
      </Link>

      <Link to="/faultsandwarnings" style={{ textDecoration: "none" }}>
        <div className="col-12 faults-card-col mt-2 " onClick={() => handleSectionClick('LOW_SOC_FAULTS_REPORT')}>
          <div className="fault-card">
            <div>
              <img src={AhsImg} className="faults-summary-image" alt="img" />
            </div>
            <div className="f-s-right blue-color">
              <div id="ahsCharged" className="f-s-count">
                {statusData.lowSOCCount}
              </div>
              <div className="count-label">LOW SOC</div>
            </div>
          </div>
        </div>
      </Link>
      <Link to="/faultsandwarnings" style={{ textDecoration: "none" }}>
        <div className="col-12 faults-card-col mt-2" onClick={() => handleSectionClick('MISCELLANEOUS_FAULTS_REPORT')}>
          <div className="fault-card">
            <div>
              <img
                src={Miscellaneous}
                className="faults-summary-image"
                alt="img"
              />
            </div>
            <div className="f-s-right blue-color">
              <div id="miscellaneousCount" className="f-s-count">
                {statusData.miscellaneousEventsCount}
              </div>
              <div className="count-label">MISCELLANEOUS</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default FaultSummary;
